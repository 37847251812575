<template>
  <div>
    <app-bar-compo
      title="Bank / Money Account"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <template v-slot:filters>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Transaction Type</h3>
            <v-select
              class="mt-2"
              dense
              v-model="transType"
              :items="['Card', 'Cash', 'Cheque']"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-5 mt-2">
          <v-col>
            <v-row class="pa-0"
              ><v-checkbox
                class="ma-0 pa-0 mr-3"
                v-model="withDateRange"
                label="with"
              ></v-checkbox>
              <h3>Date Range (Create Date)</h3></v-row
            >
            <v-date-picker
              v-if="withDateRange"
              v-model="pickDateRange"
              range
              full-width
              header-color="blue"
              color="green"
              class="date-Picker mt-4"
            ></v-date-picker>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>
    <v-container fluid class="st-container">
      <div>
        <v-row class="ma-2 mb-4">
          <v-btn text class="without-border-btn" @click="dialogAction('dip')"
            >Diposit</v-btn
          >
          <v-btn class="without-border-btn" @click="dialogAction('with')"
            >Withdrawal</v-btn
          >
          <v-btn class="without-border-btn" @click="dialogAction('ret')"
            >Cheque Return</v-btn
          >
          <v-btn class="without-border-btn" @click="dialogAction('rel')"
            >Cheque Realise</v-btn
          >
        </v-row>
      </div>
      <hr />
      <!-- Navigate sections -->
      <div>
        <v-row class="ma-2 mt-3">
          <v-card class="indigo--text">
            <v-card-title>
              <span class="text-h7"
                >Diposit : Rs.{{ totalOfDip.toFixed(2) }}</span
              >
            </v-card-title>
          </v-card>
          <v-card class="pink--text text--darken-3">
            <v-card-title>
              <span class="text-h7"
                >Withdrawal : Rs.{{ totalOfWith.toFixed(2) }}</span
              >
            </v-card-title>
          </v-card>
          <v-card class="green--text text--darken-3">
            <v-card-title>
              <span class="text-h7"
                >Balance : Rs.{{ totalOfBalance.toFixed(2) }}</span
              >
            </v-card-title>
          </v-card>
        </v-row>
      </div>
      <!-- <br /> -->
      <v-col cols="12" lg="8" md="8" sm="12" class="pa-0 ma-0">
        <v-data-table
          :headers="headers"
          :items="BodyData"
          :search="searchTxt"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          hide-default-footer
          sort-by="trans_date"
          :sort-desc="true"
          class="elevation-1 mt-4 data-tbl"
          @page-count="pageCount = $event"
        >
          <template v-slot:item.trans_date="{ item }">
            <span>{{ dateTimeFormater(item.trans_date) }}</span>
          </template>
          <!-- Show when transaction type is equels to 'Card' -->
          <template v-slot:item.actions="{ item }">
            <v-menu
              v-if="item.trans_type != null && item.trans_type != 'Card'"
              left
              origin="center"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon dark v-bind="attrs" v-on="on">
                  <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-container class="background">
                <v-row class="pa-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        color="red darken-4"
                        class="ma-1"
                        @click="dialogAction('d', item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-row>
              </v-container>
            </v-menu>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-container>
    <!--  -->
    <!-- Bank dip/with/cheq ret/cheq rel and delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="selectedCheNo"
                  label="Cheque No"
                  :items="chequeData"
                  hide-selected
                  :loading="cheNoLoad"
                  item-text="che_no"
                  outlined
                  clearable
                  required
                >
                </v-select>
              </v-col>
              <v-col
                v-if="dialogType != 'ret' && dialogType != 'rel'"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-text-field
                  dense
                  v-model="cashAmount"
                  label="Cash Amount"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  v-model="transDateModel"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="transDate"
                      label="Transaction Date"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="transDate"
                    @input="transDateModel = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            dark
            :disabled="btnLoading"
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType != 'd'
                ? bankMoneyAction()
                : deleteData(editItem.trans_id)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

var paymentsRef;
var bankMoneyRef;

export default {
  name: "bankMoney_screen",
  mounted() {
    paymentsRef = this.$fireStore.collectionGroup("payments");
    bankMoneyRef = this.$fireStore.collection("bank_money");
    this.initData();
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      chequeData: [],
      cheNoLoad: false,
      selectedCheNo: null,
      cashAmount: null,
      transDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      transDateModel: null,
      // Button
      btnLoading: false,
      editItem: {},
      // Summary
      totalOfDip: 0,
      totalOfWith: 0,
      totalOfBalance: 0,
      // Filter
      transType: null,
      withDateRange: false,
      pickDateRange: [],
      // Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: null,
      itemsPerPage: 10,
      headers: [
        {
          text: "Transaction Type",
          align: "start",
          width: 150,
          //   sortable: false,
          value: "trans_type",
        },
        { text: "Note", value: "note", width: 200, sortable: false },
        { text: "Diposit", value: "dip_amount" },
        { text: "Withdrawal", value: "with_amount" },
        { text: "Create Emp ID", value: "create_emp_id", sortable: false },
        { text: "Date", width: 130, value: "trans_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await bankMoneyRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            snapshots.docs.forEach((element) => {
              this.BodyData.push(element.data());
              this.BodyDataBack.push(element.data());
            });
            this.isTableLoading = false;
            this.calculateTotals();
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async initChequeNo(constype) {
      try {
        this.cheNoLoad = true;
        await paymentsRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.chequeData = [];
            snapshots.docs.forEach((element) => {
              if (
                element.data()["che_no"] != null &&
                !element.data()["is_che_return"] &&
                !element.data()["is_che_realise"]
              ) {
                if (constype === element.data().cons_type) {
                  var data = {
                    che_no: element.data()["che_no"],
                    cheque_amount: element.data()["cheque_amount"],
                    cons_id: element.data()["cons_id"],
                    cons_type: element.data()["cons_type"],
                  };
                  // Set cheque no to list
                  this.chequeData.push(data);
                }
              }
            });
            this.cheNoLoad = false;
          }
        );
      } catch (error) {
        this.cheNoLoad = false;
        console.log(error);
      }
    },
    async initChequeNoFromBank() {
      try {
        this.cheNoLoad = true;
        await bankMoneyRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.chequeData = [];
            snapshots.docs.forEach((element) => {
              if (
                element.data()["che_no"] != null &&
                !element.data()["is_che_realise"]
              ) {
                var data = {
                  che_no: element.data()["che_no"],
                };
                // Set cheque no to list
                this.chequeData.push(data);
              }
            });
            this.cheNoLoad = false;
          }
        );
      } catch (error) {
        this.cheNoLoad = false;
        console.log(error);
      }
    },
    calculateTotals() {
      try {
        this.totalOfDip = 0;
        this.totalOfWith = 0;
        this.totalOfBalance = 0;
        this.BodyData.forEach((el) => {
          this.totalOfDip += Number(el.dip_amount ?? 0);
          this.totalOfWith += Number(el.with_amount ?? 0);
        });
        this.totalOfBalance = this.totalOfDip - this.totalOfWith;
      } catch (error) {
        console.log(error);
      }
    },
    dialogAction(type, item) {
      this.editItem = {};
      this.selectedCheNo = null;
      this.cashAmount = null;
      this.transDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      if (type == "dip") {
        this.initChequeNo("CUS");
        this.dialogTitle = "Diposit";
        this.dialogType = "dip";
      } else if (type == "with") {
        this.initChequeNo("SUP");
        this.dialogType = "with";
        this.dialogTitle = "Withdrawal";
      } else if (type == "ret") {
        this.initChequeNoFromBank();
        this.dialogType = "ret";
        this.dialogTitle = "Cheque Return";
      } else if (type == "rel") {
        this.initChequeNoFromBank();
        this.dialogType = "rel";
        this.dialogTitle = "Cheque Realise";
      } else {
        this.editItem.trans_id = item.trans_id;
        this.editItem.che_no = item.che_no;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      }
      this.dialog = !this.dialog;
    },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    // Crud
    async bankMoneyAction() {
      try {
        // Need to set Promise

        //   trans_id
        //   trans_type
        //   note
        //   pay_id // adding from make card payment
        //   che_no // Hiden data
        //   dip_amount
        //   with_amount
        //   create_emp_id
        //   trans_date
        this.btnLoading = true;

        var transID = null;
        var message = null;
        var cheData = null;

        if (this.dialogType == "dip") {
          // set to bank account
          if (this.selectedCheNo != null && this.selectedCheNo != "") {
            // Find cheque details
            cheData = await this.chequeData.find((value) => {
              return value.che_no == this.selectedCheNo;
            });
            // Generate trans id
            transID = uuid();
            await bankMoneyRef
              .doc(transID)
              .set({
                trans_id: transID,
                trans_type: "Cheque",
                note: `Cheque Diposit - ${this.selectedCheNo}`,
                is_che_realise: false,
                che_no: this.selectedCheNo,
                dip_amount: Number(cheData.cheque_amount),
                create_emp_id: this.userData.s_user_id,
                trans_date: new Date(this.transDate),
              })
              .then(() => {
                message += " Cheque Diposited";
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (Number(this.cashAmount ?? 0) > 0) {
            // Generate trans id
            transID = uuid();
            await bankMoneyRef
              .doc(transID)
              .set({
                trans_id: transID,
                trans_type: "Cash",
                note: `Cash Diposit`,
                dip_amount: Number(this.cashAmount),
                create_emp_id: this.userData.s_user_id,
                trans_date: new Date(this.transDate),
              })
              .then(() => {
                message += " Cash Diposited";
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else if (this.dialogType == "with") {
          // set to bank account
          if (this.selectedCheNo != null && this.selectedCheNo != "") {
            // Find cheque details
            cheData = await this.chequeData.find((value) => {
              return value.che_no == this.selectedCheNo;
            });
            // Generate trans id
            transID = uuid();
            await bankMoneyRef
              .doc(transID)
              .set({
                trans_id: transID,
                trans_type: "Cheque",
                note: `Cheque Withdrawal - ${this.selectedCheNo}`,
                che_no: this.selectedCheNo,
                is_che_realise: false,
                with_amount: Number(cheData.cheque_amount),
                create_emp_id: this.userData.s_user_id,
                trans_date: new Date(this.transDate),
              })
              .then(() => {
                message += " Cheque Withdrawal";
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (Number(this.cashAmount ?? 0) > 0) {
            // Generate trans id
            transID = uuid();
            await bankMoneyRef
              .doc(transID)
              .set({
                trans_id: transID,
                trans_type: "Cash",
                note: `Cash Withdrawal`,
                with_amount: Number(this.cashAmount),
                create_emp_id: this.userData.s_user_id,
                trans_date: new Date(this.transDate),
              })
              .then(() => {
                message += " Cash Withdrawal";
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else if (this.dialogType == "rel") {
          // mark in payments
          await paymentsRef
            .where("che_no", "==", this.selectedCheNo)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.docs?.length != 0) {
                querySnapshot.docs[0].ref.update({
                  is_che_realise: true,
                  che_realise_date: new Date(this.transDate),
                });
              }
            })
            .then(async () => {
              // update from bank account
              await bankMoneyRef
                .where("che_no", "==", this.selectedCheNo)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.docs?.length != 0) {
                    querySnapshot.docs[0].ref.update({
                      note: `Cheque Realised - ${this.selectedCheNo}`,
                      is_che_realise: true,
                    });
                  }
                });
            })
            .then(() => {
              message += " Cheque Realised";
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // ret
          // mark in payments and delete from bank account
          // mark in payments
          await paymentsRef
            .where("che_no", "==", this.selectedCheNo)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.docs?.length != 0) {
                querySnapshot.docs[0].ref.update({
                  is_che_return: true,
                  che_ret_emp_id: this.userData.s_user_id,
                  che_return_date: new Date(this.transDate),
                });
              }
            })
            .then(async () => {
              // Delete from bank account
              await bankMoneyRef
                .where("che_no", "==", this.selectedCheNo)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.docs?.length != 0) {
                    querySnapshot.docs[0].ref.delete();
                  }
                });
            })
            .then(() => {
              message += " Cheque Returned";
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.btnLoading = false;
        this.dialog = !this.dialog;
        // Message modified
        message = message.split(" ").join(", ");
        this.$store.commit("alertMessage/message", [
          `Data updated successfully.(${message})`,
          "success",
        ]);
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Payment ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          await bankMoneyRef
            .doc(id)
            .delete()
            .then(async () => {
              // if cheque dip or with, Mark realise false
              await paymentsRef
                .where("che_no", "==", this.editItem?.che_no ?? null)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.docs?.length != 0) {
                    querySnapshot.docs[0].ref.update({
                      is_che_realise: false,
                      // che_realise_date: null,
                    });
                  }
                });
            })
            .then(async () => {
              this.btnLoading = false;
              this.dialog = false;
              this.editItem = {};
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Filter
    filterData() {
      try {
        var tempList = this.BodyDataBack;
        // Filter unit 01
        if (this.transType != null && this.transType != "") {
          tempList = tempList.filter(
            (value) => value.trans_type == this.transType
          );
        }
        // Filter unit 02
        if (this.withDateRange) {
          if (this.pickDateRange.length != 2) {
            this.$store.commit("alertMessage/message", [
              "Please select the date range.",
              "warning",
            ]);
          } else {
            tempList = tempList.filter((value) => {
              var date01 = new Date(this.dateTimeFormater(value?.trans_date));
              var date02 = new Date(this.pickDateRange[0]);
              var date03 = new Date(this.pickDateRange[1]);
              //
              return date01 >= date02 && date01 <= date03;
            });
          }
        }
        this.BodyData = tempList ?? [];
      } catch (error) {
        console.log(error);
      } finally {
        this.calculateTotals();
      }
    },
    clearFilter() {
      try {
        this.transactionType = null;
        this.pickDateRange = [];
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      } finally {
        this.calculateTotals();
      }
    },
  },
};
</script>
<style lang="scss">
</style>