<template>
  <div>
    <app-bar-compo
      title="Expenses"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <!-- Filters -->
      <template v-slot:filters>
        <h3>Date Range</h3>
        <div>
          <v-date-picker
            v-model="pickDateRange"
            range
            full-width
            header-color="blue"
            color="green"
            class="date-Picker mt-3"
          ></v-date-picker>
        </div>
      </template>
      <template v-slot:actions>
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>
    <!-- Body -->

    <v-container fluid class="st-container">
      <div>
        <v-row class="ma-0 mt-2 ml-2">
          <v-btn @click="navigate('/expenses/types/')" class="" text
            >Add New Type</v-btn
          >
        </v-row>
      </div>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :loading="isTableLoading"
        :search="searchTxt"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        sort-by="create_date"
        :sort-desc="true"
        class="elevation-1 mt-4 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.create_date="{ item }">
          <span>{{ dateTimeFormater(item.create_date) }}</span>
        </template>
        <template v-slot:item.proof_img_link="{ item }">
          <a :href="item.proof_img_link" target="__blank">{{
            item.proof_img_link
          }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="
                        dialogAction(
                          'edit',
                          item,
                          item.exp_type_id == 'TRANS' ? true : false
                        )
                      "
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="
                        dialogAction(
                          'delete',
                          item,
                          item.exp_type_id == 'TRANS' ? true : false
                        )
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
      <v-speed-dial open-on-hover v-model="fab" fixed bottom>
        <template v-slot:activator>
          <v-btn v-model="fab" color="deep-orange accent-3" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
        </template>
        <v-btn fab dark @click="dialogAction('', '')" small color="indigo">
          <v-icon>mdi-cash</v-icon>
        </v-btn>
        <v-btn fab dark @click="dialogAction('', '', true)" small color="blue">
          <v-icon>mdi-car-estate</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-container>
    <!--  -->
    <!-- Add/ Edit/ Delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row>
              <v-col v-if="!isTransportExp" cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editItem.exp_type_id"
                  label="Type ID"
                  :items="expTypeIDs"
                  hide-selected
                  outlined
                  clearable
                  required
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  dense
                  v-model="editItem.emp_id"
                  :items="empIDs"
                  label="Emp ID"
                  hide-selected
                  :loading="loadingDataEmpExp"
                  outlined
                  clearable
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="amount"
                  label="Amount"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <!-- <v-col v-if="dialogType == 'e'" cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  dense
                  v-model="editItem.create_emp_id"
                  :items="empIDs"
                  label="Create Emp ID"
                  hide-selected
                  :loading="loadingDataEmpExp"
                  outlined
                  clearable
                  required
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model="editItem.proof_img_link"
                  label="Proof Image Link"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.note"
                  label="Note"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  no-resize
                  clearable
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(editItem.exp_id)
                : deleteData(editItem.exp_id)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

var expTypesRef;
var expensesRef;
var employeesRef;

export default {
  name: "expenses_screen",
  mounted() {
    expTypesRef = this.$fireStore.collection("expenses_types");
    expensesRef = this.$fireStore.collection("expenses");
    employeesRef = this.$fireStore.collection("employees");
    this.initData();
    this.initDataEmps();
    this.loadExpTypesData();
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      expTypeIDs: [],
      expTypeData: [],
      empIDs: [],
      loadingDataEmpExp: false,
      amount: 0,
      isTransportExp: false,
      // Buttons
      btnLoading: false,
      fab: false,
      // Filter
      pickDateRange: [],
      //   Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      headers: [
        // {
        //   text: "Exp ID",
        //   align: "start",
        //   value: "exp_id",
        // },
        {
          text: "Exp Type ID",
          align: "start",
          value: "exp_type_id",
        },
        {
          text: "Statement",
          align: "start",
          value: "statement",
        },
        {
          text: "Exp Emp ID",
          align: "start",
          value: "emp_id",
        },
        {
          text: "Amount",
          align: "start",
          value: "amount",
        },
        {
          text: "Expenses Image Link",
          align: "start",
          value: "proof_img_link",
          width: 250,
        },

        {
          text: "Note",
          value: "note",
          width: 300,
          class: "pad-class",
          sortable: false,
        },
        {
          text: "Create Emp ID",
          value: "create_emp_id",
          sortable: false,
        },
        {
          text: "Create Date",
          value: "create_date",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: 50,
        },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await expensesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            snapshots.docs.forEach((element) => {
              this.BodyData.push(element.data());
              this.BodyDataBack.push(element.data());
            });
            this.isTableLoading = false;
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async initDataEmps() {
      try {
        this.loadingDataEmpExp = true;
        await employeesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.empIDs = [];
            snapshots.docs.forEach((element) => {
              if (
                element.data()["emp_position"] == "Cashier" ||
                element.data()["emp_position"] == "Manager"
              ) {
                this.empIDs.push(element.data()["emp_id"]);
              }
            });
            this.loadingDataEmpExp = false;
          }
        );
      } catch (error) {
        this.loadingDataEmpExp = false;
        console.log(error);
      }
    },
    async loadExpTypesData() {
      try {
        await expTypesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.expTypeIDs = [];
            this.expTypeData = [];
            snapshots.docs.forEach((element) => {
              this.expTypeIDs.push(element.data()["exp_type_id"]);
              this.expTypeData.push(element.data());
            });
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    navigate(path) {
      try {
        this.$router
          .push({
            path: path,
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item, isTransportExp = false) {
      this.editItem = {};
      this.amount = 0;
      // For identify, trasport cost or not
      this.isTransportExp = isTransportExp;
      //
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        this.amount = item.amount;
        // set data to dclired variables
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.exp_id = item.exp_id;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    // Operating with DB
    addData() {
      try {
        if (!this.isTransportExp && this.editItem?.exp_type_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Expenses Type ID.",
            "error",
          ]);
        } else if (this.editItem?.emp_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee ID.",
            "error",
          ]);
        } else if (this.amount == null || Number(this.amount) <= 0) {
          this.$store.commit("alertMessage/message", [
            "Please enter valid Amount of Expense.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          // Change exp_type_id
          if (this.isTransportExp) this.editItem.exp_type_id = "TRANS";
          else
            var findExpType = this.expTypeData.find(
              (e) => e.exp_type_id == this.editItem.exp_type_id
            ); // find statement

          // Generate exp id
          var expID = uuid();
          //
          expensesRef
            .doc(expID)
            .set({
              exp_id: expID,
              statement: findExpType.statement ?? null,
              create_emp_id: this.userData.s_user_id,
              amount: Number(this.amount),
              ...this.editItem,
              create_date: new Date(),
            })
            .then(() => {
              this.editItem = {};
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data added successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    editData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Expense ID is not valid.",
            "error",
          ]);
        } else if (!this.isTransportExp && this.editItem?.exp_type_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Expenses Type ID.",
            "error",
          ]);
        } else if (this.editItem?.emp_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee ID.",
            "error",
          ]);
        } else if (this.amount == null || Number(this.amount) <= 0) {
          this.$store.commit("alertMessage/message", [
            "Please enter valid Amount of Expense.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          // find statement
          if (!this.isTransportExp)
            var findExpType = this.expTypeData.find(
              (e) => e.exp_type_id == this.editItem.exp_type_id
            );
          //
          expensesRef
            .doc(id)
            .update({
              statement: findExpType?.statement ?? null,
              ...this.editItem,
              amount: this.amount,
            })
            .then(() => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data updated successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Expense ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          expensesRef
            .doc(id)
            .delete()
            .then(() => {
              this.btnLoading = false;
              this.dialog = false;
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    filterData() {
      try {
        if (this.pickDateRange.length != 2) {
          this.$store.commit("alertMessage/message", [
            "Please select the date range.",
            "warning",
          ]);
        } else {
          var tempList;
          tempList = this.BodyDataBack.filter((value) => {
            var date01 = new Date(this.dateTimeFormater(value?.create_date));
            var date02 = new Date(this.pickDateRange[0]);
            var date03 = new Date(this.pickDateRange[1]);
            //
            return date01 >= date02 && date01 <= date03;
          });

          this.BodyData = tempList ?? [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.pickDateRange = [];
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
</style>