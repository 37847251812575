import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ConstituentsIndex from "../views/constituents/index.vue";
import InvoicesIndex from "../views/constituents/invoices.vue";
import PaymentsIndex from "../views/constituents/payments.vue";
import EmployeesIndex from "../views/employees/index.vue";
import ItemStockIndex from "../views/items_stock/index.vue";
import CategoriesIndex from "../views/items_stock/categories.vue";
import ProductsIndex from "../views/items_stock/products.vue";
import WarehousesIndex from "../views/items_stock/warehouses.vue";
import BranchesIndex from "../views/branches/index.vue";
import ItemsIndex from "../views/items_stock/items.vue";
import InventoriesIndex from "../views/items_stock/inventories.vue";
import ExpensesIndex from "../views/expenses/index.vue";
import ExpensesTypesIndex from "../views/expenses/exp_types.vue";
import signInIndex from "../views/signIn.vue";
import BankMoneyIndex from "../views/bank_money_account/index.vue";
import ReportsIndex from "../views/reports/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home_screen",
    component: Home,
  },
  {
    path: "/constituents",
    name: "constituents_screen",
    component: ConstituentsIndex,
    props: true,
  },
  {
    path: "/constituents/invoices/:id?",
    name: "invoices_screen",
    component: InvoicesIndex,
    props: true,
  },
  {
    path: "/constituents/payments/:cons_type?",
    name: "payments_screen",
    component: PaymentsIndex,
    props: true,
    // meta: { requiresAuth: false }
  },
  {
    path: "/reports/invoices",
    name: "invoices_screen",
    component: InvoicesIndex,
    props: true,
  },
  {
    path: "/reports/payments",
    name: "payments_screen",
    component: PaymentsIndex,
    props: true,
    // meta: { requiresAuth: false }
  },
  {
    path: "/employees",
    name: "employees_screen",
    component: EmployeesIndex,
    props: true,
  },
  {
    path: "/items_stock",
    name: "items_stock_screen",
    component: ItemStockIndex,
    props: true,
  },
  {
    path: "/items_stock/categories",
    name: "categories_screen",
    component: CategoriesIndex,
    props: true,
  },
  {
    path: "/items_stock/products",
    name: "products_screen",
    component: ProductsIndex,
    props: true,
  },
  {
    path: "/items_stock/warehouses",
    name: "warehouses_screen",
    component: WarehousesIndex,
    props: true,
  },
  {
    path: "/items_stock/items",
    name: "items_screen",
    component: ItemsIndex,
    props: true,
  },
  {
    path: "/items_stock/inventories",
    name: "inventories_screen",
    component: InventoriesIndex,
    props: true,
  },
  {
    path: "/expenses",
    name: "expenses_screen",
    component: ExpensesIndex,
    props: true,
  },
  // {
  {
    path: "/expenses/types",
    name: "exp_types_screen",
    component: ExpensesTypesIndex,
    props: true,
  },
  {
    path: "/branches",
    name: "branches_screen",
    component: BranchesIndex,
    props: true,
  },
  {
    path: "/signIn",
    name: "signIn_screen",
    component: signInIndex,
    props: true,
  },
  {
    path: "/bank_money",
    name: "bankMoney_screen",
    component: BankMoneyIndex,
    props: true,
  },
  {
    path: "/reports",
    name: "reports_home_screen",
    component: ReportsIndex,
    props: true,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  // mode: "history", // Only Web
  base: process.env.BASE_URL,
  routes,
});

export default router;
