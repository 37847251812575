<template>
  <div>
    <app-bar-compo
      title="Employees"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <template v-slot:filters>
        <h3>Job Position</h3>
        <v-col cols="12" sm="6" md="6" lg="6" class="pa-0 pt-2 pm-2">
          <v-select
            dense
            v-model="positionFilter"
            :items="['Manager', 'Cashier', 'Salesman', 'Employee']"
            hide-selected
            outlined
            clearable
            required
          ></v-select>
        </v-col>
      </template>
      <template v-slot:actions>
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>

    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :search="searchTxt"
        :loading="isTableLoading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.reg_date="{ item }">
          <!-- <span>{{item.reg_date}}</span> -->
          <span>{{ dateTimeFormater(item.reg_date) }}</span>
        </template>
        <template v-slot:item.fire_date="{ item }">
          <!-- <span>{{item.reg_date}}</span> -->
          <span>{{ dateTimeFormater(item.fire_date) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('edit', item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="indigo darken-2"
                      class="ma-1"
                      @click="navigate('path', item)"
                    >
                      <v-icon>mdi-text-box-multiple</v-icon>
                    </v-btn>
                  </template>
                  <span>Paysheet</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="pink darken-2"
                      class="ma-1"
                      @click="navigate('path', item)"
                    >
                      <v-icon>mdi-cash</v-icon>
                    </v-btn>
                  </template>
                  <span>Collections</span>
                </v-tooltip> -->
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
      <!-- <v-fab-transition> -->
      <v-btn
        @click="dialogAction()"
        color="deep-orange accent-3"
        dark
        fixed
        bottom
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- </v-fab-transition> -->
    </v-container>
    <!--  -->
    <!-- Add/ Edit/ Delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dialogType == 'a'">
                <v-text-field
                  dense
                  v-model="editItem.emp_id"
                  label="ID"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-text-field
                  dense
                  v-model="editItem.emp_name"
                  label="Name"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  dense
                  v-model="editItem.emp_position"
                  label="Position"
                  :items="
                    dialogType == 'e'
                      ? editItem.emp_position == 'Cashier' ||
                        editItem.emp_position == 'Manager'
                        ? ['Manager', 'Cashier']
                        : ['Salesman', 'Employee']
                      : ['Manager', 'Cashier', 'Salesman', 'Employee']
                  "
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editItem.emp_work_br_id"
                  label="Work Branch ID"
                  :items="branches"
                  :loading="loadingDataEmpBranch"
                  hide-selected
                  outlined
                  clearable
                  required
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.nic_drive_card_no"
                  label="NIC/ Driving Licen No"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.address"
                  label="Address"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  no-resize
                  clearable
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.area"
                  label="Area"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.city"
                  label="City"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.contact_no"
                  label="Contact No"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="editItem.emp_status"
                  label="Account Status"
                  :items="['Active', 'Deactive']"
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="editItem.job_status"
                  label="Job Status"
                  :items="['Permanent', 'Temporary']"
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col v-if="dialogType != 'e'" cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.email"
                  label="Email"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col v-if="dialogType != 'e'" cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.password"
                  label="Password"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <!-- <v-col v-if="dialogType == 'e'" cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  dense
                  v-model="editItem.create_emp_id"
                  :items="empIDs"
                  label="Create Emp ID"
                  hide-selected
                  :loading="loadingDataEmpBranch"
                  outlined
                  clearable
                  required
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  v-model="regDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="regDate"
                      label="Register Date"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="regDate"
                    @input="regDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dialogType == 'e'">
                <v-menu
                  v-model="fireDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fireDate"
                      label="Fire Date"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fireDate"
                    @input="fireDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.note"
                  label="Note"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  clearable
                  no-resize
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(
                    editItem.emp_position == 'Cashier' ||
                      editItem.emp_position == 'Manager'
                      ? editItem.authID
                      : editItem.emp_id
                  )
                : deleteData(
                    editItem.emp_position == 'Cashier' ||
                      editItem.emp_position == 'Manager'
                      ? editItem.authID
                      : editItem.emp_id
                  )
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
var employeesRef;
var branchesRef;

export default {
  name: "employee_screen",
  mounted() {
    employeesRef = this.$fireStore.collection("employees");
    branchesRef = this.$fireStore.collection("branches");
    this.initData();
    this.loadBranchesData();
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      regDateMenu: false,
      regDate: null,
      fireDateMenu: false,
      fireDate: null,
      branches: [],
      // empIDs: [],
      loadingDataEmpBranch: false,
      // Filter
      positionFilter: null,
      // Buttons
      btnLoading: false,
      // Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      headers: [
        {
          text: "ID",
          align: "start",
          //   sortable: false,
          value: "emp_id",
        },
        {
          text: "Name",
          align: "start",
          //   sortable: false,
          value: "emp_name",
        },
        { text: "Position", value: "emp_position", width: 100 },
        {
          text: "Reg Branch",
          align: "start",
          value: "emp_reg_br_id",
        },
        {
          text: "Work Branch",
          align: "start",
          value: "emp_work_br_id",
        },
        { text: "NIC/D.L", value: "nic_drive_card_no" },
        { text: "Address", value: "address", sortable: false },
        { text: "Area", value: "area", sortable: false },
        { text: "City", value: "city", sortable: false },
        { text: "Contact No", value: "contact_no", sortable: false },
        {
          text: "Emp Status",
          value: "emp_status",
          align: "center",
        },
        {
          text: "Job Status",
          value: "job_status",
          align: "center",
        },
        {
          text: "Note",
          value: "note",
          // width: 250,
          class: "pad-class",
          sortable: false,
        },
        { text: "Create Emp ID", width: 130, value: "create_emp_id" },
        { text: "Register Date", width: 130, value: "reg_date" },
        { text: "Fire Date", width: 130, value: "fire_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await employeesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            snapshots.docs?.forEach((element) => {
              if (element.data()["emp_position"] != "Owner") {
                this.BodyData.push(element.data());
                this.BodyDataBack.push(element.data());
              }
            });
            this.isTableLoading = false;
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async loadBranchesData() {
      try {
        this.loadingDataEmpBranch = true;
        await branchesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.branches = [];
            snapshots.docs.forEach((element) => {
              this.branches.push(element.data()["br_id"]);
            });
            this.loadingDataEmpBranch = false;
          }
        );
      } catch (error) {
        this.loadingDataEmpBranch = false;
        console.log(error);
      }
    },
    // async initDataEmps() {
    //   try {
    //     this.loadingDataEmpBranch = true;
    //     await employeesRef.onSnapshot(
    //       { includeMetadataChanges: true },
    //       (snapshots) => {
    //         this.empIDs = [];
    //         snapshots.docs.forEach((element) => {
    //           if (
    //             element.data()["emp_position"] == "Cashier" ||
    //             element.data()["emp_position"] == "Manager"
    //           ) {
    //             this.empIDs.push(element.data()["emp_id"]);
    //           }
    //         });
    //         this.loadingDataEmpBranch = false;
    //       }
    //     );
    //   } catch (error) {
    //     this.loadingDataEmpBranch = false;
    //     console.log(error);
    //   }
    // },
    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item) {
      this.editItem = {};
      this.regDate = null;
      this.fireDate = null;
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        // set data to dclired variables
        this.regDate = this.dateTimeFormater(this.editItem?.reg_date);
        this.fireDate = this.dateTimeFormater(this.editItem?.fire_date);
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.emp_id = item.emp_id;
        this.editItem.authID = item.authID;
        this.editItem.emp_position = item.emp_position;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },
    // navigate(path) {
    //   try {
    //     this.$router.push(path).catch(() => {});
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    // Operating with DB
    addData() {
      try {
        if (this.editItem?.emp_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Employee ID.",
            "error",
          ]);
        } else if (this.editItem?.emp_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Employee Name.",
            "error",
          ]);
        } else if (this.editItem?.emp_position == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Job Position.",
            "error",
          ]);
        } else if (this.editItem?.emp_work_br_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Work Branch.",
            "error",
          ]);
        } else if (this.editItem?.nic_drive_card_no == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter NIC or Driver License No.",
            "error",
          ]);
        } else if (this.editItem?.contact_no == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Contact No.",
            "error",
          ]);
        } else if (this.editItem?.emp_status == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Account Status.",
            "error",
          ]);
        } else if (this.editItem?.job_status == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Job Status.",
            "error",
          ]);
        } else if (this.regDate == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Register Date.",
            "error",
          ]);
        } else {
          this.editItem.emp_id =
            "EMP" + this.editItem.emp_id.replace("EMP", "");

          if (
            this.editItem.emp_position == "Manager" ||
            this.editItem.emp_position == "Cashier"
          ) {
            if (this.editItem.email == null || this.editItem.email == "") {
              this.$store.commit("alertMessage/message", [
                "Please enter Email Address.",
                "error",
              ]);
            } else if (
              this.editItem.password == null ||
              this.editItem.password == ""
            ) {
              this.$store.commit("alertMessage/message", [
                "Please enter Password.",
                "error",
              ]);
            } else {
              this.btnLoading = true;
              // Firebase auth
              this.$auth
                .createUserWithEmailAndPassword(
                  this.editItem.email,
                  this.editItem.password
                )
                .then((userCredential) => {
                  // Signed in
                  if (userCredential.user != null) {
                    this.editItem.authID = userCredential.user.uid;
                    this.editItem.emp_reg_br_id = this.userData.s_using_branch;
                    this.editItem.create_emp_id = this.userData.s_user_id;
                    this.editItem.reg_date = new Date(this.regDate);
                  }
                })
                .then(() => {
                  // Resignin manage account
                  this.$auth
                    .signInWithEmailAndPassword(
                      this.userMP.s_user_email,
                      this.userMP.s_user_password
                    )
                    .then((userCredential) => {
                      return userCredential.user?.uid ?? null;
                    })
                    .then((uid) => {
                      if (uid != null) {
                        this.regEmp(true);
                      } else {
                        this.btnLoading = false;
                        this.$store.commit("alertMessage/message", [
                          "Current System User Crashed. Please Sign In again.",
                          "error",
                        ]);
                      }
                    })
                    .then(() => {
                      // Reload
                      this.initData();
                    })
                    .catch((error) => {
                      this.btnLoading = false;
                      this.$store.commit("alertMessage/message", [
                        error,
                        "error",
                      ]);
                    });
                })
                .catch((error) => {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [error, "error"]);
                });
            }
          } else {
            this.btnLoading = true;
            this.regEmp(false);
          }
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    regEmp(isAuth) {
      try {
        employeesRef
          .doc(isAuth ? this.editItem.authID : this.editItem.emp_id)
          .set({
            ...this.editItem,
          })
          .then(() => {
            this.editItem = {};
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [
              "Data added successfully.",
              "success",
            ]);
          });
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async editData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Employee Reg ID.",
            "error",
          ]);
        } else if (this.editItem?.emp_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Employee Name.",
            "error",
          ]);
        } else if (this.editItem?.emp_position == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Job Position.",
            "error",
          ]);
        } else if (this.editItem?.emp_work_br_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Work Branch.",
            "error",
          ]);
        } else if (this.editItem?.nic_drive_card_no == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter NIC or Driver License No.",
            "error",
          ]);
        } else if (this.editItem?.contact_no == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Contact No.",
            "error",
          ]);
        } else if (this.editItem?.emp_status == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Account Status.",
            "error",
          ]);
        } else if (this.editItem?.job_status == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Employee Job Status.",
            "error",
          ]);
        } else if (this.regDate == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Register Date.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          this.editItem.reg_date = new Date(this.regDate);
          // Check user auth id exist in firestore
          employeesRef
            .doc(id)
            .update({
              ...this.editItem,
            })
            .then(() => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data updated successfully.",
                "success",
              ]);
            })
            .catch((error) => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [error, "error"]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },

    deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Employee Reg ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          employeesRef
            .doc(id)
            .delete()
            .then(() => {
              this.btnLoading = false;
              this.dialog = false;
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            })
            .then(async () => {
              await this.$auth
                .onAuthStateChanged(async (user) => {
                  if (user?.uid == id) {
                    await this.$auth
                      .signOut()
                      .then(() => {
                        localStorage.removeItem("systemuser");
                        this.$store.commit("alertMessage/message", [
                          "Sign Out",
                          "success",
                        ]);
                      })
                      .catch((error) => {
                        this.btnLoading = false;
                        this.$store.commit("AlertMessage/message", [
                          error,
                          "error",
                        ]);
                      });
                  }
                })
                .catch((error) => {
                  this.btnLoading = false;
                  this.$store.commit("AlertMessage/message", [error, "error"]);
                });
            })
            .catch((error) => {
              this.btnLoading = false;
              this.$store.commit("AlertMessage/message", [error, "error"]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    filterData() {
      try {
        this.BodyData = this.BodyDataBack.filter(
          (value) => value.emp_position == this.positionFilter
        );
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.positionFilter = null;
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>