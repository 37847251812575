import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import {
  fireStore,
  storage,
  messaging,
  firebase,
  auth,
} from "./firebaseConfig.js";
import { dateTimeFormater } from "./helper";
import moment from "moment";
// import globally css
import "@/styles/styles.css";
// Components
import AppBar from "@/components/app_bar.vue";
import SearchText from "@/components/common/search_text.vue";
// window.escpos = require("escpos");
// import  usb from 'escpos-usb';
// Mixins
import systemUser from "@/mixins/systemUserMixin.js";

Vue.component("app-bar-compo", AppBar);
Vue.component("search-txt-compo", SearchText);

// Prototypes
Vue.prototype.$fireStore = fireStore;
Vue.prototype.$storage = storage;
Vue.prototype.$messaging = messaging;
Vue.prototype.$firebase = firebase;
Vue.prototype.$auth = auth;
Vue.prototype.$batch = firebase.firestore(); // All batches will declare inside current working functions
Vue.prototype.$dateTime = dateTimeFormater;
Vue.prototype.$moment = moment;
// Vue.prototype.$uuid = uuid();
// Maxins
Vue.mixin(systemUser);
//
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
