<template>
  <div>
    <app-bar-compo
      :title="
        this.$route.query.id != null
          ? 'Constituent - Payments'
          : 'Constituents - Payments'
      "
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <!-- Filters -->
      <template v-slot:filters>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Invoice No</h3>
            <v-autocomplete
              class="mt-2"
              dense
              v-model="invoiceNo"
              :items="invoiceNos"
              :loading="loadingDataSalmEmpInvo"
              hide-selected
              outlined
              clearable
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Salesman ID</h3>
            <v-autocomplete
              class="mt-2"
              dense
              v-model="smanID"
              :loading="loadingDataSalmEmpInvo"
              :items="salesmanIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Employee ID</h3>
            <v-autocomplete
              class="mt-2"
              dense
              v-model="empID"
              :loading="loadingDataSalmEmpInvo"
              :items="empIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Payment Type</h3>
            <v-select
              class="mt-2"
              dense
              v-model="payType"
              :items="['Invo_Init', 'Common', 'Return_Pay', 'Get_Pay']"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
        </v-row>
        <br />
        <v-col class="ma-0 pa-0 mt-4">
          <v-row class="pa-2"
            ><v-checkbox
              class="ma-0 pa-0 mr-3"
              v-model="withDateRange"
              label="with"
            ></v-checkbox>
            <h3>Date Range</h3>
          </v-row>

          <v-col v-if="withDateRange" class="ma-0 pa-0">
            <v-radio-group
              v-model="dateRangeType"
              column
              style="min-height: 160px"
              mandatory
              class="pa-0 ma-0 mt-2 mb-2"
            >
              <template v-slot:label>
                <h3>Select a type</h3>
              </template>
              <v-radio value="create">
                <template v-slot:label>
                  <span>Create Date</span>
                </template>
              </v-radio>
              <!-- <v-radio value="pay_ret">
                <template v-slot:label>
                  <span>Payment Return Date</span>
                </template>
              </v-radio>
              <v-radio value="pay_ret_back">
                <template v-slot:label>
                  <span>Payment Return Back Date</span>
                </template>
              </v-radio> -->
              <v-radio value="che_issue">
                <template v-slot:label>
                  <span>Cheque Issue Date</span>
                </template>
              </v-radio>
              <v-radio value="che_realise">
                <template v-slot:label>
                  <span>Cheque Realise Date</span>
                </template>
              </v-radio>
              <v-radio value="che_ret">
                <template v-slot:label>
                  <span>Cheque Return Date</span>
                </template>
              </v-radio>
            </v-radio-group>
            <v-date-picker
              v-if="withDateRange"
              v-model="pickDateRange"
              range
              full-width
              header-color="blue"
              color="green"
              class="pa-0 ma-0"
            ></v-date-picker>
          </v-col>
        </v-col>
      </template>

      <template v-slot:actions>
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red darken-2"
              dark
              icon
              v-bind="attrs"
              v-on="on"
              :loading="btnLoading"
              :disabled="btnLoading"
              @click="dialogAction('dall')"
            >
              <v-icon>mdi-delete</v-icon>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </template>
          <span>Delete All Data</span>
        </v-tooltip>
      </template>
    </app-bar-compo>
    <!-- Body -->
    <h4 v-if="this.$route.query.id != null" class="mt-3 ml-3 text-caption-new">
      {{ this.$route.query.id }} Payments
    </h4>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :search="searchTxt"
        :page.sync="page"
        :loading="isTableLoading"
        :items-per-page="itemsPerPage"
        hide-default-footer
        sort-by="create_date"
        :sort-desc="true"
        class="elevation-1 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.che_issue_date="{ item }">
          <span>{{ dateTimeFormater(item.che_issue_date) }}</span>
        </template>
        <template v-slot:item.che_realise_date="{ item }">
          <span>{{ dateTimeFormater(item.che_realise_date) }}</span>
        </template>
        <template v-slot:item.che_return_date="{ item }">
          <span>{{ dateTimeFormater(item.che_return_date) }}</span>
        </template>
        <template v-slot:item.create_date="{ item }">
          <span>{{ dateTimeFormater(item.create_date) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('edit', item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
      <!-- <v-fab-transition> -->
      <v-btn
        @click="dialogAction()"
        color="deep-orange accent-3"
        dark
        fixed
        bottom
        fab
        v-if="this.$route.query.id != null"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- </v-fab-transition> -->
    </v-container>
    <!--  -->
    <!-- Edit/ Delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd' && dialogType != 'dall'">
          <v-container>
            <v-row>
              <!-- <v-row class="ma-0 pa-0" > -->
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  dense
                  v-model="editItem.salesman_id"
                  :items="salesmanIDs"
                  label="Salesman ID"
                  hide-selected
                  :loading="loadingDataSalmEmpInvo"
                  outlined
                  clearable
                  required
                ></v-autocomplete>
              </v-col>
              <v-col v-if="dialogType == 'a'" cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="editItem.payment_type"
                  :items="['Invo_Init', 'Common']"
                  label="Payment Type"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col
                v-if="editItem.payment_type == 'Invo_Init'"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-autocomplete
                  dense
                  v-model="editItem.invoice_no"
                  :items="invoiceNos"
                  :loading="loadingDataSalmEmpInvo"
                  hide-selected
                  label="Invoice No"
                  outlined
                  clearable
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dialogType == 'a'">
                <v-text-field
                  dense
                  v-model="editItem.cash_amount"
                  label="Cash Amount"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dialogType == 'a'">
                <v-text-field
                  dense
                  v-model="editItem.card_amount"
                  label="Card Amount"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dialogType == 'a'">
                <v-text-field
                  dense
                  v-model="editItem.cheque_amount"
                  label="Cheque Amount"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-row
                v-if="editItem.cheque_amount > 0 || dialogType == 'a'"
                class="ma-0 pa-0"
              >
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-text-field
                    dense
                    v-model="editItem.che_no"
                    label="Cheque No"
                    outlined
                    clearable
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-menu
                    v-model="cheIssueDateModel"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="issueDate"
                        label="Cheque Issue Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="issueDate"
                      @input="cheIssueDateModel = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="dialogType == 'e'" cols="12" sm="6" md="6" lg="6">
                  <v-select
                    dense
                    v-model="isCheRealise"
                    :items="[true, false]"
                    label="Is Cheque Realise?"
                    hide-selected
                    outlined
                    clearable
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-menu
                    v-model="cheRealiseDateModel"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="realiseDate"
                        label="Cheque Realise Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="realiseDate"
                      @input="cheRealiseDateModel = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="dialogType == 'e'" cols="12" sm="6" md="6" lg="6">
                  <v-select
                    dense
                    v-model="isCheReturn"
                    :items="[true, false]"
                    label="Is Cheque Return?"
                    hide-selected
                    outlined
                    clearable
                    required
                  ></v-select>
                </v-col>
                <!-- <v-col v-if="dialogType == 'e'" cols="12" sm="6" md="6" lg="6">
                  <v-autocomplete
                    dense
                    v-model="editItem.che_ret_emp_id"
                    :items="empIDs"
                    label="Cheque Return Emp ID"
                    hide-selected
                    :loading="loadingDataSalmEmpInvo"
                    outlined
                    clearable
                    required
                  ></v-autocomplete>
                </v-col> -->
                <v-col v-if="dialogType == 'e'" cols="12" sm="6" md="6" lg="6">
                  <v-menu
                    v-model="cheReturnDateModel"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="returnDate"
                        label="Cheque Return Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="returnDate"
                      @input="cheReturnDateModel = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(editItem.pay_id)
                : dialogType == 'd'
                ? deleteData(editItem.pay_id)
                : deleteAllData()
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!--  -->
    <!-- Balance Dialog -->
    <!--  -->
    <!--  -->
    <v-dialog v-model="isBalanceDialog" width="400">
      <v-card>
        <v-card-title class="green--text darken-3 pa-0 px-4 pt-3 title-center">
          <v-icon class="appBar-icon pr-2">mdi-coffee-maker-check</v-icon>
          Payment created Successfully...
        </v-card-title>
        <v-row justify="center" align-content="center" class="ma-0 pa-4">
          <span class="custom-f">{{ "Balance :" }}</span>
          <span class="custom-f text-caption-new pl-1">{{
            "Rs." + balance.toFixed(2)
          }}</span>
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

var invoicesRef;
var paymentsRef;
var employeesRef;
var constituentsRef;
var bankMoneyRef;

export default {
  name: "payments_screen",
  mounted() {
    // this.$firebase
    //   .firestore()
    //   .clearPersistence()
    //   .catch((error) => {
    //     console.error("Could not enable persistence:", error.code);
    //   });
    constituentsRef = this.$fireStore.collection("constituents");
    invoicesRef = this.$fireStore.collectionGroup("invoices");
    paymentsRef = this.$fireStore.collectionGroup("payments");
    employeesRef = this.$fireStore.collection("employees");
    bankMoneyRef = this.$fireStore.collection("bank_money");
    this.initData();
    this.initDataSalesmansEmps();
    this.initDataInvoiceNos();
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      // withAmountUpdate: false,
      isCheRealise: false,
      isCheReturn: false,
      loadingDataSalmEmpInvo: false,
      cheIssueDateModel: false,
      cheRealiseDateModel: false,
      cheReturnDateModel: false,
      issueDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      realiseDate: null,
      returnDate: null,
      // Common
      salesmanIDs: [],
      empIDs: [],
      // Balance Dialog
      isBalanceDialog: false,
      balance: 0,
      //   Filters
      pickDateRange: [],
      invoiceNo: null,
      smanID: null,
      invoiceNos: [],
      payType: null,
      empID: null,
      dateRangeType: "create",
      withDateRange: false,
      // Button
      btnLoading: false,
      //   Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      headers: [
        {
          text: "Invoice No",
          align: "start",
          //   sortable: false,
          value: "invoice_no",
        },
        { text: "Cons ID", value: "cons_id", sortable: false },
        { text: "Cons Type", value: "cons_type", sortable: false },
        { text: "Salesman ID", value: "salesman_id" },
        { text: "Payment Type", value: "payment_type" },
        { text: "Cash Amount", value: "cash_amount" },
        { text: "Card Amount", value: "card_amount" },
        { text: "Cheque Amount", value: "cheque_amount" },
        { text: "Cheque No", value: "che_no", sortable: false },
        { text: "Issue Date", value: "che_issue_date" },
        { text: "Realise", value: "is_che_realise" },
        { text: "Realise Date", value: "che_realise_date" },
        { text: "Return", value: "is_che_return" },
        { text: "Ch Return Emp ID", value: "che_ret_emp_id", sortable: false },
        { text: "Return Date", value: "che_return_date" },
        { text: "Create Emp ID", value: "create_emp_id", sortable: false },
        { text: "Create Date", width: 130, value: "create_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        var id = this.$route.query.id ?? null;
        var ref =
          id != null ? paymentsRef.where("cons_id", "==", id) : paymentsRef;
        await ref.onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
          this.BodyData = [];
          this.BodyDataBack = [];
          snapshots.docs.forEach((element) => {
            this.BodyData.push(element.data());
            this.BodyDataBack.push(element.data());
          });
          this.isTableLoading = false;
        });
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async initDataInvoiceNos() {
      try {
        this.loadingDataSalmEmpInvo = true;
        var id = this.$route.query.id ?? null;
        var ref =
          id != null ? invoicesRef.where("cons_id", "==", id) : invoicesRef;
        await ref.onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
          this.invoiceNos = [];
          snapshots.docs.forEach((element) => {
            this.invoiceNos.push(element.data()["invoice_no"]);
          });
          this.loadingDataSalmEmpInvo = false;
        });
      } catch (error) {
        this.loadingDataSalmEmpInvo = false;
        console.log(error);
      }
    },

    async initDataSalesmansEmps() {
      try {
        this.loadingDataSalmEmpInvo = true;
        await employeesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.salesmanIDs = [];
            this.empIDs = [];
            snapshots.docs.forEach((element) => {
              if (element.data()["emp_position"] == "Salesman") {
                this.salesmanIDs.push(element.data()["emp_id"]);
              } else if (
                element.data()["emp_position"] == "Cashier" ||
                element.data()["emp_position"] == "Manager"
              ) {
                this.empIDs.push(element.data()["emp_id"]);
              }
            });
            this.loadingDataSalmEmpInvo = false;
          }
        );
      } catch (error) {
        this.loadingDataSalmEmpInvo = false;
        console.log(error);
      }
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item) {
      this.editItem = {};
      this.editItemConst = {};
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        this.isCheRealise = item.is_che_realise;
        this.isCheReturn = item.is_che_return;
        this.issueDate = this.dateTimeFormater(item.che_issue_date);
        this.realiseDate = this.dateTimeFormater(item.che_realise_date);
        this.returnDate = this.dateTimeFormater(item.che_return_date);
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.pay_id = item.pay_id;
        this.editItem.che_no = item.che_no ?? null;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else if (type == "dall") {
        // this.editItem.pay_id = item.pay_id;
        // this.editItem.cons_id = item.cons_id;
        this.dialogType = "dall";
        this.dialogTitle = "Do you want to delete all of the data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    // Operating with DB
    // async getInvoiceData() {
    //   try {
    //     await invoicesRef
    //       .doc(this.editItem.invoice_no)
    //       .get()
    //       .then((snapshot) => {
    //         return Number(snapshot?.data()["net_total"]) ?? 0;
    //       })
    //       .then((value) => {
    //         // get total of payments of invoice
    //         paymentsRef
    //           .where("invoice_no", "==", this.editItem.invoice_no)
    //           .get()
    //           .then((querySnapshot) => {
    //             var totalCashAmount = 0;
    //             var totalCardAmount = 0;
    //             var totalChequeAmount = 0;

    //             querySnapshot.docs?.forEach((element) => {
    //               totalCashAmount += Number(element.data()["cash_amount"] ?? 0);
    //               totalCardAmount += Number(element.data()["card_amount"] ?? 0);
    //               totalChequeAmount += element.data()["is_che_return"]
    //                 ? 0
    //                 : Number(element.data()["cheque_amount"] ?? 0);
    //             });
    //             return [
    //               value,
    //               Number(totalCashAmount + totalCardAmount + totalChequeAmount),
    //             ];
    //           })
    //           .then((value) => {
    //             this.invoice_net_amount = value[0];
    //             this.invoice_net_payments = value[1];
    //           })
    //           .catch((e) => {
    //             console.log(e);
    //           });
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async addData() {
      try {
        if (this.$route.query.id == null || this.$route.query.id == "") {
          this.$store.commit("alertMessage/message", [
            "The Constituent ID is not valid. Please go back to 'Constituents' screen and come again.",
            "error",
          ]);
        } else if (
          this.$route.query.cons_type == null ||
          this.$route.query.cons_type == ""
        ) {
          this.$store.commit("alertMessage/message", [
            "The Constituent Type is not valid. Please go back to 'Constituents' screen and come again.",
            "error",
          ]);
        } else if (
          this.editItem.payment_type == null ||
          this.editItem.payment_type == ""
        ) {
          this.$store.commit("alertMessage/message", [
            "Please select Payment Type.",
            "error",
          ]);
        } else if (
          this.editItem.payment_type == "Invo_Init" &&
          (this.editItem.invoice_no == null || this.editItem.invoice_no == "")
        ) {
          this.$store.commit("alertMessage/message", [
            "Please select the Invoice No.",
            "error",
          ]);
        } else {
          // Total of new payments
          let newPaymentAmount =
            Number(this.editItem.cash_amount ?? 0) +
            Number(this.editItem.card_amount ?? 0) +
            Number(this.editItem.cheque_amount ?? 0);
          //
          // if (this.editItem.payment_type == "Single") {
          if (
            Number(this.editItem.cheque_amount) > 0 &&
            (this.editItem.che_no == null || this.editItem.che_no == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please enter Cheque No.",
              "error",
            ]);
          } else if (
            Number(this.editItem.cheque_amount) > 0 &&
            (this.issueDate == null || this.issueDate == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please select Cheque Issue Date.",
              "error",
            ]);
          } else if (
            Number(this.editItem.cheque_amount) > 0 &&
            (this.realiseDate == null || this.realiseDate == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please select Cheque Realise Date.",
              "error",
            ]);
          } else if (newPaymentAmount <= 0) {
            this.$store.commit("alertMessage/message", [
              "Please enter Valid Payments.",
              "error",
            ]);
          } else {
            this.btnLoading = true;
            if (this.editItem.payment_type == "Invo_Init") {
              await invoicesRef
                .where("invoice_no", "==", this.editItem.invoice_no)
                .get()
                .then(async (snapshots) => {
                  if (
                    Number(snapshots?.docs[0]?.data()["net_total"] ?? 0) != 0 &&
                    Number(snapshots?.docs[0]?.data()["net_total"]) ==
                      newPaymentAmount
                  ) {
                    await this.addDataToDB();
                  } else {
                    this.btnLoading = false;
                    this.$store.commit("alertMessage/message", [
                      `Invalid amount of payments. Valid amount is ${Number(
                        snapshots?.docs[0]?.data()["net_total"].toFixed(2)
                      )}`,
                      "error",
                    ]);
                  }
                });
            } else {
              await this.getDetailsInvosAndPayments(this.$route.query.id)
                .then(async (values) => {
                  // Make balance [totalOfOnvoicess - cashCardAmount + chequeAmount],
                  let balance = values[2] - (values[0] + values[1]);
                  // Check have a balance?
                  if (balance > 0) {
                    if (
                      Number(this.editItem.card_amount ?? 0) +
                        Number(this.editItem.cheque_amount ?? 0) >
                      balance
                    ) {
                      this.btnLoading = false;
                      this.$store.commit("alertMessage/message", [
                        "Invalid amount of payments.",
                        "error",
                      ]);
                    } else {
                      this.balance = newPaymentAmount - balance;
                      await this.addDataToDB();
                    }
                  } else {
                    this.btnLoading = false;
                    this.$store.commit("alertMessage/message", [
                      "Payment is not required.",
                      "error",
                    ]);
                  }
                })
                .catch((e) => {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [e, "error"]);
                });
            }
          }
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async addDataToDB() {
      try {
        // Add data to DB
        constituentsRef
          .doc(this.$route.query.id)
          .get()
          .then(async (snapshot) => {
            // Generate payment id
            var payID = uuid();
            await snapshot.ref
              ?.collection("payments")
              .doc(payID)
              .set({
                pay_id: payID,
                invoice_no: this.editItem.invoice_no ?? null,
                cons_type: this.$route.query.cons_type,
                cons_id: this.$route.query.id,
                salesman_id: this.editItem.salesman_id ?? null,
                cash_amount:
                  this.balance > 0
                    ? Number(this.editItem.cash_amount ?? 0) - this.balance
                    : Number(this.editItem.cash_amount ?? 0),
                card_amount: Number(this.editItem.card_amount ?? 0),
                cheque_amount: Number(this.editItem.cheque_amount ?? 0),
                che_no: this.editItem.che_no ?? null,
                che_issue_date:
                  this.editItem.che_no != null
                    ? new Date(this.issueDate)
                    : null,
                is_che_realise: this.editItem.che_no != null ? false : null,
                che_realise_date:
                  this.editItem.che_no != null
                    ? new Date(this.realiseDate)
                    : null,
                is_che_return: this.editItem.che_no != null ? false : null,
                payment_type: this.editItem.payment_type,
                create_emp_id: this.userData.s_user_id,
                create_date: new Date(),
              });
            return payID;
          })
          .then(async (payID) => {
            if (Number(this.editItem.card_amount) > 0) {
              // Generate trans id
              var transID = uuid();
              await bankMoneyRef.doc(transID).set({
                trans_id: transID,
                pay_id: payID,
                trans_type: "Card",
                note: "Card Payment",
                dip_amount:
                  this.$route.query.cons_type == "CUS"
                    ? Number(this.editItem.card_amount)
                    : 0,
                with_amount:
                  this.$route.query.cons_type == "SUP"
                    ? Number(this.editItem.card_amount)
                    : 0,
                create_emp_id: this.userData.s_user_id,
                trans_date: new Date(),
              });
            }
          })
          .then(() => {
            this.editItem = {};
            this.btnLoading = false;
            if (this.balance > 0) {
              this.isBalanceDialog = !this.isBalanceDialog;
            } else {
              this.$store.commit("alertMessage/message", [
                "Data added successfully.",
                "success",
              ]);
            }
          })
          .catch((e) => {
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [e, "error"]);
          });
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async editData(id) {
      try {
        if (id == null || id == "") {
          this.$store.commit("alertMessage/message", [
            "Payment ID is not valid.",
            "error",
          ]);
        } else {
          if (
            Number(this.editItem.cheque_amount) > 0 &&
            (this.editItem.che_no == null || this.editItem.che_no == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please enter Cheque No.",
              "error",
            ]);
          } else if (
            this.isCheReturn &&
            (this.editItem.che_ret_emp_id == null ||
              this.editItem.che_ret_emp_id == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please select Cheque Return Emp ID.",
              "error",
            ]);
          } else if (
            this.isCheReturn &&
            (this.returnDate == null || this.returnDate == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please select Cheque Return Date.",
              "error",
            ]);
          } else if (
            this.editItem.payment_type == "Invo_Init" &&
            (this.editItem.invoice_no == null || this.editItem.invoice_no == "")
          ) {
            this.$store.commit("alertMessage/message", [
              "Please select the Invoice No.",
              "error",
            ]);
          } else {
            this.btnLoading = true;
            await this.getDetailsInvosAndPayments(this.editItem.cons_id)
              .then(async (values) => {
                // Make balance [totalOfOnvoicess - cashCardAmount + chequeAmount]
                this.balance = values[2] - (values[0] + values[1]);

                // Identify if need cheque return && return back process
                var isCheRetValid = false;
                if (this.editItem.is_che_return && !this.isCheReturn) {
                  if (
                    Number(this.balance) >= Number(this.editItem.cheque_amount)
                  ) {
                    isCheRetValid = true;
                  } else {
                    isCheRetValid = false;
                  }
                } else {
                  isCheRetValid = true;
                }
                return isCheRetValid;
              })
              .then(async (value) => {
                if (!value) {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [
                    "Cheque is can't marke as Return Back. Its amount is not valid now.",
                    "error",
                  ]);
                }

                await paymentsRef
                  .where("pay_id", "==", id)
                  .get()
                  .then(async (querySnapshot) => {
                    await querySnapshot?.docs[0]?.ref
                      .update({
                        salesman_id: this.editItem.salesman_id ?? null,
                        invoice_no: this.editItem.invoice_no ?? null,
                        che_no: this.editItem.che_no ?? null,
                        che_issue_date:
                          this.editItem.che_no != null
                            ? new Date(this.issueDate)
                            : null,
                        is_che_realise:
                          this.editItem.che_no != null
                            ? this.isCheRealise
                            : null,
                        che_realise_date:
                          this.editItem.che_no != null
                            ? new Date(this.realiseDate)
                            : null,
                        is_che_return:
                          this.editItem.che_no != null
                            ? value
                              ? this.isCheReturn
                                ? true
                                : false
                              : this.editItem.is_che_return
                            : null,
                        che_ret_emp_id:
                          this.editItem.che_no != null
                            ? value
                              ? this.isCheReturn
                                ? this.userData.s_user_id
                                : null
                              : this.userData.s_user_id
                            : null,
                        che_return_date:
                          this.editItem.che_no != null
                            ? value
                              ? this.isCheReturn
                                ? new Date(this.returnDate)
                                : null
                              : this.editItem.che_return_date
                            : null,
                      })
                      .catch((e) => {
                        this.btnLoading = false;
                        this.$store.commit("alertMessage/message", [
                          e,
                          "error",
                        ]);
                      });
                    // Bank/money account update
                    if (
                      this.editItem.che_no != null &&
                      value &&
                      this.isCheReturn
                    ) {
                      await bankMoneyRef
                        .where("che_no", "==", this.editItem.che_no)
                        .get()
                        .then((querySnapshot) => {
                          if (querySnapshot.docs?.length != 0) {
                            querySnapshot.docs[0].ref.delete();
                          }
                        });
                    }
                    // Bank/money account update
                    if (this.editItem.che_no != null) {
                      await bankMoneyRef
                        .where("che_no", "==", this.editItem.che_no)
                        .get()
                        .then((querySnapshot) => {
                          if (querySnapshot.docs?.length != 0) {
                            querySnapshot.docs[0].ref.update({
                              note: this.isCheRealise
                                ? `Cheque Realised - ${this.editItem.che_no}`
                                : this.editItem.cons_type == "CUS"
                                ? `Cheque Diposit - ${this.editItem.che_no}`
                                : `Cheque Withdrawal - ${this.editItem.che_no}`,
                            });
                          }
                        });
                    }
                  })
                  .then(() => {
                    this.editItem = {};
                    this.btnLoading = false;
                    this.dialog = false;
                    this.$store.commit("alertMessage/message", [
                      "Data updated successfully.",
                      "success",
                    ]);
                  })
                  .catch((e) => {
                    this.btnLoading = false;
                    this.$store.commit("alertMessage/message", [e, "error"]);
                  });
              })
              .catch((e) => {
                this.btnLoading = false;
                this.$store.commit("alertMessage/message", [e, "error"]);
              });
          }
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Payment ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          await paymentsRef
            .where("pay_id", "==", id)
            .get()
            .then(async (querySnapshot) => {
              querySnapshot?.docs[0]?.ref.delete();
            })
            .then(async () => {
              // If has card payment(s) in bank/money account, Delete payment
              await bankMoneyRef
                .where("pay_id", "==", id)
                .get()
                .then(async (querySnapshot) => {
                  if (querySnapshot.docs?.length != 0) {
                    await querySnapshot.docs.forEach(async (snapshot) => {
                      await snapshot.ref.delete();
                    });
                  }
                });

              // If has cheque payment(s) in bank/money account, Delete payment
              await bankMoneyRef
                .where("che_no", "==", this.editItem?.che_no)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.docs?.length != 0) {
                    querySnapshot.docs[0].ref.delete();
                  }
                });
            })
            .then(() => {
              this.btnLoading = false;
              this.dialog = false;
              this.editItem = {};
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            })
            .catch((e) => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [e, "error"]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async deleteAllData() {
      try {
        if (this.BodyData.length == 0) {
          this.$store.commit("alertMessage/message", [
            "Not data found.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          // Delete all of the filtered data
          await paymentsRef
            .get()
            .then(async (querySnapshot) => {
              await querySnapshot?.docs?.forEach((doc) => {
                if (
                  this.BodyData.some((e) => e.pay_id === doc.data()["pay_id"])
                )
                  doc.ref.delete();
              });
            })
            .then(async () => {
              // Manage Bank/Money Account
              await this.BodyData.forEach(async (el) => {
                // If has card payment(s) in bank/money account, Delete payment
                await bankMoneyRef
                  .where("pay_id", "==", el?.pay_id)
                  .get()
                  .then(async (querySnapshot) => {
                    if (querySnapshot.docs?.length != 0) {
                      await querySnapshot.docs.forEach(async (snapshot) => {
                        await snapshot.ref.delete();
                      });
                    }
                  });

                // If has cheque payment(s) in bank/money account, Delete payment
                await bankMoneyRef
                  .where("che_no", "==", el?.che_no)
                  .get()
                  .then((querySnapshot) => {
                    if (querySnapshot.docs?.length != 0) {
                      querySnapshot.docs[0].ref.delete();
                    }
                  });
              });
            })
            .then(() => {
              this.btnLoading = false;
              this.dialog = false;
              this.editItem = {};
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Get Details of constituent invoices and payments
    async getDetailsInvosAndPayments(consID) {
      try {
        var returnData = null;
        await constituentsRef
          .doc(consID)
          .get()
          .then(async (snapshot) => {
            let cashCardAmount = 0;
            let chequeAmount = 0;
            let totalOfOnvoicess = 0;
            var chequesDetails = [];
            // get payments amount
            await snapshot.ref
              ?.collection("payments")
              .get()
              .then((querySnapshot) => {
                let totalOfCashRet = 0;
                let totalOfCardRet = 0;
                let totalOfCash = 0;
                let totalOfCard = 0;
                querySnapshot.docs?.forEach((element) => {
                  // Get Return and not return amounts
                  var logic =
                    element?.data().cons_type == "CUS"
                      ? element?.data()["payment_type"] == "Return_Pay" // Identify first process
                      : element?.data()["payment_type"] == "Get_Pay"; // Identify first process // Like return
                  //
                  if (logic) {
                    totalOfCashRet += Number(
                      element.data()["cash_amount"] ?? 0
                    );
                    totalOfCardRet += Number(
                      element.data()["card_amount"] ?? 0
                    );
                  } else {
                    totalOfCash += Number(element.data()["cash_amount"] ?? 0);
                    totalOfCard += Number(element.data()["card_amount"] ?? 0);
                  }

                  // Cal Cheque Amount
                  chequeAmount += Number(
                    !element.data().is_che_return
                      ? element.data().cheque_amount ?? 0
                      : 0
                  );
                  // Find cheque details
                  if (
                    !element.data().is_che_realise &&
                    !element.data().is_che_return &&
                    Number(element.data().cheque_amount ?? 0) != 0
                  ) {
                    chequesDetails.push(element.data());
                  }
                });
                return (
                  totalOfCard + totalOfCash - (totalOfCardRet + totalOfCashRet)
                ); // Net Cash Amount = totalOfCash - totalOfCashRet
              })
              .then((value) => {
                cashCardAmount = value;
              });
            // get invoices net amount
            await snapshot.ref
              .collection("invoices")
              .get()
              .then((querySnapshot) => {
                querySnapshot.docs?.forEach((element) => {
                  totalOfOnvoicess += Number(element.data().net_total ?? 0);
                });
              });

            return [
              cashCardAmount,
              chequeAmount,
              totalOfOnvoicess,
              chequesDetails,
            ];
          })
          .then((value) => {
            returnData = value;
          });
        return returnData;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    // Filters
    filterData() {
      try {
        var tempList = this.BodyDataBack;
        // Filter unit 01
        if (this.invoiceNo != null && this.invoiceNo != "") {
          tempList = tempList.filter(
            (value) => value.invoice_no == this.invoiceNo
          );
        }
        // Filter unit 02
        if (this.smanID != null && this.smanID != "") {
          tempList = tempList.filter(
            (value) => value.salesman_id == this.smanID
          );
        }
        // Filter unit 03
        if (this.empID != null && this.empID != "") {
          tempList = tempList.filter(
            (value) => value.create_emp_id == this.empID
          );
        }
        // Filter unit 04
        if (this.payType != null && this.payType != "") {
          tempList = tempList.filter(
            (value) => value.payment_type == this.payType
          );
        }
        // Filter unit 05
        if (this.withDateRange) {
          if (this.pickDateRange.length != 2) {
            this.$store.commit("alertMessage/message", [
              "Please select the date range.",
              "warning",
            ]);
          } else {
            tempList = tempList.filter((value) => {
              var dateType = value?.create_date;
              switch (this.dateRangeType) {
                case "create":
                  dateType = value?.create_date ?? null;
                  break;
                // case "pay_ret":
                //   dateType = value?.create_date ?? null;
                //   break;
                // case "pay_ret_back":
                //   dateType = value?.create_date ?? null;
                //   break;
                case "che_realise":
                  dateType = value?.che_realise_date ?? null;
                  break;
                case "che_ret":
                  dateType = value?.che_return_date ?? null;
                  break;
                case "che_issue":
                  dateType = value?.che_issue_date ?? null;
                  break;
                default:
                  break;
              }
              var date01 = new Date(this.dateTimeFormater(dateType));
              var date02 = new Date(this.pickDateRange[0]);
              var date03 = new Date(this.pickDateRange[1]);
              //
              return date01 >= date02 && date01 <= date03;
            });
          }
        }
        this.BodyData = tempList ?? [];
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.invoiceNo = null;
        this.smanID = null;
        this.empID = null;
        this.withDateRange = false;
        this.pickDateRange = [];
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
</style>