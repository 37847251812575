<template>
  <div>
    <app-bar-compo
      title="Items Stock"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <template v-slot:filters>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <h3>Item Type</h3>
            <v-select
              class="mt-2"
              dense
              v-model="typeFilter"
              :items="['RI', 'PI', 'AI', 'MI']"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <h3>Item Code</h3>
            <v-autocomplete
              class="mt-2"
              dense
              v-model="itemCodeFilter"
              :items="itemsCodes"
              hide-selected
              outlined
              clearable
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <h3>Branch ID</h3>
            <v-select
              class="mt-2"
              dense
              v-model="branchIDFilter"
              :items="BranchIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <h3>Warehouse ID</h3>
            <v-select
              class="mt-2"
              dense
              v-model="whIDFilter"
              :items="WhIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>

    <v-container fluid class="st-container">
      <!-- Navigate sections -->
      <div>
        <v-row class="ma-0 mt-2 ml-2">
          <v-btn text @click="navigate('items_stock/categories')"
            >Categories</v-btn
          >
          <v-btn text @click="navigate('items_stock/products')">Products</v-btn>
          <v-btn text @click="navigate('items_stock/warehouses')"
            >Warehouses</v-btn
          >
          <v-btn text @click="navigate('items_stock/items')">Items</v-btn>
          <v-btn text @click="navigate('items_stock/inventories')"
            >Inventories</v-btn
          >
          <v-btn
            text
            class="without-border-btn"
            @click="dialogActionCustomApply()"
            >Custom Apply</v-btn
          >
          <v-btn
            class="without-border-btn"
            @click="dialogActionTakenDamagedView('tki')"
            >Taken Items</v-btn
          >
          <v-btn
            class="without-border-btn"
            @click="dialogActionTakenDamagedView('dmi')"
            >Damaged Items</v-btn
          >
          <v-btn
            class="without-border-btn"
            @click="dialogQuantity = !dialogQuantity"
            >Managed Quantity</v-btn
          >
        </v-row>
      </div>
      <!-- <br /> -->
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :search="searchTxt"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :loading="isTableLoading"
        hide-default-footer
        sort-by="create_date"
        :sort-desc="true"
        class="elevation-1 mt-4 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.create_date="{ item }">
          <!-- <span>{{item.reg_date}}</span> -->
          <span>{{ dateTimeFormater(item.create_date) }}</span>
        </template>
        <template v-slot:item.last_update_date="{ item }">
          <!-- <span>{{item.reg_date}}</span> -->
          <span>{{ dateTimeFormater(item.last_update_date) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('dm', item)"
                    >
                      <v-icon>mdi-cart-remove</v-icon>
                    </v-btn>
                  </template>
                  <span>Damage</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="orange darken-4"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('tk', item)"
                    >
                      <v-icon>mdi-paper-roll-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Item Taking</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.item_type == 'PI'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="pink darken-4"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('am', item)"
                    >
                      <v-icon>mdi-numeric</v-icon>
                    </v-btn>
                  </template>
                  <span>Manage Quantity</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="indigo darken-2"
                      class="ma-1"
                      @click="dialogAction('rti', item)"
                    >
                      <v-icon>mdi-clipboard-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Return Item</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-container>
    <!--  -->
    <!-- Make Damage/Take Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container v-if="dialogType != 'am'">
            <v-row justify="start" class="flex-row">
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="taken_damaged_ret_qty"
                  :label="
                    dialogType == 'dm'
                      ? 'Damaged Qty'
                      : dialogType == 'rti'
                      ? 'Item Return'
                      : 'Taken Qty'
                  "
                  outlined
                  clearable
                  @input="qtyTextChange()"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  v-model="taken_dmage_retDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="taken_dmage_retDate"
                      label="Date"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="taken_dmage_retDate"
                    @input="taken_dmage_retDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                v-if="dialogType == 'tk' || dialogType == 'rti'"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <span
                  ><b>{{
                    dialogType == "tk" ? "Taking Type" : "Constituent Type"
                  }}</b></span
                >
                <v-switch
                  inset
                  class="mt-1"
                  :label="
                    dialogType == 'tk'
                      ? isProOrCus
                        ? 'Production'
                        : 'Personal Reason'
                      : dialogType == 'rti' && isProOrCus
                      ? 'Customer'
                      : 'Supplier'
                  "
                  v-model="isProOrCus"
                  dense
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="dialogType == 'am'">
            <v-switch
              inset
              class="ma-0"
              :label="functionTypeDecrement ? 'Decrement' : 'Increment'"
              v-model="functionTypeDecrement"
              dense
            ></v-switch>
            <v-col cols="12" sm="6" md="6" lg="6" class="ml-0 pl-0">
              <v-text-field
                dense
                v-model="increDecre_qty"
                :label="
                  functionTypeDecrement ? 'Decrement Qty' : 'Increment Qty'
                "
                outlined
                clearable
                required
              ></v-text-field>
            </v-col>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            dark
            :disabled="btnLoading"
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'dm'
                ? damageItem()
                : dialogType == 'tk'
                ? itemTaking()
                : dialogType == 'am'
                ? manageQuantity()
                : dialogType == 'rti'
                ? returnItem()
                : deleteData(editItem.stock_id)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Damaged/Taken View Dialog -->
    <!--  -->
    <v-dialog
      v-model="dialogView"
      :persistent="btnLoading"
      scrollable
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitleView }}</span>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6" lg="4" sm="12" class="pa-0 ma-0">
            <search-txt-compo :searchData="getSearchTDData"></search-txt-compo>
          </v-col>
        </v-card-title>

        <v-card-text>
          <!-- Damaged/Taken Table -->
          <v-container fluid>
            <v-data-table
              :headers="takenDamHeaders"
              :items="dialogTypeView == 'tki' ? takenBodyData : damagedBodyData"
              :loading="isTableLoadingtakenDam"
              :search="searchTxtTakenDam"
              :page.sync="takenDamPage"
              :items-per-page="itemsPerPageTakenDam"
              hide-default-footer
              class="elevation-1 data-tbl"
              @page-count="takenDamPageCount = $event"
            >
              <template v-slot:item.create_date="{ item }">
                <span>{{ dateTimeFormater(item.create_date) }}</span>
              </template>
              <template v-slot:item.damaged_date="{ item }">
                <span>{{ dateTimeFormater(item.damaged_date) }}</span>
              </template>
              <template v-slot:item.taken_date="{ item }">
                <span>{{ dateTimeFormater(item.taken_date) }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu left origin="center" transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon dark v-bind="attrs" v-on="on">
                      <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-container class="background">
                    <v-row class="pa-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            dark
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="red darken-4"
                            class="ma-1"
                            @click="dialogActionTDMCrud('d', item)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-row>
                  </v-container>
                </v-menu>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <div class="text-center pt-2">
          <v-pagination
            v-model="takenDamPage"
            :length="takenDamPageCount"
          ></v-pagination>
        </div>
        <br />
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Manage Qty View Dialog-->
    <!--  -->
    <v-dialog
      v-model="dialogQuantity"
      :persistent="btnLoading"
      scrollable
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Managed Items Quantity</span>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6" lg="4" sm="12" class="pa-0 ma-0">
            <search-txt-compo
              :searchData="getSearchManageQtyData"
            ></search-txt-compo>
          </v-col>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-data-table
              :headers="manageQtyHeaders"
              :items="manageQtyBodyData"
              :loading="isTableLoadingManageQty"
              :search="searchManageQtyData"
              :page.sync="manageQtyPage"
              :items-per-page="itemsPerPageManageQty"
              hide-default-footer
              class="elevation-1 data-tbl"
              @page-count="manageQtyPageCount = $event"
            >
              <template v-slot:item.create_date="{ item }">
                <span>{{ dateTimeFormater(item.create_date) }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-menu left origin="center" transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon dark v-bind="attrs" v-on="on">
                      <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-container class="background">
                    <v-row class="pa-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            dark
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            color="red darken-4"
                            class="ma-1"
                            @click="dialogActionTDMCrud('amd', item)"
                          >
                            <!-- Taken, Damage, Manage Qty Delete Dialog -->
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-row>
                  </v-container>
                </v-menu>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <div class="text-center pt-2">
          <v-pagination
            v-model="manageQtyPage"
            :length="manageQtyPageCount"
          ></v-pagination>
        </div>
        <br />
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Update, Delete Damage/Take Dialog -->
    <!--  -->
    <v-dialog v-model="dialogCrud" scrollable persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialogCrud = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            :loading="btnLoading"
            :disabled="btnLoading"
            outlined
            @click="
              dialogType == 'd' ? deleteTakenDamaged() : deleteManageQty()
            "
          >
            Yes
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Custom Apply Dialog -->
    <!--  -->
    <v-dialog v-model="dialogCustomApply" scrollable persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editCustomApply.item_code"
                  label="Item Code"
                  :items="itemsCodes"
                  :loading="loadingIDsCodesData"
                  hide-selected
                  outlined
                  clearable
                  required
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editCustomApply.br_id"
                  label="Branch ID"
                  :items="BranchIDs"
                  hide-selected
                  :loading="loadingIDsCodesData"
                  outlined
                  clearable
                  required
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editCustomApply.wh_id"
                  label="Warehouse ID"
                  :items="WhIDs"
                  :loading="loadingIDsCodesData"
                  hide-selected
                  outlined
                  clearable
                  required
                >
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialogCustomApply = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            :loading="btnLoading"
            :disabled="btnLoading"
            outlined
            @click="customApply()"
          >
            Save
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

var itemsStockRef;
var damagedRef;
var takenRef;
var itemsRef;
var itemsInvRef;
var warehousesRef;
var branchesRef;
var manageQtyRef;

export default {
  name: "items_stock_screen",
  mounted() {
    itemsStockRef = this.$fireStore.collection("itemsStock");
    damagedRef = this.$fireStore.collectionGroup("damaged");
    takenRef = this.$fireStore.collectionGroup("taken");
    manageQtyRef = this.$fireStore.collectionGroup("manage_qty");
    itemsRef = this.$fireStore.collection("RP_Items");
    itemsInvRef = this.$fireStore.collection("AM_Items");
    warehousesRef = this.$fireStore.collection("warehouses");
    branchesRef = this.$fireStore.collection("branches");
    this.initData();
    this.initDataTakenDamged();
    this.initDataManageQty();
    this.initDataIDsCodes();
  },
  data() {
    return {
      // Crud Stock
      dialog: false,
      dialogView: false,
      dialogTitle: null,
      dialogType: null,
      isProOrCus: true,
      // Taken/Damage
      dialogCrud: false,
      dialogTitleView: null,
      dialogTypeView: null,
      // Custom Aply
      editCustomApply: {},
      dialogCustomApply: false,
      loadingIDsCodesData: false,
      itemsCodes: [],
      items: [],
      BranchIDs: [],
      WhIDs: [],
      // Common
      editItem: {},
      //
      taken_damaged_ret_qty: 0,
      // taken_damaged_amount: 0,
      // damageReturn: false,
      taken_dmage_retDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      taken_dmage_retDateMenu: false,
      // Buttons
      btnLoading: false,
      // Filter
      typeFilter: null,
      itemCodeFilter: null,
      branchIDFilter: null,
      whIDFilter: null,
      // takingTypeFilter: "",
      //   Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      headers: [
        {
          text: "Item Code",
          align: "start",
          sortable: false,
          value: "item_code",
        },
        {
          text: "Item Name",
          align: "start",
          //   sortable: false,
          value: "item_name",
        },
        { text: "Type", value: "item_type" },
        { text: "Available Qty", value: "available_qty" },
        { text: "Damage Qty", value: "damaged_qty" },
        // { text: "T.Pro Qty", value: "taking_production_qty" },
        // { text: "T.Per Qty", value: "taking_personal_qty" },
        { text: "Unit", value: "measuar_unit", sortable: false },
        { text: "Product Id", value: "pro_id" },
        { text: "Warehouse Id", value: "wh_id" },
        { text: "Branch Show-Room ID", value: "br_id" },
        { text: "Cost Price (Def)", value: "def_cost_price" },
        { text: "Sale Price (Def)", value: "def_sale_price" },

        { text: "Create Date", width: 130, value: "create_date" },
        { text: "L.U Date", width: 130, value: "last_update_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
      // Taken Damaged Table
      isTableLoadingtakenDam: false,
      takenDamHeaders: [
        {
          text: "Item Code",
          align: "start",
          sortable: false,
          value: "item_code",
          width: 180,
        },
        {
          text: "Item Name",
          align: "start",
          //   sortable: false,
          value: "item_name",
          width: 180,
        },
        { text: "Type", value: "item_type" },
        { text: "Price x 1", value: "def_cost_price", width: 100 },
        { text: "Create Date", width: 130, value: "create_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      takenBodyData: [],
      damagedBodyData: [],
      searchTxtTakenDam: null,
      takenDamPage: 1,
      itemsPerPageTakenDam: 10,
      takenDamPageCount: 0,
      // Manage Quantity Crud
      functionTypeDecrement: false,
      increDecre_qty: null,
      // Manage Quantity View
      dialogQuantity: false,
      searchManageQtyData: null,
      manageQtyHeaders: [
        {
          text: "Item Code",
          align: "start",
          sortable: false,
          value: "item_code",
          width: 100,
        },
        {
          text: "Item Name",
          align: "start",
          //   sortable: false,
          value: "item_name",
          width: 100,
        },
        { text: "Type", value: "item_type" },
        { text: "Branch ID", value: "br_id" },
        { text: "Warehouse ID", value: "wh_id" },
        { text: "Decrement Qty", value: "decrement_qty" },
        { text: "Increment Qty", value: "increment_qty" },
        { text: "Create Date", width: 130, value: "create_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      manageQtyBodyData: [],
      isTableLoadingManageQty: false,
      manageQtyPage: 1,
      manageQtyPageCount: 0,
      itemsPerPageManageQty: 10,
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await itemsStockRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            snapshots.docs.forEach((element) => {
              this.BodyData.push(element.data());
              this.BodyDataBack.push(element.data());
            });
            this.isTableLoading = false;
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async initDataTakenDamged() {
      try {
        this.isTableLoadingtakenDam = true;
        await damagedRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.damagedBodyData = [];
            snapshots.docs.forEach((element) => {
              this.damagedBodyData.push(element.data());
            });
          }
        );
        await takenRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.takenBodyData = [];
            snapshots.docs.forEach((element) => {
              this.takenBodyData.push(element.data());
            });
          }
        );
      } catch (error) {
        this.isTableLoadingtakenDam = false;
        console.log(error);
      } finally {
        this.isTableLoadingtakenDam = false;
      }
    },
    async initDataManageQty() {
      try {
        this.isTableLoadingManageQty = true;
        await manageQtyRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.manageQtyBodyData = [];
            snapshots.docs.forEach((element) => {
              this.manageQtyBodyData.push(element.data());
            });
            this.isTableLoadingManageQty = false;
          }
        );
      } catch (error) {
        this.isTableLoadingManageQty = false;
        console.log(error);
      }
    },
    async initDataIDsCodes() {
      try {
        this.loadingIDsCodesData = true;
        this.items = [];
        this.itemsCodes = [];
        this.BranchIDs = [];
        this.WhIDs = [];
        // Row and Production Items Codes Loading
        await itemsRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            snapshots.docs.forEach((element) => {
              this.items.push(element.data());
              this.itemsCodes.push(element.data()["item_code"]);
            });
          }
        );
        // Accessories and Materials Items Codes Loading
        await itemsInvRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            snapshots.docs.forEach((element) => {
              this.items.push(element.data());
              this.itemsCodes.push(element.data()["item_code"]);
            });
          }
        );
        // Branches IDs Loading
        await branchesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            snapshots.docs.forEach((element) => {
              this.BranchIDs.push(element.data()["br_id"]);
            });
          }
        );
        // Warehouse IDs Loading
        await warehousesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            snapshots.docs.forEach((element) => {
              this.WhIDs.push(element.data()["wh_id"]);
            });
          }
        );
      } catch (error) {
        this.loadingIDsCodesData = false;
        console.log(error);
      } finally {
        this.loadingIDsCodesData = false;
      }
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    getSearchTDData(value) {
      this.searchTxtTakenDam = value;
    },
    getSearchManageQtyData(value) {
      this.searchManageQtyData = value;
    },
    navigate(path) {
      try {
        this.$router.push(path).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    dialogAction(type, item) {
      this.editItem = {};
      if (type == "dm") {
        this.dialogTitle = "Item Damage";
        this.dialogType = "dm";
        this.editItem = Object.assign({}, item);
      } else if (type == "delete") {
        this.editItem.stock_id = item.stock_id;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else if (type == "tk") {
        this.dialogTitle = "Item Take";
        this.dialogType = "tk";
        this.editItem = Object.assign({}, item);
      } else if (type == "rti") {
        this.dialogTitle = "Item Return";
        this.dialogType = "rti";
        this.editItem = Object.assign({}, item);
      } else {
        this.dialogTitle = "Manage Quantity - " + item.item_code;
        this.dialogType = "am";
        this.editItem = Object.assign({}, item);
      }
      this.dialog = !this.dialog;
    },
    dialogActionCustomApply() {
      this.editCustomApply = {};
      this.dialogTitle = "Apply Items to the Stock";
      this.dialogCustomApply = !this.dialogCustomApply;
    },
    dialogActionTakenDamagedView(type) {
      // Manage Table column
      //
      //
      //
      // Find index of header
      const indexQTY = this.takenDamHeaders.findIndex((item) => {
        return item.value === "taken_qty" || item.value === "damaged_qty";
      });
      // Remove
      if (indexQTY >= 0) this.takenDamHeaders.splice(indexQTY, 1);
      //
      //
      //
      const indexDate = this.takenDamHeaders.findIndex((item) => {
        return item.value === "taken_date" || item.value === "damaged_date";
      });
      // Remove
      if (indexDate >= 0) this.takenDamHeaders.splice(indexDate, 1);
      //
      //
      //
      // Find index of header
      const indexType = this.takenDamHeaders.findIndex((item) => {
        return item.value === "taken_type";
      });
      // Remove
      if (indexType >= 0) this.takenDamHeaders.splice(indexType, 1);
      //
      if (type == "dmi") {
        // Add
        this.takenDamHeaders.splice(3, 0, {
          text: "Damaged Qty",
          value: "damaged_qty",
          width: 100,
        });
        // Add
        this.takenDamHeaders.splice(5, 0, {
          text: "Damaged Date",
          value: "damaged_date",
          width: 130,
        });
        //
        this.dialogTitleView = "List of Damaged Items";
        this.dialogTypeView = "dmi";
      } else {
        // Add
        this.takenDamHeaders.splice(3, 0, {
          text: "Taken Qty",
          value: "taken_qty",
          width: 100,
        });
        // Add
        this.takenDamHeaders.splice(5, 0, {
          text: "Taken Type",
          value: "taken_type",
        });
        // Add
        this.takenDamHeaders.splice(6, 0, {
          text: "Taken Date",
          value: "taken_date",
          width: 130,
        });
        //
        this.dialogTitleView = "List of Taken Items";
        this.dialogTypeView = "tki";
      }
      this.dialogView = !this.dialogView;
    },
    //Taken, Damaged, and Manage Qty
    dialogActionTDMCrud(type, item) {
      if (type == "d") {
        this.dialogType = "d";
      } else if (type == "amd") {
        this.dialogType = "amd";
      }
      this.editItem = Object.assign({}, item);
      this.dialogTitle = "Do you want to delete this data?";
      this.dialogCrud = !this.dialogCrud;
    },

    async customApply() {
      // var dataAdded = false;
      try {
        if (
          this.editCustomApply.item_code == null ||
          this.editCustomApply.item_code == ""
        ) {
          this.$store.commit("alertMessage/message", [
            "Please select Item Code.",
            "error",
          ]);
        } else if (
          (this.editCustomApply.br_id == null ||
            this.editCustomApply.br_id == "") &&
          (this.editCustomApply.wh_id == null ||
            this.editCustomApply.wh_id == "")
        ) {
          this.$store.commit("alertMessage/message", [
            "Please select Branch ID or Warehose ID.",
            "error",
          ]);
        } else {
          this.btnLoading = true;

          var selectedItemData = this.items.find((value) => {
            return value.item_code == this.editCustomApply.item_code;
          });
          var dataMap = {
            ...selectedItemData,
            available_qty: 0,
            damaged_qty: 0,
            create_date: new Date(),
            last_update_date: null,
          };
          // Item Apply with Branch ID
          if (
            this.editCustomApply.br_id != null &&
            this.editCustomApply.br_id != ""
          ) {
            await itemsStockRef
              .where("item_code", "==", this.editCustomApply.item_code)
              .where("br_id", "==", this.editCustomApply.br_id)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.docs?.length == 0) {
                  const stockID = uuid();
                  itemsStockRef.doc(stockID).set({
                    ...dataMap,
                    stock_id: stockID,
                    br_id: this.editCustomApply.br_id,
                  });
                } else {
                  this.$store.commit("alertMessage/message", [
                    `The Item data already exist in the Stock.${
                      this.editCustomApply.item_code +
                      " -> " +
                      this.editCustomApply.br_id
                    }`,
                    "error",
                  ]);
                }
              })
              .catch((error) => {
                this.btnLoading = false;
                console.log(error);
              });
          }
          // Item Apply with Warehose ID
          if (
            this.editCustomApply.wh_id != null &&
            this.editCustomApply.wh_id != ""
          ) {
            await itemsStockRef
              .where("item_code", "==", this.editCustomApply.item_code)
              .where("wh_id", "==", this.editCustomApply.wh_id)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.docs?.length == 0) {
                  const stockID = uuid();
                  itemsStockRef.doc(stockID).set({
                    ...dataMap,
                    stock_id: stockID,
                    wh_id: this.editCustomApply.wh_id,
                  });
                } else {
                  this.$store.commit("alertMessage/message", [
                    `The Item data already exist in the Stock.${
                      this.editCustomApply.item_code +
                      " -> " +
                      this.editCustomApply.wh_id
                    }`,
                    "error",
                  ]);
                }
              })
              .catch((error) => {
                this.btnLoading = false;
                console.log(error);
              });
          }
        }
      } catch (error) {
        this.btnLoading = false;
        this.editCustomApply = {};
        this.dialogCustomApply = false;
        console.log(error);
      } finally {
        this.btnLoading = false;
        this.editCustomApply = {};
        // if (dataAdded == true)
        //   this.$store.commit("alertMessage/message", [
        //     "Data added successfully.",
        //     "success",
        //   ]);
      }
    },
    async deleteTakenDamaged() {
      try {
        this.btnLoading = true;
        await itemsStockRef
          .doc(this.editItem.stock_id)
          .update({
            available_qty: this.$firebase.firestore.FieldValue.increment(
              this.dialogTypeView == "tki"
                ? this.editItem.taken_qty
                : this.editItem.damaged_qty
            ),
            damaged_qty: this.$firebase.firestore.FieldValue.increment(
              this.dialogTypeView == "dmi" ? -this.editItem.damaged_qty : 0
            ),
            last_update_date: new Date(),
          })
          .then(async () => {
            await itemsStockRef
              .doc(this.editItem.stock_id)
              .collection(this.dialogTypeView == "tki" ? "taken" : "damaged")
              .doc(
                this.dialogTypeView == "tki"
                  ? this.editItem.taken_id
                  : this.editItem.damaged_id
              )
              .delete();
          })
          .then(() => {
            this.btnLoading = false;
            this.dialogCrud = false;
            this.$store.commit("alertMessage/message", [
              "Data deleted successfully.",
              "success",
            ]);
          })
          .catch((error) => {
            this.btnLoading = false;
            this.dialogCrud = false;
            this.$store.commit("alertMessage/message", [error, "error"]);
          });
      } catch (error) {
        this.btnLoading = false;
        this.dialogCrud = false;
        console.log(error);
      }
    },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    qtyTextChange() {
      try {
        // this.taken_damaged_amount = 0;
        if (this.taken_damaged_ret_qty == "") {
          this.taken_damaged_ret_qty = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // amountTextChange() {
    //   try {
    //     this.taken_damaged_ret_qty = 0;
    //     if (this.taken_damaged_amount == "") {
    //       this.taken_damaged_amount = 0;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async itemTaking() {
      try {
        //
        let newTaking = Number(this.taken_damaged_ret_qty ?? 0);
        if (newTaking > 0) {
          if (newTaking > Number(this.editItem.available_qty)) {
            this.taken_damaged_ret_qty = 0;
            // this.taken_damaged_amount = 0;
            this.$store.commit("alertMessage/message", [
              `Your can't update stock qty of this item. ${this.editItem.item_code} Item available qty is low.`,
              "error",
            ]);
          } else {
            this.btnLoading = true;
            var amountAvalailability =
              Number(this.editItem.available_qty) - Number(newTaking);
            // var amountOfTakingQty =
            //   Number(this.editItem.damaged_qty) + Number(newTaking);
            await itemsStockRef
              .doc(this.editItem.stock_id)
              .update({
                available_qty: Number(amountAvalailability.toFixed(3)),
                // damaged_qty: Number(amountOfTakingQty.toFixed(3)),
                last_update_date: new Date(),
              })
              .then(() => {
                const takenID = uuid();
                itemsStockRef
                  .doc(this.editItem.stock_id)
                  .collection("taken")
                  .doc(takenID)
                  .set({
                    taken_id: takenID,
                    stock_id: this.editItem.stock_id,
                    // item_id: this.editItem.item_id,
                    item_code: this.editItem.item_code,
                    item_name: this.editItem.item_name,
                    item_type: this.editItem.item_type,
                    taken_qty: Number(newTaking),
                    def_cost_price: Number(this.editItem.def_cost_price),
                    taken_type: this.isProOrCus
                      ? "Production"
                      : "Personal_Reason",
                    create_date: new Date(),
                    taken_date: new Date(this.taken_dmage_retDate),
                  })
                  .then(() => {
                    this.btnLoading = false;
                    this.taken_damaged_ret_qty = 0;
                    this.isProOrCus = true;
                    // this.taken_damaged_amount = 0;
                    this.dialog = false;
                    this.$store.commit("alertMessage/message", [
                      "Data updated successfully.",
                      "success",
                    ]);
                  })
                  .catch((e) => {
                    this.btnLoading = false;
                    console.log(e.message);
                  });
              })
              .catch((e) => {
                this.btnLoading = false;
                console.log(e.message);
              });
          }
        } else {
          this.btnLoading = false;
          this.taken_damaged_ret_qty = 0;
          // this.taken_damaged_amount = 0;
          this.$store.commit("alertMessage/message", [
            "Qty is invalid. Please enter valid Qty.",
            "error",
          ]);
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async damageItem() {
      try {
        //
        let newDamage = Number(this.taken_damaged_ret_qty ?? 0);
        if (newDamage > 0) {
          if (newDamage > Number(this.editItem.available_qty)) {
            this.taken_damaged_ret_qty = 0;
            // this.taken_damaged_amount = 0;
            this.$store.commit("alertMessage/message", [
              `Your can't update stock qty of this item. ${this.editItem.item_code} Item available qty is low.`,
              "error",
            ]);
          } else {
            this.btnLoading = true;
            var amountAvalailability =
              Number(this.editItem.available_qty) - Number(newDamage);
            var amountDamgeQty =
              Number(this.editItem?.damaged_qty) + Number(newDamage);
            await itemsStockRef
              .doc(this.editItem.stock_id)
              .update({
                available_qty: Number(amountAvalailability.toFixed(3)),
                damaged_qty: Number(amountDamgeQty.toFixed(3)),
                last_update_date: new Date(),
              })
              .then(() => {
                const damagedID = uuid();
                itemsStockRef
                  .doc(this.editItem.stock_id)
                  .collection("damaged")
                  .doc(damagedID)
                  .set({
                    damaged_id: damagedID,
                    stock_id: this.editItem.stock_id,
                    // item_id: this.editItem.item_id,
                    item_code: this.editItem.item_code,
                    item_name: this.editItem.item_name,
                    item_type: this.editItem.item_type,
                    damaged_qty: Number(newDamage),
                    def_cost_price: Number(this.editItem.def_cost_price),
                    create_date: new Date(),
                    damaged_date: new Date(this.taken_dmage_retDate),
                  })
                  .then(() => {
                    this.btnLoading = false;
                    this.taken_damaged_ret_qty = 0;
                    // this.taken_damaged_amount = 0;
                    this.dialog = false;
                    this.$store.commit("alertMessage/message", [
                      "Data updated successfully.",
                      "success",
                    ]);
                  })
                  .catch((e) => {
                    this.btnLoading = false;
                    console.log(e.message);
                  });
              })
              .catch((e) => {
                this.btnLoading = false;
                console.log(e.message);
              });
          }
        } else {
          this.btnLoading = false;
          this.taken_damaged_ret_qty = 0;
          // this.taken_damaged_amount = 0;
          this.$store.commit("alertMessage/message", [
            "Qty is invalid. Please enter valid Qty.",
            "error",
          ]);
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async returnItem() {
      try {
        //
        let newReturning = Number(this.taken_damaged_ret_qty ?? 0);
        if (newReturning > 0) {
          if (
            !this.isProOrCus &&
            newReturning > Number(this.editItem.available_qty)
          ) {
            this.taken_damaged_ret_qty = 0;
            // this.taken_damaged_amount = 0;
            this.$store.commit("alertMessage/message", [
              `Your can't update stock qty of this item. ${this.editItem.item_code} Item available qty is low.`,
              "error",
            ]);
          } else {
            this.btnLoading = true;

            await itemsStockRef
              .doc(this.editItem.stock_id)
              .update({
                available_qty: this.$firebase.firestore.FieldValue.increment(
                  this.isProOrCus ? newReturning : -newReturning
                ),
                // damaged_qty: Number(amountOfTakingQty.toFixed(3)),
                last_update_date: new Date(),
              })
              .then(() => {
                const returnID = uuid();
                itemsStockRef
                  .doc(this.editItem.stock_id)
                  .collection("returns")
                  .doc(returnID)
                  .set({
                    return_id: returnID,
                    stock_id: this.editItem.stock_id,
                    // item_id: this.editItem.item_id,
                    item_code: this.editItem.item_code,
                    item_name: this.editItem.item_name,
                    item_type: this.editItem.item_type,
                    return_qty: newReturning,
                    def_cost_price: Number(this.editItem.def_cost_price),
                    return_type: this.isProOrCus ? "CUS_RET" : "SUP_RET",
                    create_date: new Date(),
                    return_date: new Date(this.taken_dmage_retDate),
                  })
                  .then(() => {
                    this.btnLoading = false;
                    this.taken_damaged_ret_qty = 0;
                    this.isProOrCus = true;
                    // this.taken_damaged_amount = 0;
                    this.dialog = false;
                    this.$store.commit("alertMessage/message", [
                      "Data updated successfully.",
                      "success",
                    ]);
                  })
                  .catch((e) => {
                    this.btnLoading = false;
                    console.log(e.message);
                  });
              })
              .catch((e) => {
                this.btnLoading = false;
                console.log(e.message);
              });
          }
        } else {
          this.btnLoading = false;
          this.taken_damaged_ret_qty = 0;
          // this.taken_damaged_amount = 0;
          this.$store.commit("alertMessage/message", [
            "Qty is invalid. Please enter valid Qty.",
            "error",
          ]);
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Stock ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          // Declare Batch
          var batch = this.$batch.batch();
          await itemsStockRef
            .doc(id)
            .get()
            .then(async (snapshot) => {
              // Remove all of the taken items
              await snapshot?.ref
                .collection("taken")
                .get()
                .then(async (querySnapshot) => {
                  await querySnapshot?.docs?.forEach((element) => {
                    batch.delete(element?.ref);
                  });
                })
                .then(async () => {
                  // Remove all of the damaged items
                  await snapshot?.ref
                    .collection("damaged")
                    .get()
                    .then(async (querySnapshot) => {
                      await querySnapshot?.docs?.forEach((element) => {
                        batch.delete(element?.ref);
                      });
                    })
                    .then(() => {
                      batch.delete(snapshot?.ref);
                    });
                });
            })
            .then(() => {
              batch.commit();
              this.btnLoading = false;
              this.dialog = false;
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async manageQuantity() {
      try {
        if (this.increDecre_qty == null || this.increDecre_qty == "") {
          this.$store.commit("alertMessage/message", [
            "Please enter Qty for process.",
            "error",
          ]);
        } else if (
          this.functionTypeDecrement &&
          Number(this.editItem.available_qty) < Number(this.increDecre_qty ?? 0)
        ) {
          this.$store.commit("alertMessage/message", [
            "No quantity of an item for decrement.",
            "error",
          ]);
        } else if (Number(this.increDecre_qty) <= 0) {
          this.$store.commit("alertMessage/message", [
            "Entered Qty is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          itemsStockRef
            .doc(this.editItem.stock_id)
            .update({
              available_qty: this.$firebase.firestore.FieldValue.increment(
                this.functionTypeDecrement
                  ? -Number(this.increDecre_qty)
                  : Number(this.increDecre_qty)
              ),
              last_update_date: new Date(),
            })
            .then(() => {
              var manageID = uuid();
              itemsStockRef
                .doc(this.editItem.stock_id)
                .collection("manage_qty")
                .doc(manageID)
                .set({
                  manage_id: manageID,
                  stock_id: this.editItem.stock_id,
                  item_code: this.editItem.item_code,
                  item_name: this.editItem.item_name,
                  item_type: this.editItem.item_type,
                  br_id: this.editItem.br_id ?? null,
                  wh_id: this.editItem.wh_id ?? null,
                  decrement_qty: this.functionTypeDecrement
                    ? Number(this.increDecre_qty)
                    : null,
                  increment_qty: !this.functionTypeDecrement
                    ? Number(this.increDecre_qty)
                    : null,
                  create_date: new Date(),
                })
                .then(() => {
                  this.btnLoading = false;
                  this.increDecre_qty = null;
                  this.functionTypeDecrement = false;
                  this.dialog = false;
                  this.$store.commit("alertMessage/message", [
                    "Data updated successfully.",
                    "success",
                  ]);
                });
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    deleteManageQty() {
      try {
        if (this.editItem.manage_id == null || this.editItem.manage_id == "") {
          this.$store.commit("alertMessage/message", [
            "Manage Qty ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          itemsStockRef
            .doc(this.editItem.stock_id)
            .update({
              available_qty: this.$firebase.firestore.FieldValue.increment(
                Number(this.editItem.decrement_qty) > 0
                  ? Number(this.editItem.decrement_qty)
                  : -Number(this.editItem.increment_qty)
              ),
              last_update_date: new Date(),
            })
            .then(() => {
              itemsStockRef
                .doc(this.editItem.stock_id)
                .collection("manage_qty")
                .doc(this.editItem.manage_id)
                .delete()
                .then(() => {
                  this.btnLoading = false;
                  this.dialogCrud = false;
                  this.$store.commit("alertMessage/message", [
                    "Data deleted successfully.",
                    "success",
                  ]);
                });
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Filter
    filterData() {
      try {
        // Filter unit 01
        var tempList = this.BodyDataBack;
        if (this.typeFilter == "RI") {
          tempList = tempList.filter((value) => value.item_type == "RI");
        } else if (this.typeFilter == "PI") {
          tempList = tempList.filter((value) => value.item_type == "PI");
        } else if (this.typeFilter == "AI") {
          tempList = tempList.filter((value) => value.item_type == "AI");
        } else if (this.typeFilter == "MI") {
          tempList = tempList.filter((value) => value.item_type == "MI");
        }
        // Filter unit 02
        if (this.itemCodeFilter != null && this.itemCodeFilter != "") {
          tempList = tempList.filter(
            (value) => value.item_code == this.itemCodeFilter
          );
        }
        // Filter unit 03
        if (this.branchIDFilter != null && this.branchIDFilter != "") {
          tempList = tempList.filter(
            (value) => value.br_id == this.branchIDFilter
          );
        }
        // Filter unit 04
        if (this.whIDFilter != null && this.whIDFilter != "") {
          tempList = tempList.filter((value) => value.wh_id == this.whIDFilter);
        }

        this.BodyData = tempList;
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.isSupplier = false;
        this.isFireDate = false;
        this.withDateRange = false;
        this.pickDateRange = [];
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/theme_styles.scss";

.st-container {
  & .row {
    flex-wrap: wrap;
    gap: 10px;
    & button {
      @extend .button-border;
      &.without-border-btn {
        background-color: rgb(0, 123, 128) !important;
        color: white !important;
        border: none;
      }
    }
  }
}
.flex-row {
  align-items: center;
}
</style>