<template>
  <v-card class="about-card ma-0">
    <v-card-text>
      <div class="img">
        <img src="@/assets/development logo.webp" />
      </div>
      <v-col cols="12" class="ma-0 pa-0">
        <h2 class="black--text">Apec Lanka</h2>
        <p class="fmedium mt-1">Development Team</p>
        <p>We are providing Software Solutions for your Smart Future.</p>
        <br />
        <v-row class="ml-1 pa-0 pt-1">
          <v-row class="ma-0 pa-0 mr-5 mb-2" align-content="center">
            <v-icon size="22">mdi-phone</v-icon>
            <span class="fmedium pl-1">+94706 193 294</span>
          </v-row>
          <v-row class="ma-0 pa-0" align-content="center">
            <v-icon size="22">mdi-email</v-icon>
            <span class="fmedium pl-1">apeclanka.dev@gmail.com</span>
          </v-row>
        </v-row>
        <br />
      </v-col>
      <span></span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "aboutCard_compo",
};
</script>

<style lang="scss">
.about-card {
  & .img {
    width: 100%;
    display: flex;
    justify-content: center;
    & img {
      padding: 0px;
      margin: 0px;
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }
}
</style>
