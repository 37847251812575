<template>
  <v-card-text :class="styleClass"
    ><v-row class="ma-0 pa-0" justify="space-between">
      <span>{{ title }}</span>
      <span>Rs.{{ value.toLocaleString() }}</span></v-row
    ></v-card-text
  >
</template>

<script>
export default {
  name: "report_card_body_compo",
  props: ["styleClass", "title", "value"],
};
</script>