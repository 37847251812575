<template>
  <v-app class="light">
    <v-main>
      <!-- <transition name="fade"> -->
      <router-view />
      <!-- </transition> -->
    </v-main>
    <!-- Message -->
    <v-snackbar
      v-model="isAlertShow"
      top
      shaped
      content-class="snack_content"
      centered
      :color="
        alertType == 'error'
          ? 'red darken-4'
          : alertType == 'warning'
          ? 'orange darken-4'
          : 'green darken-4'
      "
    >
      {{ message }}
    </v-snackbar>
    <!-- <v-alert
      transition="scale-transition"
      dense
      class="fmedium"
      :value="isAlertShow"
      :type="alertType"
      >{{ message }}</v-alert
    > -->
  </v-app>
</template>

<script>
var constituentsRef;

export default {
  name: "App",
  data: () => ({}),
  mounted() {
    constituentsRef = this.$fireStore.collection("constituents");
    this.checkAuth();
    // this.createDefConst();
  },
  computed: {
    isAlertShow() {
      return this.$store.getters["alertMessage/isAlertShow"];
    },
    alertType() {
      return this.$store.getters["alertMessage/alert_type"];
    },
    message() {
      return this.$store.getters["alertMessage/msg"];
    },
    // isAuth() {
    //   return this.$store.getters["systemUser/isAuth"];
    // },
  },
  methods: {
    async checkAuth() {
      try {
        await this.$auth.onAuthStateChanged(async (user) => {
          if (user) {
            // this.$store.commit("systemUser/authCheck", true);
            if (this.$store.getters["systemUser/userData"]?.isAuth ?? false)
              this.$router.replace(this.$router.currentRoute).catch(() => {});
          } else {
            this.$router.replace("/signIn").catch(() => {});
            // this.$store.commit("systemUser/authCheck", false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async createDefConst() {
      try {
        await constituentsRef
          .get()
          .then((snapshots) => {
            if (snapshots.docs.length == 0) {
              constituentsRef.doc("Def").set({
                cons_id: "Def",
                cons_name: "Def Customer",
                cons_type: "CUS",
                acc_status: "Active",
                reg_date: new Date(),
              });
            }
          })
          .then(() => {
            this.$store.commit("alertMessage/message", [
              "Default Customer created successfully.",
              "success",
            ]);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s none;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
//   // transform: translateX(-30%);
// }
.snack_content {
  text-align: center !important;
}
</style>
