export default {
  namespaced: true,
  state: {
    userMP: false,
    userData: null,
  },
  getters: {
    userMP: (state) => {
      return state.userMP;
    },
    userData: (state) => {
      return state.userData;
    },
  },
  mutations: {
    // async authCheck(state, isAuth) {
    //   state.isAuth = isAuth;
    // },
    findUserData(state) {
      state.userData = JSON.parse(localStorage.getItem("systemuser"));
      state.userMP = JSON.parse(localStorage.getItem("userMP"));
    },
  },
  actions: {},
  modules: {},
};
