<template>
  <div>
    <app-bar-compo title="Home" :filter="false">
      <template v-slot:actions>
        <v-btn icon @click="signOut()"
          ><v-icon class="appBar-icon">mdi-logout</v-icon></v-btn
        >
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-menu
          bottom
          origin="center center"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  inset
                  color="indigo darken-4"
                  class="ma-0"
                  @click="clearWithSwitch()"
                  :label="
                    isSupplierInvo ? 'Supplier Invoice' : 'Customer Invoice'
                  "
                  v-model="isSupplierInvo"
                  :loading="switchLoading"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <div></div>
      </template>
    </app-bar-compo>
    <!-- Body -->
    <v-container fluid>
      <v-row class="ma-1 mt-3">
        <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="pa-0 pl-0">
          <!-- Item code, qty and amount section -->
          <v-row class="ma-0" justify="start" align-content="center">
            <v-col cols="6" class="pa-0 ma-0">
              <v-autocomplete
                :items="items"
                v-model="selectedStockID"
                :loading="loadingItemsData"
                append-icon="mdi-magnify"
                label="Item Code"
                solo-inverted
                ref="item_code_ref"
                hide-selected
                auto-select-first
                item-text="item_code"
                item-value="stock_id"
                dense
                class="icon-color"
                clearable
                @keyup.enter.native="itemCodeKeyDownEnter"
              >
                <template v-slot:selection="data">
                  <span> {{ data.item.item_code }}</span>
                </template>
                <!-- List Item -->
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ data.item.item_code
                      }}<span
                        class="text-subtitle"
                        style="font-weight: normal"
                        >{{
                          " - " +
                          (data.item.wh_id != null
                            ? data.item.wh_id
                            : data.item.br_id)
                        }}</span
                      ></v-list-item-title
                    >
                    <v-row justify="space-between" dense class="warp-off ma-0">
                      <span class="fsmall text-subtitle">{{
                        data.item.item_name
                      }}</span>
                      <span class="fsmall text-subtitle">{{
                        "Rs." + data.item.def_sale_price
                      }}</span>
                    </v-row>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="3" class="pa-0 pl-3 ma-0">
              <v-text-field
                v-model="qty"
                append-icon="mdi-fruit-grapes-outline"
                label="Qty"
                ref="qty_ref"
                solo-inverted
                dense
                @keydown.enter="itemSetToBill()"
                @keydown.native="qtyKeyDown"
                class="icon-color"
              ></v-text-field>
            </v-col>
            <!-- <span class="mt-2 pl-2 or-text">OR</span>
            <v-col cols="2.5" class="pa-0 pl-2 ma-0">
              <v-text-field
                v-model="qtyAmount"
                append-icon="mdi-cash-multiple"
                label="Amount"
                solo-inverted
                class="icon-color"
                dense
              ></v-text-field>
            </v-col> -->
          </v-row>
          <br />
          <!-- Item details section -->
          <v-col cols="12" class="pa-0 ma-0">
            <v-data-table
              :headers="headers"
              :items="BodyData"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              class="data-tbl row-height"
              height="500"
              dense
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red lighten-1"
                      class="ma-0"
                      elevation="0"
                      @click="itemRemoveBill(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-col>
        </v-col>
        <div style="width: 30px"></div>
        <!-- Price Cash pay section -->
        <v-col class="pa-0 pr-4 ma-0">
          <v-col v-if="isSupplierInvo" class="pa-0 ma-0">
            <v-text-field
              v-model="supplierInvoNo"
              ref="sup_invo_ref"
              label="Invoice No"
              outlined
              dense
              class="ma-0 pa-0"
            ></v-text-field>
            <br />
          </v-col>

          <v-row justify="space-between" class="mt-2 mb-2">
            <h2 class="orange--text text--darken-4">Gross Total</h2>
            <h2>{{ grossAmount.toFixed(2) }}</h2>
          </v-row>
          <v-row justify="space-between" class="mt-2 mb-2 warp-off">
            <h2 class="green--text text--darken-4">Discount</h2>
            <v-row class="ma-0 pa-0">
              <v-col cols="3" sm="5" lg="3" class="ma-0 pa-0"></v-col>
              <v-checkbox
                v-model="discountUnitRs"
                label="Rs"
                dense
                @change="makeNetTotal()"
                class="ma-0 pa-0 pt-2 pr-2"
              ></v-checkbox>

              <v-col class="pa-0 ma-0">
                <v-text-field
                  v-model="discount"
                  append-icon="mdi-cash"
                  ref="discount_ref"
                  label="Discount"
                  solo
                  @input="makeNetTotal()"
                  @keydown.native="discountKeyDown"
                  dense
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>
          <v-row justify="space-between" class="mt-2 mb-2">
            <h2 class="red--text text--darken-4">Net Total</h2>
            <h2>{{ netAmount.toFixed(2) }}</h2>
          </v-row>
          <br />
          <!-- Cash/ Card -->
          <v-row class="mt-2 mb-2">
            <v-col class="pa-0 ma-0 pr-2">
              <v-text-field
                v-model="cashAmount"
                ref="cash_ref"
                append-icon="mdi-cash"
                label="Cash Amount"
                outlined
                dense
                @keydown.native="cashKeyDown"
                class="ma-0 pa-0"
              ></v-text-field>
            </v-col>
            <v-col class="pa-0 ma-0 pl-2">
              <v-text-field
                v-model="cardAmount"
                ref="card_ref"
                append-icon="mdi-credit-card"
                label="Card Amount"
                outlined
                dense
                @keydown.native="cardKeyDown"
                class="ma-0 pa-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Cheque -->
          <h4 class="mt-3 blue--text text--darken-4">Cheque Details</h4>
          <v-row class="mt-3 mb-2">
            <v-col class="pa-0 ma-0 pr-2">
              <v-text-field
                v-model="chequeAmount"
                ref="cheque_ref"
                @keydown="chequeKeyDown"
                append-icon="mdi-checkbook"
                label="Cheque Amount"
                outlined
                dense
                class="ma-0 pa-0"
              ></v-text-field>
            </v-col>
            <v-col class="pa-0 ma-0 pl-2">
              <v-text-field
                v-model="chequeNo"
                ref="cheque_no_ref"
                @keydown.native="cheNoKeyDown"
                append-icon="mdi-numeric"
                label="Cheque No"
                outlined
                dense
                class="ma-0 pa-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-2 pb-2">
            <v-col class="pa-0 ma-0 pr-2">
              <v-menu
                v-model="issueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="issueDate"
                    label="Issue Date"
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="issueDate"
                  @input="issueDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pa-0 ma-0 pl-2">
              <v-menu
                v-model="realiseDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                left
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="realiseDate"
                    label="Realise Date"
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="realiseDate"
                  @input="realiseDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Other -->
          <h4 class="mt-4 blue--text text--darken-4">Other Details</h4>
          <v-row class="mt-2 mb-2">
            <v-col class="pa-0 ma-0 pr-2">
              <v-autocomplete
                :items="constituentsIDs"
                v-model="constituentID"
                ref="cons_ref"
                @keydown.native="constituentKeyDown"
                append-icon="mdi-magnify"
                label="Constituent ID"
                outlined
                :loading="loadingDataCons"
                hide-selected
                dense
              ></v-autocomplete>
            </v-col>
            <v-col class="pa-0 ma-0 pl-2">
              <v-autocomplete
                :items="salesmanIDs"
                v-model="salesmanID"
                ref="sman_ref"
                @keydown.native="salesmanKeyDown"
                append-icon="mdi-magnify"
                label="Salesman ID"
                outlined
                :loading="loadingDataSalm"
                hide-selected
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0 mt-5 mb-2">
              <v-textarea
                dense
                v-model="note"
                ref="note_ref"
                @keydown.native="noteKeyDown"
                label="Note"
                class="text-area-max-height"
                height="110"
                outlined
                no-resize
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Do you want to change invoice type?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="clear()"
          >
            Yes
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!--  -->
    <!--  -->
    <!-- Balance Dialog -->
    <!--  -->
    <!--  -->
    <v-dialog v-model="isBalanceDialog" width="400">
      <v-card>
        <v-card-title
          v-if="message != null"
          class="green--text darken-3 pa-0 px-4 pt-3 title-center"
        >
          <v-icon class="appBar-icon pr-2">mdi-coffee-maker-check</v-icon>
          {{ message }}
        </v-card-title>
        <v-row justify="center" align-content="center" class="ma-0 pa-4">
          <span class="custom-f">{{ "Balance :" }}</span>
          <span class="custom-f text-caption-new pl-1">{{
            "Rs." + balance.toFixed(2)
          }}</span>
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <!-- Pay Button -->
    <v-fab-transition>
      <v-btn
        @click="invoiceMake()"
        x-large
        dark
        bottom
        :disabled="payBtnLoading"
        :loading="payBtnLoading"
        right
        fixed
        color="button"
      >
        <v-icon>mdi-check-outline</v-icon>
        <span class="pl-2">Pay</span>
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

var constituentsRef;
var itemsStockRef;
var invoicesRef;
var employeesRef;
var bankMoneyRef;
var warehousesRef;

export default {
  name: "home_screen",
  mounted() {
    constituentsRef = this.$fireStore.collection("constituents");
    itemsStockRef = this.$fireStore.collection("itemsStock");
    invoicesRef = this.$fireStore.collectionGroup("invoices");
    employeesRef = this.$fireStore.collection("employees");
    bankMoneyRef = this.$fireStore.collection("bank_money");
    warehousesRef = this.$fireStore.collection("warehouses");
    this.initDataItems();
    this.initDataConstituents();
    this.initDataSalesmans();
    this.$refs.item_code_ref.$refs.input.focus();
  },
  created() {
    window.addEventListener("keyup", this.keyhandler);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyhandler);
  },
  // mixins: [systemUser],
  data() {
    return {
      isSupplierInvo: false,
      supplierInvoNo: null,
      whIds: [],
      items: [],
      itemsBackup: [],
      selectedStockID: null,
      loadingItemsData: false,
      qty: null,
      payBtnLoading: false,
      // Button / Dialog
      dialog: false,
      switchLoading: false,
      message: null,
      // qtyAmount: 0,
      grossAmount: 0,
      netAmount: 0,
      // Balance Dialog
      isBalanceDialog: false,
      balance: 2000,
      // payments and final step of invoice create
      discountUnitRs: true,
      discount: 0,
      cashAmount: null,
      cardAmount: null,
      chequeAmount: null,
      chequeNo: null,
      issueDateMenu: false,
      issueDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      realiseDateMenu: false,
      realiseDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      salesmanID: null,
      constituentID: null,
      loadingDataCons: false,
      loadingDataSalm: false,
      constituentsIDs: [],
      salesmanIDs: [],
      note: null,
      // Table
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 20,
      headers: [
        {
          text: "Item Name",
          align: "start",
          //   sortable: false,
          value: "item_name",
        },
        { text: "Qty", value: "qty" },
        { text: "Price", value: "price" },
        { text: "Total", value: "total" },
        { text: "", value: "actions", sortable: false, width: 100 },
      ],
      BodyData: [],
      selectedBillItemsList: [],
    };
  },
  methods: {
    keyhandler(e) {
      // e.preventDefault();
      // e.stopPropagation();
      try {
        if (e.ctrlKey && e.which === 73) {
          // ctrl + i
          this.$refs.item_code_ref.$refs.input.focus();
        } else if (e.ctrlKey && e.which === 81) {
          // ctrl + q
          this.$refs.qty_ref.$refs.input.focus();
        } else if (e.ctrlKey && e.altKey && e.which === 68) {
          // ctrl + alt + d
          this.$refs.discount_ref.$refs.input.focus();
        } else if (e.altKey && e.which === 73) {
          // alt + i
          this.$refs.sup_invo_ref.$refs.input.focus();
        } else if (e.altKey && e.which === 80) {
          // alt + p
          this.$refs.cash_ref.$refs.input.focus();
        } else if (e.which === 113) {
          // F5
          this.invoiceMake();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async initDataItems() {
      try {
        this.loadingItemsData = true;
        await this.loadWhIDsByBrID().then(async () => {
          await itemsStockRef.onSnapshot(
            { includeMetadataChanges: true },
            (snapshots) => {
              this.items = [];
              this.itemsBackup = [];
              snapshots.docs.forEach((element) => {
                if (element.data()?.br_id == this.userData.s_using_branch) {
                  this.items.push(element.data());
                  this.itemsBackup.push(element.data());
                } else if (
                  this.whIds.some((e) => e === element.data()?.wh_id)
                ) {
                  this.items.push(element.data());
                  this.itemsBackup.push(element.data());
                }
              });
              this.loadingItemsData = false;
            }
          );
        });
      } catch (error) {
        this.loadingItemsData = false;
        console.log(error);
      }
    },
    async loadWhIDsByBrID() {
      try {
        await warehousesRef
          .where("br_id", "==", this.userData.s_using_branch)
          .onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
            this.whIds = [];
            snapshots.docs.forEach((element) => {
              this.whIds.push(element.data().wh_id);
            });
          });
      } catch (error) {
        this.loadingItemsData = false;
        console.log(error);
      }
    },
    async initDataConstituents() {
      try {
        this.loadingDataCons = true;

        await constituentsRef
          .where("cons_type", "==", this.isSupplierInvo ? "SUP" : "CUS")
          .onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
            this.constituentsIDs = [];
            snapshots.docs.forEach((element) => {
              this.constituentsIDs.push(element.data()["cons_id"]);
            });
            this.loadingDataCons = false;
            this.switchLoading = false;
          });
      } catch (error) {
        this.loadingDataCons = false;
        this.switchLoading = false;
        console.log(error);
      }
    },
    async initDataSalesmans() {
      try {
        this.loadingDataSalm = true;
        await employeesRef
          .where("emp_position", "==", "Salesman")
          .onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
            this.salesmanIDs = [];
            snapshots.docs.forEach((element) => {
              this.salesmanIDs.push(element.data()["emp_id"]);
            });
            this.loadingDataSalm = false;
          });
      } catch (error) {
        this.loadingDataSalm = false;
        console.log(error);
      }
    },
    async itemSetToBill() {
      try {
        if (this.selectedStockID == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter item code.",
            "error",
          ]);
        } else if (Number(this.qty) == 0) {
          this.$store.commit("alertMessage/message", [
            "Please enter valid qty.",
            "error",
          ]);
        } else {
          // Get Full details from items list [] using selected stock id
          var selectedData = await this.items.find((value) => {
            return value.stock_id == this.selectedStockID;
          });
          // Manage availability

          let qtyOfEntered = 0;
          this.selectedBillItemsList.forEach((element) => {
            if (element.stock_id == this.selectedStockID) {
              qtyOfEntered += element?.qty ?? 0;
            }
          });
          let availability = Number(selectedData.available_qty) - qtyOfEntered;
          // Check availability
          if (!this.isSupplierInvo && Number(this.qty) > Number(availability)) {
            this.$store.commit("alertMessage/message", [
              `The ${selectedData.item_code} Item qty is not available. Please check your stock availability and try again.`,
              "error",
            ]);
          } else {
            // Set full details to BodyData table aray and selectedBillItemsList
            this.BodyData.push({
              item_name: selectedData.item_name,
              qty: this.qty,
              price: this.isSupplierInvo
                ? selectedData.def_cost_price
                : selectedData.def_sale_price,
              total:
                Number(
                  this.isSupplierInvo
                    ? selectedData.def_cost_price
                    : selectedData.def_sale_price
                ) * Number(this.qty),
            });

            // Set bill data map to selectedBillItemsList list
            this.selectedBillItemsList.push({
              qty: Number(this.qty),
              total:
                Number(
                  this.isSupplierInvo
                    ? selectedData.def_cost_price
                    : selectedData.def_sale_price
                ) * Number(this.qty),
              ...selectedData,
            });
            this.selectedStockID = null;
            this.qty = null;
            this.makeGrossTotal();
            this.$refs.item_code_ref.$refs.input.focus();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    itemRemoveBill(item) {
      try {
        const index = this.BodyData.findIndex((data) => {
          return data === item;
        });
        this.BodyData.splice(index, 1);
        this.selectedBillItemsList.splice(index, 1);
        this.makeGrossTotal();
      } catch (error) {
        console.log(error);
      }
    },
    makeGrossTotal() {
      try {
        this.grossAmount = 0;
        this.BodyData.forEach((element) => {
          this.grossAmount += Number(element.total);
        });
        this.makeNetTotal();
      } catch (error) {
        console.log(error);
      }
    },
    makeNetTotal() {
      try {
        // Make discount and net total
        if (this.discountUnitRs) {
          if (Number(this.discount) > Number(this.grossAmount)) {
            this.$store.commit("alertMessage/message", [
              "Discount amount is invalid.",
              "error",
            ]);
            this.netAmount = 0;
          } else {
            this.netAmount = Number(this.grossAmount) - Number(this.discount);
          }
        } else {
          const discountAmountPre =
            (Number(this.grossAmount) * Number(this.discount)) / 100;
          if (discountAmountPre > Number(this.grossAmount)) {
            this.$store.commit("alertMessage/message", [
              "Discount presentage is invalid.",
              "error",
            ]);
            this.netAmount = 0;
          } else {
            this.netAmount = Number(this.grossAmount) - discountAmountPre;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Operating with DB
    // Invoice Bill Payments make
    async invoiceMake() {
      try {
        if (this.BodyData.length == 0) {
          this.$store.commit("alertMessage/message", [
            "Please select bill items.",
            "error",
          ]);
        } else if (
          this.isSupplierInvo &&
          (this.supplierInvoNo == null || this.supplierInvoNo == "")
        ) {
          this.$store.commit("alertMessage/message", [
            "Please enter Invoice No.",
            "error",
          ]);
        } else if (
          (this.constituentID == null ||
            this.constituentID == "" ||
            this.constituentID == "Def") &&
          Number(this.cashAmount ?? 0) +
            Number(this.cardAmount ?? 0) +
            Number(this.chequeAmount ?? 0) <
            Number(this.netAmount)
        ) {
          this.$store.commit("alertMessage/message", [
            "Invalid amount of payments.Please add valid payments.",
            "error",
          ]);
        } else if (
          Number(this.cardAmount) + Number(this.chequeAmount) >
          Number(this.netAmount)
        ) {
          this.$store.commit("alertMessage/message", [
            "Invalid amount of payments.",
            "error",
          ]);
        } else if (
          Number(this.chequeAmount) > 0 &&
          (this.chequeNo == null || this.chequeNo == "")
        ) {
          this.$store.commit("alertMessage/message", [
            "Please enter Cheque No.",
            "error",
          ]);
        } else if (
          this.isSupplierInvo &&
          (this.constituentID == null || this.constituentID == "")
        ) {
          this.$store.commit("alertMessage/message", [
            "Please enter Constituent ID.",
            "error",
          ]);
        } else {
          this.payBtnLoading = true;
          // Get next customer invoice number
          var nextInvoID;
          if (!this.isSupplierInvo) {
            await invoicesRef
              .where("cons_type", "==", "CUS")
              .get()
              .then((snapshots) => {
                var idList = [];
                snapshots.docs?.forEach((doc) => {
                  idList.push(
                    Number(doc?.data()["invoice_no"].replace("CIN", ""))
                  );
                });
                return idList.length != 0
                  ? Math.max(...idList) + 1
                  : idList.length + 1; // Get max id and increament 1
              })
              .then((value) => {
                nextInvoID = value;
              });
          }
          nextInvoID = this.isSupplierInvo
            ? "SIN" + this.supplierInvoNo
            : "CIN" + nextInvoID;
          // Make invoice
          constituentsRef
            .doc(this.constituentID ?? "Def")
            .collection("invoices")
            .doc(nextInvoID)
            .set({
              invoice_no: nextInvoID,
              cons_type: this.isSupplierInvo ? "SUP" : "CUS",
              cons_id: this.constituentID ?? "Def",
              salesman_id: this.salesmanID ?? null,
              gross_total: this.grossAmount,
              discount_amount: this.discount,
              discount_unit: this.discountUnitRs ? "Rs" : "%",
              net_total: this.netAmount,
              create_emp_id: this.userData.s_user_id,
              br_id: this.userData.s_using_branch,
              create_date: new Date(),
              note: this.note ?? null,
            })
            .then(async () => {
              // Make Bill
              await this.bill_and_Stock_Manage(nextInvoID);
            });
        }
      } catch (error) {
        this.payBtnLoading = false;
        console.log(error);
      }
    },
    async bill_and_Stock_Manage(nextInvoID) {
      try {
        // Declare Batch
        var batch = this.$batch.batch();
        this.selectedBillItemsList.forEach(async (element) => {
          // //////////////////////////
          // ///////// Bill Create ////
          // /////////////////////////
          // Generate bill id
          var billID = uuid();
          // Identify collection
          var docBillRef = constituentsRef
            .doc(this.constituentID ?? "Def")
            .collection("invoices")
            .doc(nextInvoID)
            .collection("invoice_bill")
            .doc(billID);
          // Set data map
          var dataBill = {
            bill_id: billID,
            invoice_no: nextInvoID,
            stock_id: element.stock_id,
            item_code: element.item_code,
            item_name: element.item_name,
            qty: element.qty,
            measuar_unit: element.measuar_unit,
            price: this.isSupplierInvo
              ? element.def_cost_price
              : element.def_sale_price,
            total: element.total,
            create_date: new Date(),
          };
          // Set to batch
          batch.set(docBillRef, dataBill);
          // //////////////////////////////
          // ///////// Stock Update ///////
          // /////////////////////////////
          // Identify collection
          var docSatockRef = itemsStockRef.doc(element.stock_id);
          // Set data map
          var dataStock = {
            available_qty: this.$firebase.firestore.FieldValue.increment(
              this.isSupplierInvo ? Number(element.qty) : -Number(element.qty)
            ),
            last_update_date: new Date(),
          };
          // Set to batch
          batch.update(docSatockRef, dataStock);
        });
        // Commit batches
        batch.commit();
      } catch (error) {
        this.payBtnLoading = false;
        console.log(error);
      } finally {
        this.paymentMake(nextInvoID);
      }
    },

    async paymentMake(nextInvoID) {
      try {
        // Generate payment id
        var payID = uuid();
        // Make Balance Amount
        var paymentsAmount =
          Number(this.cashAmount ?? 0) +
          Number(this.cardAmount ?? 0) +
          Number(this.chequeAmount ?? 0);
        // Make balance
        this.balance = paymentsAmount - Number(this.netAmount);
        //
        if (paymentsAmount > 0) {
          // Add data
          await constituentsRef
            .doc(this.constituentID ?? "Def")
            .collection("payments")
            .doc(payID)
            .set({
              pay_id: payID,
              invoice_no: nextInvoID,
              cons_type: this.isSupplierInvo ? "SUP" : "CUS",
              cons_id: this.constituentID ?? "Def",
              salesman_id: this.salesmanID ?? null,
              cash_amount:
                this.balance > 0
                  ? Number(this.cashAmount ?? 0) - this.balance
                  : Number(this.cashAmount ?? 0),
              card_amount: Number(this.cardAmount ?? 0),
              cheque_amount: Number(this.chequeAmount ?? 0),
              che_no: this.chequeNo ?? null,
              che_issue_date:
                this.chequeNo != null ? new Date(this.issueDate) : null,
              is_che_realise: this.chequeNo != null ? false : null,
              che_realise_date:
                this.chequeNo != null ? new Date(this.realiseDate) : null,
              is_che_return: this.chequeNo != null ? false : null,
              payment_type: "Invo_Init",
              create_emp_id: this.userData.s_user_id,
              br_id: this.userData.s_using_branch,
              create_date: new Date(),
            })
            .then(async () => {
              if (Number(this.cardAmount) > 0) {
                // Generate trans id
                var transID = uuid();
                await bankMoneyRef.doc(transID).set({
                  trans_id: transID,
                  pay_id: payID,
                  trans_type: "Card",
                  note: "Card Payment",
                  dip_amount: this.isSupplierInvo ? 0 : Number(this.cardAmount),
                  with_amount: this.isSupplierInvo
                    ? Number(this.cardAmount)
                    : 0,
                  create_emp_id: this.userData.s_user_id,
                  trans_date: new Date(),
                });
              }
            })
            .then(() => {
              this.printInvoice(nextInvoID);
            })
            .catch((error) => {
              this.payBtnLoading = false;
              console.log(error);
            });
        } else {
          this.printInvoice(nextInvoID);
        }
      } catch (error) {
        this.payBtnLoading = false;
        console.log(error);
      }
    },
    printInvoice(invoiceNo) {
      try {
        // Manage print data
        var printDataList = {};
        printDataList.items = [];
        // Set items and psc
        let pcs = 0;
        this.selectedBillItemsList.forEach(function (element, i) {
          var data = [];
          data.push(i + 1 + ". " + element.item_name);
          data.push(" x " + element.qty);
          pcs += Number(element.qty);
          data.push(element.total.toFixed(2));
          printDataList.items.push(data);
        });
        printDataList.branch = this.userData.s_using_branch;
        printDataList.cashier = this.userData.s_user_id;
        printDataList.salesman = this.salesmanID ?? "";
        printDataList.invoiceNo = invoiceNo;
        printDataList.invoType = this.isSupplierInvo ? "SUP" : "CUS";
        printDataList.cons_id =
          this.constituentID == null || this.constituentID == ""
            ? "Def"
            : this.constituentID;
        printDataList.grossTotal = Number(this.grossAmount.toString()).toFixed(
          2
        );
        printDataList.disUnit = this.discountUnitRs
          ? "DISCOUNT  Rs"
          : "DISCOUNT  %";
        printDataList.discount = Number(this.discount?.toString() ?? 0).toFixed(
          2
        );
        printDataList.netTotal = Number(this.netAmount.toString()).toFixed(2);
        printDataList.cashTenderd = Number(
          this.cashAmount?.toString() ?? 0
        ).toFixed(2);
        printDataList.cardTenderd = Number(
          this.cardAmount?.toString() ?? 0
        ).toFixed(2);
        printDataList.chequeTenderd = Number(
          this.chequeAmount?.toString() ?? 0
        ).toFixed(2);
        printDataList.balance = Number(this.balance.toString()).toFixed(2);
        printDataList.itemsPcs =
          "Items : " + this.selectedBillItemsList.length + "  Pcs : " + pcs;
        // Set sale type
        var saleType = "";
        if (Number(this.cashAmount ?? 0) > 0) saleType = "CASH";
        if (Number(this.cardAmount ?? 0) > 0)
          saleType.length != 0 ? (saleType += " + CARD") : (saleType = "CARD");
        if (Number(this.chequeAmount ?? 0) > 0)
          saleType.length != 0 ? (saleType += " + CHEQ") : (saleType = "CHEQ");
        printDataList.saleType = saleType;
        // Items with Pcs
        "Items : " + this.selectedBillItemsList.length + "  Pcs : " + pcs;
        // Date and Time
        var date = new Date();
        printDataList.date =
          "Date : " + this.$moment(date).format("YYYY-MM-DD");
        printDataList.time = "Time : " + this.$moment(date).format("h:mm a");
        // Make Print
        window.doPrinting(printDataList);
        // Clear
        this.clear();
        // Make current date
        var dateDefault = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.cashAmount = null;
        this.cardAmount = null;
        this.chequeAmount = null;
        this.chequeNo = null;
        this.issueDate = dateDefault;
        this.realiseDate = dateDefault;
        this.salesmanID = null;
        this.note = null;
        // Message
        this.message = "Invoice created Successfully...";
        if (this.balance > 0) {
          this.isBalanceDialog = !this.isBalanceDialog;
        } else {
          this.$store.commit("alertMessage/message", [this.message, "success"]);
        }
        this.$refs.item_code_ref.$refs.input.focus();
        this.payBtnLoading = false;
      } catch (error) {
        this.payBtnLoading = false;
        console.log(error);
      }
    },
    clear() {
      this.BodyData = [];
      this.selectedBillItemsList = [];
      this.selectedStockID = null;
      this.qty = null;
      this.discount = 0;
      this.supplierInvoNo = null;
      this.constituentID = null;
      this.makeGrossTotal();
    },
    //
    clearWithSwitch() {
      try {
        this.switchLoading = true;
        this.clear();
        this.initDataConstituents();
        if (this.isSupplierInvo) {
          this.items = this.itemsBackup.filter((e) => e.item_type != "PI");
        } else {
          this.items = this.itemsBackup;
        }
      } catch (error) {
        this.switchLoading = false;

        console.log(error);
      } finally {
        this.switchLoading = false;
      }
    },
    // KeyDowns
    itemCodeKeyDownEnter() {
      try {
        if (this.selectedStockID != null) {
          this.$refs.qty_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    qtyKeyDown(e) {
      try {
        //
        // console.log(e);
        if (e.key === "Backspace" && (this.qty == "" || this.qty == null)) {
          this.$refs.item_code_ref.$refs.input.focus();
        } else if (e.which === 9) {
          // It focused cash amount text
          this.$refs.discount_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    discountKeyDown(e) {
      try {
        //
        if (e.key === "Backspace" && this.discount == "") {
          this.$refs.item_code_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.cash_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    cashKeyDown(e) {
      try {
        //
        if (
          e.key === "Backspace" &&
          (this.cashAmount == null || this.cashAmount == "")
        ) {
          this.$refs.item_code_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.card_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    cardKeyDown(e) {
      try {
        //
        if (
          e.key === "Backspace" &&
          (this.cardAmount == null || this.cardAmount == "")
        ) {
          this.$refs.cash_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.cheque_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    chequeKeyDown(e) {
      try {
        //
        if (
          e.key === "Backspace" &&
          (this.chequeAmount == null || this.chequeAmount == "")
        ) {
          this.$refs.card_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.cheque_no_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    cheNoKeyDown(e) {
      try {
        //
        if (
          e.key === "Backspace" &&
          (this.chequeNo == "" || this.chequeNo == null)
        ) {
          this.$refs.cheque_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.cons_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    constituentKeyDown(e) {
      try {
        //
        if (
          e.key === "Backspace" &&
          (this.constituentID == null || this.constituentID == "")
        ) {
          this.$refs.cheque_no_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.sman_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    salesmanKeyDown(e) {
      try {
        //
        if (
          e.key === "Backspace" &&
          (this.salesmanID == null || this.salesmanID == "")
        ) {
          this.$refs.cons_ref.$refs.input.focus();
        } else if (e.key === "Enter") {
          this.$refs.note_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    noteKeyDown(e) {
      try {
        //
        if (e.key === "Backspace" && (this.note == null || this.note == "")) {
          this.$refs.sman_ref.$refs.input.focus();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async signOut() {
      try {
        await this.$auth
          .signOut()
          .then(async () => {
            localStorage.removeItem("systemuser");
            await this.$store.commit("alertMessage/message", [
              "Sign out successfully.",
              "success",
            ]);
            this.$router.go().catch(() => {});
            // window.history.go("/"); // Only Web
          })
          .catch((error) => {
            this.$store.commit("AlertMessage/message", [error, "error"]);
          });
      } catch (error) {
        this.$store.commit("alertMessage/message", [error, "error"]);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/theme_styles.scss";

.icon-color {
  .v-icon.v-icon {
    // @extend .appBar-icon;
    &.primary--text {
      color: white !important;
    }
  }
}
.warp-off {
  flex-wrap: nowrap !important;
}
.or-text {
  font-weight: bold;
  color: rgb(114, 114, 114);
}
.custom-f {
  font-size: 25px !important;
  font-weight: bold !important;
}
.title-center {
  justify-content: center;
}
</style>


//  // Identify collection
//           await invoicesRef
//             .doc(nextInvoID)
//             .collection("invoice_bill")
//             .doc(billID)
//             .set({
//               bill_id: billID,
//               invoice_no: nextInvoID,
//               stock_id: element.stock_id,
//               item_code: element.item_code,
//               item_name: element.item_name,
//               qty: element.qty,
//               measuar_unit: element.measuar_unit,
//               price: this.isSupplierInvo
//                 ? element.def_cost_price
//                 : element.def_sale_price,
//               total: element.total,
//               create_date: new Date(),
//             })
//             .then(async () => {
//               // //////////////////////////////
//               // ///////// Stock Update ///////
//               // /////////////////////////////
//               // Identify collection
//               await itemsStockRef
//                 .doc(element.stock_id)
//                 .update({
//                   available_qty: this.$firebase.firestore.FieldValue.increment(
//                     this.isSupplierInvo
//                       ? Number(element.qty)
//                       : -Number(element.qty)
//                   ),
//                   last_update_date: new Date(),
//                 })
//                 .catch((e) => {
//                   this.message = e;
//                   this.isMessage = true;
//                   this.is = true;
//                 });
//             })
//             .catch((e) => {
//             });
//         });