<template>
  <div>
    <app-bar-compo
      :title="
        this.$route.query.id != null
          ? 'Contituent - Invoices'
          : 'Contituents - Invoices'
      "
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <!-- Filters -->
      <template v-slot:filters>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Invoice No</h3>
            <v-select
              class="mt-2"
              dense
              v-model="invoiceNo"
              :items="invoiceNos"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Salesman ID</h3>
            <v-select
              class="mt-2"
              dense
              v-model="smanID"
              :items="salesmanIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <h3>Employee ID</h3>
            <v-select
              class="mt-2"
              dense
              v-model="empID"
              :items="empIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-5 mt-2">
          <v-col>
            <v-row class="pa-0"
              ><v-checkbox
                class="ma-0 pa-0 mr-3"
                v-model="withDateRange"
                label="with"
              ></v-checkbox>
              <h3>Date Range (Create Date)</h3></v-row
            >
            <v-date-picker
              v-if="withDateRange"
              v-model="pickDateRange"
              range
              full-width
              header-color="blue"
              color="green"
              class="date-Picker mt-4"
            ></v-date-picker>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>
    <!-- Body -->
    <h4 v-if="this.$route.query.id != null" class="mt-3 ml-3 text-caption-new">
      {{ this.$route.query.id }} Invoices
    </h4>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :search="searchTxt"
        :page.sync="page"
        :loading="isInvoTableLoading"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1 data-tbl"
        sort-by="create_date"
        :sort-desc="true"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.create_date="{ item }">
          <span>{{ dateTimeFormater(item.create_date) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('edit', item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="indigo darken-2"
                      class="ma-1"
                      @click="dialogActionBillView(item)"
                    >
                      <v-icon>mdi-paper-roll</v-icon>
                    </v-btn>
                  </template>
                  <span>Bill Details</span>
                </v-tooltip>
                <!--  -->
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-container>
    <!--  -->
    <!-- Add/ Edit/ Delete Dialog Invoice -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="dialogType != 'd'">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  :items="salesmanIDs"
                  v-model="editItem.salesman_id"
                  ref="sman_ref"
                  append-icon="mdi-magnify"
                  label="Salesman ID"
                  outlined
                  hide-selected
                  :loading="loadingDataSalmEmp"
                  auto-select-first
                  dense
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  :items="empIDs"
                  v-model="editItem.create_emp_id"
                  ref="create_emp_ref"
                  append-icon="mdi-magnify"
                  label="Create Emp ID"
                  outlined
                  hide-selected
                  :loading="loadingDataSalmEmp"
                  auto-select-first
                  dense
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-text-field
                  dense
                  v-model="editItem.discount_amount"
                  label="Discount Amount"
                  outlined
                  clearable
                  @input="makeNetTotal()"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  dense
                  v-model="editItem.discount_unit"
                  label="Unit"
                  :items="['Rs', '%']"
                  hide-selected
                  outlined
                  @change="makeNetTotal()"
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.note"
                  label="Note"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  clearable
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <br />
            <small>*indicates required field</small>
          </v-container>
          <v-row v-if="dialogType == 'd'" class="ma-0 pa-0 mt-4">
            <span class="fbig text-caption-new"
              >Do you want to update stock?</span
            >
            <v-switch
              inset
              color="green darken-2"
              class="ma-0 pa-0 ml-4"
              :label="isStockUpdate ? 'Yes' : 'No'"
              v-model="isStockUpdate"
            ></v-switch>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(editItem.invoice_no)
                : deleteData(editItem.invoice_no, editItem.cons_type)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Invoice Bill Dialog -->
    <!--  -->
    <v-dialog v-model="billDialog" fullscreen>
      <v-card>
        <v-toolbar>
          <v-toolbar-title>{{ billDialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="billDialog = !billDialog">
              <v-icon class="appBar-icon">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <br />
        <!-- Bill Table -->
        <v-container fluid>
          <v-data-table
            :headers="billHeaders"
            :items="billBodyData"
            :search="searchTxtBill"
            :page.sync="billPage"
            :items-per-page="itemsPerPage"
            :loading="isBillTableLoading"
            hide-default-footer
            sort-by="create_date"
            :sort-desc="true"
            class="elevation-1 data-tbl"
            @page-count="billPageCount = $event"
          >
            <template v-slot:item.return_date="{ item }">
              <span>{{ dateTimeFormater(item.return_date) }}</span>
            </template>
            <template v-slot:item.return_back_date="{ item }">
              <span>{{ dateTimeFormater(item.return_back_date) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu left origin="center" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon dark v-bind="attrs" v-on="on">
                    <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-container class="background">
                  <v-row class="pa-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          dark
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          color="red darken-4"
                          class="ma-1"
                          @click="dialogActionBillCrud('delete', item)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          dark
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          color="indigo darken-2"
                          class="ma-1"
                          @click="dialogActionBillCrud('return', item)"
                        >
                          <v-icon>mdi-clipboard-arrow-left</v-icon>
                        </v-btn>
                      </template>
                      <span>Return Item</span>
                    </v-tooltip>
                    <!--  -->
                  </v-row>
                </v-container>
              </v-menu>
            </template>
          </v-data-table>
        </v-container>
        <div class="text-center pt-2">
          <v-pagination
            v-model="billPage"
            :length="billPageCount"
          ></v-pagination>
        </div>
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Add/ Edit/ Delete Invoice Bill Item Actions Dialog -->
    <!--  -->
    <v-dialog
      v-model="billItemDialogAction"
      scrollable
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row align="center" justify="start" class="ma-0 pa-0">
              <v-col cols="12" sm="12" md="8" lg="8" class="ma-0 pa-0">
                <v-text-field
                  dense
                  v-model="editBillItem.return_qty"
                  label="Return Qty"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-switch
                inset
                class="mr-3 mt-3"
                :label="returnBack ? 'Return Back' : 'Return'"
                v-model="returnBack"
                dense
              ></v-switch>
              <!-- <span style="font-size: 20px">OR</span>
              <v-col>
                <v-text-field
                  dense
                  v-model="editBillItem.return_amount"
                  label="Return Amount"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col> -->
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            dark
            outlined
            :disabled="btnLoading"
            @click="billItemDialogAction = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="dialogType == 'r' ? returnBillItem() : deleteBillItem()"
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Manage Payments -->
    <v-dialog persistent v-model="payManageDialog" width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Payment Management</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <span class="red--text fmedium"
              >{{
                this.editItem.cons_type == "SUP"
                  ? this.payReturn
                    ? "* You have to get "
                    : "* You have to give "
                  : this.payReturn
                  ? "* You have to return "
                  : "* You have to get "
              }}
              some payment(s).</span
            >
            <v-row justify="center" align-content="center" class="ma-0 pa-4">
              <span class="custom-f">{{ "Balance :" }}</span>
              <span class="custom-f text-caption-new pl-1">{{
                "Rs." + balance.toFixed(2)
              }}</span>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12">
                <v-select
                  dense
                  v-model="retChequNos"
                  :label="
                    !payReturn
                      ? 'Return Back Cheque No(s)'
                      : 'Return Cheque No(s)'
                  "
                  :items="chequeNos"
                  hide-selected
                  multiple
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="retCashAmount"
                  :label="
                    (this.editItem.cons_type == 'SUP'
                      ? this.payReturn
                        ? 'Get '
                        : 'Give '
                      : this.payReturn
                      ? 'Return '
                      : 'Get ') + 'Cash Amount'
                  "
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col class="mt-2" cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="retCardAmount"
                  :label="
                    (this.editItem.cons_type == 'SUP'
                      ? this.payReturn
                        ? 'Get '
                        : 'Give '
                      : this.payReturn
                      ? 'Return '
                      : 'Get ') + 'Card Amount'
                  "
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <v-btn
            v-if="balance <= 0"
            color="red darken-2"
            dark
            outlined
            :disabled="btnLoading"
            @click="payManageDialog = false"
          >
            Close
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="managePayments()"
          >
            Save
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

var invoicesRef;
var invoiceBillRef;
var employeesRef;
var itemsStockRef;
var paymentsRef;
var constituentsRef;
var bankMoneyRef;

export default {
  name: "invoices_screen",
  mounted() {
    paymentsRef = this.$fireStore.collectionGroup("payments");
    constituentsRef = this.$fireStore.collection("constituents");
    invoicesRef = this.$fireStore.collectionGroup("invoices");
    invoiceBillRef = this.$fireStore.collectionGroup("invoice_bill");
    itemsStockRef = this.$fireStore.collection("itemsStock");
    employeesRef = this.$fireStore.collection("employees");
    bankMoneyRef = this.$fireStore.collection("bank_money");
    this.initData();
    this.initChequeNo();
    this.initDataSalesmansEmps();
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      editBillItem: {},
      loadingDataSalmEmp: false,
      isStockUpdate: true,
      returnBack: false,
      maxReturn: 0,
      // Common
      salesmanIDs: [],
      empIDs: [],
      chequeNos: [],
      retChequNos: [],
      balance: 0,
      consAmountsDetails: [],
      retCashAmount: 0,
      retCardAmount: 0,
      // Button
      btnLoading: false,
      // Bill
      billDialog: false,
      billItemDialogAction: false,
      billDialogTitle: null,
      //   Filters
      pickDateRange: [],
      invoiceNo: null,
      smanID: null,
      invoiceNos: [],
      empID: null,
      withDateRange: false,
      // Payment Management
      payManageDialog: false,
      payReturn: true,
      //   Table Invoice
      isInvoTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      //   Table Bill
      isBillTableLoading: false,
      billPage: 1,
      billPageCount: 0,
      searchTxtBill: "",
      headers: [
        { text: "Invoice No", align: "start", value: "invoice_no" },
        { text: "Cons ID", value: "cons_id", sortable: false },
        { text: "Cons Type", value: "cons_type", sortable: false },
        { text: "Salesman ID", value: "salesman_id" },
        { text: "Gross Total", value: "gross_total" },
        { text: "Discount Amount", value: "discount_amount" },
        { text: "Unit", value: "discount_unit" },
        { text: "Net Total", value: "net_total" },
        { text: "Return Amount", value: "return_amount", sortable: false },
        {
          text: "Note",
          value: "note",
          width: 300,
          class: "pad-class",
          sortable: false,
        },
        { text: "Create Emp ID", value: "create_emp_id", sortable: false },
        { text: "Create Date", width: 130, value: "create_date" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      billHeaders: [
        // { text: "Bill ID", align: "start", value: "bill_id" },
        // { text: "Invoice No", align: "start", value: "invoice_no" },
        // { text: "Stock ID", value: "stock_id", sortable: false },
        { text: "Item Code", value: "item_code" },
        { text: "Item Name", value: "item_name" },
        { text: "Qty", value: "qty" },
        { text: "Measur Unit", value: "measur_unit" },
        { text: "Price", value: "price" },
        { text: "Total", value: "total" },
        { text: "Return Amount", value: "return_amount" },
        { text: "Return Qty", value: "return_qty" },
        { text: "Return Emp ID", value: "ret_emp_id" },
        { text: "Return Date", width: 130, value: "return_date" },
        {
          text: "Return Back Date",
          width: 130,
          value: "return_back_date",
        },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
      billBodyData: [],
    };
  },
  methods: {
    async initData() {
      try {
        this.isInvoTableLoading = true;
        var id = this.$route.query.id ?? null;
        var ref =
          id != null ? invoicesRef.where("cons_id", "==", id) : invoicesRef;
        await ref.onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
          this.BodyData = [];
          this.BodyDataBack = [];
          this.invoiceNos = [];
          snapshots.docs.forEach((element) => {
            this.BodyData.push(element.data());
            this.BodyDataBack.push(element.data());
            this.invoiceNos.push(element.data()["invoice_no"]);
          });
          this.isInvoTableLoading = false;
        });
      } catch (error) {
        this.isInvoTableLoading = false;
        console.log(error);
      }
    },
    async initChequeNo() {
      try {
        this.isTableLoading = true;
        var id = this.$route.query.id ?? null;
        var ref =
          id != null ? paymentsRef.where("cons_id", "==", id) : paymentsRef;
        await ref.onSnapshot({ includeMetadataChanges: true }, (snapshots) => {
          this.chequeNos = [];
          snapshots.docs.forEach((element) => {
            this.chequeNos.push(element.data()["che_no"] ?? null);
          });
          this.isTableLoading = false;
        });
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async initBillData(invoiceNo) {
      try {
        this.isBillTableLoading = true;
        await invoiceBillRef
          .where("invoice_no", "==", invoiceNo)
          .get()
          .then((snapshots) => {
            this.billBodyData = [];
            snapshots.docs.forEach((element) => {
              this.billBodyData.push(element.data());
            });
            this.isBillTableLoading = false;
          });
      } catch (error) {
        this.isBillTableLoading = false;
        console.log(error);
      }
    },
    async initDataSalesmansEmps() {
      try {
        this.loadingDataSalmEmp = true;
        await employeesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.salesmanIDs = [];
            this.empIDs = [];
            snapshots.docs.forEach((element) => {
              if (element.data()["emp_position"] == "Salesman") {
                this.salesmanIDs.push(element.data()["emp_id"]);
              } else if (
                element.data()["emp_position"] == "Cashier" ||
                element.data()["emp_position"] == "Manager"
              ) {
                this.empIDs.push(element.data()["emp_id"]);
              }
            });
            this.loadingDataSalmEmp = false;
          }
        );
      } catch (error) {
        this.loadingDataSalmEmp = false;
        console.log(error);
      }
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item) {
      this.editItem = {};
      this.retChequNos = [];
      this.balance = 0;
      this.consAmountsDetails = [];
      this.retCashAmount = 0;
      this.retCardAmount = 0;
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.invoice_no = item.invoice_no;
        this.editItem.cons_id = item.cons_id;
        this.editItem.salesman_id = item.salesman_id;
        this.editItem.cons_type = item.cons_type;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },

    dialogActionBillView(item) {
      // Set invoice details to the editItem map
      this.editItem = Object.assign({}, item);
      this.billDialogTitle = item.invoice_no + " Bill Details";
      this.initBillData(item.invoice_no);
      this.billDialog = !this.billDialog;
    },
    dialogActionBillCrud(type, item) {
      this.editBillItem = Object.assign({}, item);
      if (type == "return") {
        this.maxReturn = item.return_qty ?? 0;
        this.dialogType = "r";
        this.dialogTitle = "Return";
      } else if (type == "delete") {
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      }
      // else {
      //   this.dialogType = "a";
      //   this.dialogTitle = "Add";
      // }
      this.billItemDialogAction = !this.billItemDialogAction;
    },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    makeNetTotal() {
      try {
        // Make discount and net total
        if (this.editItem.discount_unit == "Rs") {
          if (
            Number(this.editItem.discount_amount) >
            Number(this.editItem.gross_total)
          ) {
            this.$store.commit("alertMessage/message", [
              "Discount amount is invalid.",
              "error",
            ]);
            this.editItem.net_total = this.editItem.gross_total;
            this.editItem.discount_amount = 0;
          } else {
            this.editItem.net_total =
              Number(this.editItem.gross_total) -
              Number(this.editItem.discount_amount);
          }
        } else {
          const discountAmountPre =
            (Number(this.editItem.gross_total) *
              Number(this.editItem.discount_amount)) /
            100;
          if (discountAmountPre > Number(this.editItem.gross_total)) {
            this.$store.commit("alertMessage/message", [
              "Discount presentage is invalid.",
              "error",
            ]);

            this.editItem.net_total = this.editItem.gross_total;
            this.editItem.discount_amount = 0;
          } else {
            this.editItem.net_total =
              Number(this.editItem.gross_total) - discountAmountPre;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Operating with DB
    async editData(id) {
      try {
        this.btnLoading = true;

        await invoicesRef
          .where("invoice_no", "==", id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs[0]?.ref.update({
              salesman_id: this.editItem.salesman_id ?? null,
              discount_amount: Number(this.editItem.discount_amount),
              discount_unit: this.editItem.discount_unit,
              net_total: Number(this.editItem.net_total),
              // create_emp_id: this.editItem.create_emp_id,
              note: this.editItem.note ?? null,
            });
          })
          .then(async () => {
            // Check Constituent Balance
            await this.checkConstituentBalance().then(() => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data updated successfully.",
                "success",
              ]);
            });
          })
          .catch((e) => {
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [e, "error"]);
          });
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async deleteData(id, consType) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Invoice No is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          // Declare Batch
          var batch = this.$batch.batch();
          await constituentsRef
            .doc(this.editItem.cons_id)
            .get()
            .then(async (snapshot) => {
              // Update Stock
              await snapshot?.ref
                .collection("invoices")
                .doc(id)
                .get()
                .then(async (snapshot) => {
                  await snapshot.ref
                    .collection("invoice_bill")
                    .get()
                    .then(async (querySnapshot) => {
                      await querySnapshot.docs?.forEach((doc) => {
                        if (this.isStockUpdate) {
                          // //////////////////////////////
                          // ///////// Stock Update ///////
                          // /////////////////////////////
                          // Set data map
                          var availability =
                            Number(doc.data()["qty"]) +
                            Number(doc.data()["return_qty"] ?? 0);
                          var dataStock = {
                            available_qty:
                              this.$firebase.firestore.FieldValue.increment(
                                consType == "SUP"
                                  ? -Number(availability)
                                  : Number(availability)
                              ),
                            last_update_date: new Date(),
                          };
                          batch.update(
                            itemsStockRef.doc(doc.data()["stock_id"]),
                            dataStock
                          );
                        }
                        // Delete batch of invoice bill item
                        batch.delete(doc.ref);
                      });
                    });
                  // Delete batch of invoice
                  await snapshot.ref.delete();
                })
                .then(() => {
                  batch
                    .commit()
                    .then(async () => {
                      // Check Constituent Balance
                      await this.checkConstituentBalance().then(() => {
                        this.dialog = false;
                        this.btnLoading = false;
                        this.$store.commit("alertMessage/message", [
                          "Data deleted successfully.",
                          "success",
                        ]);
                      });
                    })
                    .catch((e) => {
                      this.btnLoading = false;
                      this.$store.commit("alertMessage/message", [e, "error"]);
                    });
                });
            })
            .catch((e) => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [e, "error"]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    //
    returnBillItem() {
      try {
        if (
          this.editBillItem.return_qty == null ||
          this.editBillItem.return_qty == "" ||
          this.editBillItem.return_qty == 0
        ) {
          this.$store.commit("alertMessage/message", [
            "Please enter valid return qty.",
            "error",
          ]);
        } else if (
          !this.returnBack &&
          Number(this.editBillItem.qty) < Number(this.editBillItem.return_qty)
        ) {
          this.$store.commit("alertMessage/message", [
            "Return qty is more than item qty.Please enter valid return qty.",
            "error",
          ]);
        } else if (
          this.returnBack &&
          Number(this.maxReturn) < Number(this.editBillItem.return_qty)
        ) {
          this.$store.commit("alertMessage/message", [
            `Maximum return qty is ${this.maxReturn}.Please enter valid return qty.`,
            "error",
          ]);
        } else {
          this.btnLoading = true;
          var returnAmount =
            Number(this.editBillItem.return_qty) *
            Number(this.editBillItem.price);
          var grossTotal = this.returnBack
            ? Number(this.editItem.gross_total) + Number(returnAmount)
            : Number(this.editItem.gross_total) - Number(returnAmount);
          // Make new discount and net total
          if (this.editItem.discount_unit == "Rs") {
            if (Number(this.editItem.discount_amount) > Number(grossTotal)) {
              this.$store.commit("alertMessage/message", [
                "Discount amount is invalid.",
                "error",
              ]);

              this.editItem.net_total = this.editItem.gross_total;
              this.editItem.discount_amount = 0;
            } else {
              this.editItem.net_total =
                Number(grossTotal) - Number(this.editItem.discount_amount);
            }
          } else {
            const discountAmountPre =
              (Number(grossTotal) * Number(this.editItem.discount_amount)) /
              100;
            if (discountAmountPre > Number(grossTotal)) {
              this.$store.commit("alertMessage/message", [
                "Discount presentage is invalid.",
                "error",
              ]);

              this.editItem.net_total = this.editItem.gross_total;
              this.editItem.discount_amount = 0;
            } else {
              this.editItem.net_total = Number(grossTotal) - discountAmountPre;
            }
          }
          // DB update
          itemsStockRef
            .doc(this.editBillItem.stock_id)
            .update({
              available_qty: this.$firebase.firestore.FieldValue.increment(
                this.returnBack
                  ? this.editItem.cons_type == "SUP"
                    ? Number(this.editBillItem.return_qty)
                    : -Number(this.editBillItem.return_qty)
                  : this.editItem.cons_type == "SUP"
                  ? -Number(this.editBillItem.return_qty)
                  : Number(this.editBillItem.return_qty)
              ),
            })
            .then(() => {
              invoicesRef
                .where("invoice_no", "==", this.editBillItem.invoice_no)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.docs[0]?.ref.update({
                    gross_total: Number(grossTotal),
                    discount_amount: Number(this.editItem.discount_amount),
                    net_total: Number(this.editItem.net_total),
                    return_amount:
                      this.$firebase.firestore.FieldValue.increment(
                        this.returnBack
                          ? -Number(returnAmount)
                          : Number(returnAmount) ?? 0
                      ),
                  });
                })
                .then(() => {
                  // Update bill item
                  invoicesRef
                    .where("invoice_no", "==", this.editBillItem.invoice_no)
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.docs[0]?.ref
                        .collection("invoice_bill")
                        .doc(this.editBillItem.bill_id)
                        .update({
                          qty: this.$firebase.firestore.FieldValue.increment(
                            this.returnBack
                              ? Number(this.editBillItem.return_qty)
                              : -Number(this.editBillItem.return_qty)
                          ),
                          total: this.$firebase.firestore.FieldValue.increment(
                            this.returnBack
                              ? Number(returnAmount)
                              : -Number(returnAmount)
                          ),
                          return_amount:
                            this.$firebase.firestore.FieldValue?.increment(
                              this.returnBack
                                ? -Number(returnAmount)
                                : Number(returnAmount) ?? 0
                            ),
                          return_qty:
                            this.$firebase.firestore.FieldValue?.increment(
                              this.returnBack
                                ? -Number(this.editBillItem.return_qty)
                                : Number(this.editBillItem.return_qty)
                            ),
                          ret_emp_id: this.userData.s_user_id,
                          return_date: this.returnBack
                            ? this.editBillItem.return_date ?? null
                            : new Date(),
                          return_back_date: this.returnBack
                            ? new Date()
                            : this.editBillItem.return_back_date ?? null,
                        });
                    })
                    .then(async () => {
                      // Check Constituent Balance
                      await this.checkConstituentBalance().then(() => {
                        //
                        this.btnLoading = false;
                        this.billItemDialogAction = false;
                        this.billDialog = false;
                        this.$store.commit("alertMessage/message", [
                          "Data updated successfully.",
                          "success",
                        ]);
                      });
                    });
                })
                .catch((e) => {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [e, "error"]);
                });
            })
            .catch((e) => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [e, "error"]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    deleteBillItem() {
      try {
        this.btnLoading = true;
        var grossTotal =
          Number(this.editItem.gross_total) - Number(this.editBillItem.total);
        // Make new discount and net total
        if (this.editItem.discount_unit == "Rs") {
          if (Number(this.editItem.discount_amount) > Number(grossTotal)) {
            this.$store.commit("alertMessage/message", [
              "Discount amount is invalid.",
              "error",
            ]);

            this.editItem.net_total = this.editItem.gross_total;
            this.editItem.discount_amount = 0;
          } else {
            this.editItem.net_total =
              Number(grossTotal) - Number(this.editItem.discount_amount);
          }
        } else {
          const discountAmountPre =
            (Number(grossTotal) * Number(this.editItem.discount_amount)) / 100;
          if (discountAmountPre > Number(grossTotal)) {
            this.$store.commit("alertMessage/message", [
              "Discount presentage is invalid.",
              "error",
            ]);

            this.editItem.net_total = this.editItem.gross_total;
            this.editItem.discount_amount = 0;
          } else {
            this.editItem.net_total = Number(grossTotal) - discountAmountPre;
          }
        }
        // DB update
        itemsStockRef
          .doc(this.editBillItem.stock_id)
          .update({
            available_qty: this.$firebase.firestore.FieldValue.increment(
              this.editItem.cons_type == "SUP"
                ? -Number(this.editBillItem.qty)
                : Number(this.editBillItem.qty)
            ),
          })
          .then(() => {
            invoicesRef
              .where("invoice_no", "==", this.editBillItem.invoice_no)
              .get()
              .then((querySnapshot) => {
                querySnapshot.docs[0]?.ref.update({
                  gross_total: Number(grossTotal),
                  discount_amount: Number(this.editItem.discount_amount),
                  net_total: Number(this.editItem.net_total),
                });
              })
              .then(() => {
                // Delete bill item
                invoicesRef
                  .where("invoice_no", "==", this.editBillItem.invoice_no)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.docs[0]?.ref
                      .collection("invoice_bill")
                      .doc(this.editBillItem.bill_id)
                      .delete();
                  });
              })
              .then(async () => {
                // Check Constituent Balance
                await this.checkConstituentBalance().then(() => {
                  this.btnLoading = false;
                  this.billItemDialogAction = false;
                  this.billDialog = false;
                  this.$store.commit("alertMessage/message", [
                    "Data deleted successfully.",
                    "success",
                  ]);
                });
              })
              .catch((e) => {
                this.btnLoading = false;
                this.$store.commit("alertMessage/message", [e, "error"]);
              });
          })
          .catch((e) => {
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [e, "error"]);
          });
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Check Constituent Balance
    async checkConstituentBalance() {
      try {
        await constituentsRef
          .doc(this.editItem.cons_id)
          .get()
          .then(async (snapshot) => {
            let cashCardAmount = 0;
            let chequeAmount = 0;
            let totalOfOnvoicess = 0;
            var chequesDetails = [];
            // get payments amount
            await snapshot.ref
              ?.collection("payments")
              .get()
              .then((querySnapshot) => {
                let totalOfCashRet = 0;
                let totalOfCardRet = 0;
                let totalOfCash = 0;
                let totalOfCard = 0;
                querySnapshot.docs?.forEach((element) => {
                  // Get Return and not return amounts
                  var logic =
                    element?.data().cons_type == "CUS"
                      ? element?.data()["payment_type"] == "Return_Pay" // Identify first process
                      : element?.data()["payment_type"] == "Get_Pay"; // Identify first process // Like return
                  //
                  if (logic) {
                    totalOfCashRet += Number(
                      element.data()["cash_amount"] ?? 0
                    );
                    totalOfCardRet += Number(
                      element.data()["card_amount"] ?? 0
                    );
                  } else {
                    totalOfCash += Number(element.data()["cash_amount"] ?? 0);
                    totalOfCard += Number(element.data()["card_amount"] ?? 0);
                  }

                  // Cal Cheque Amount
                  chequeAmount += Number(
                    !element.data().is_che_return
                      ? element.data().cheque_amount ?? 0
                      : 0
                  );
                  // Find cheque details
                  if (
                    !element.data().is_che_realise &&
                    // !element.data().is_che_return &&
                    Number(element.data().cheque_amount ?? 0) != 0
                  ) {
                    chequesDetails.push(element.data());
                  }
                });
                return (
                  totalOfCard + totalOfCash - (totalOfCardRet + totalOfCashRet)
                ); // Net Cash Amount = totalOfCash - totalOfCashRet
                // Net Card Amount = totalOfCard - totalOfCardRet
              })
              .then((value) => {
                cashCardAmount = value;
              });
            // get invoices net amount
            await snapshot.ref
              .collection("invoices")
              .get()
              .then((querySnapshot) => {
                querySnapshot.docs?.forEach((element) => {
                  totalOfOnvoicess += Number(element.data().net_total ?? 0);
                });
              });

            return [
              cashCardAmount,
              chequeAmount,
              totalOfOnvoicess,
              chequesDetails,
            ];
          })
          .then(async (values) => {
            // Make balance [cashCardAmount + chequeAmount - totalOfOnvoicess],
            this.balance = values[0] + values[1] - values[2];
            // Check have a balance?
            if (this.balance != 0) {
              if (this.balance > 0) {
                this.payReturn = true;
              } else {
                this.payReturn = false;
              }
              // Set payament and invoice amounts to consAmountsDetails
              this.consAmountsDetails = values;
              // Open Payment manage Dialog
              this.payManageDialog = !this.payManageDialog;
            }
          })
          .catch((e) => {
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [e, "error"]);
          });
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Manage Payments
    async managePayments() {
      try {
        // Declare Batch
        var batch = this.$batch.batch();
        // cashCardAmount, chequeAmount, totalOfOnvoicess, cheque details
        new Promise((resolve, reject) => {
          // Generate Positive number
          let balance = Math.abs(this.balance);

          if (
            Number(this.retCashAmount + this.retCardAmount) != 0 ||
            this.retChequNos.length != 0
          ) {
            // var sucMessage = "";
            this.btnLoading = true;
            // Manage Cash + Card payments
            if (Number(this.retCashAmount + this.retCardAmount) != 0) {
              if (
                balance >=
                Number(this.retCashAmount) + Number(this.retCardAmount)
              ) {
                var payID = uuid();
                batch.set(
                  constituentsRef
                    .doc(this.editItem.cons_id)
                    .collection("payments")
                    .doc(payID),
                  {
                    pay_id: payID,
                    invoice_no: this.editItem.invoice_no,
                    cons_type: this.editItem.cons_type,
                    cons_id: this.editItem.cons_id,
                    salesman_id: this.editItem.salesman_id,
                    cash_amount: Number(this.retCashAmount ?? 0),
                    card_amount: Number(this.retCardAmount ?? 0),
                    cheque_amount:
                      this.$firebase.firestore.FieldValue.increment(0), // Set default value or 0
                    create_date: new Date(),
                    payment_type:
                      this.editItem.cons_type == "CUS"
                        ? this.payReturn
                          ? "Return_Pay"
                          : "Get_Pay"
                        : this.payReturn
                        ? "Get_Pay"
                        : "Give_Pay",
                    create_emp_id: this.userData.s_user_id,
                  }
                );

                if (Number(this.retCardAmount ?? 0) > 0) {
                  // Generate trans id
                  var transID = uuid();
                  batch.set(bankMoneyRef.doc(transID), {
                    trans_id: transID,
                    pay_id: payID,
                    trans_type: "Card",
                    note:
                      "Card Payment - " +
                      this.editItem.cons_type +
                      (this.editItem.cons_type == "CUS"
                        ? this.payReturn
                          ? " - Return_Pay"
                          : " - Get_Pay"
                        : this.payReturn
                        ? " - Get_Pay"
                        : " - Give_Pay"),
                    dip_amount:
                      this.editItem.cons_type == "SUP"
                        ? this.payReturn // return
                          ? Number(this.retCardAmount)
                          : 0
                        : this.payReturn // return
                        ? 0
                        : Number(this.retCardAmount),
                    with_amount:
                      this.editItem.cons_type == "SUP"
                        ? !this.payReturn // return back
                          ? Number(this.retCardAmount)
                          : 0
                        : !this.payReturn // return back
                        ? 0
                        : Number(this.retCardAmount),
                    create_emp_id: this.userData.s_user_id,
                    trans_date: new Date(),
                  });
                }
                resolve();
              } else {
                reject("Amount of Cash + Card payments are not valid.");
              }
            }

            // Manage Cheque Payments
            if (this.retChequNos.length != 0) {
              var chNos = [];
              // Filter cheque no
              this.retChequNos.forEach((chNo) => {
                if (this.payReturn) {
                  if (
                    this.consAmountsDetails[3].some(
                      (value) =>
                        value.is_che_return == false && value.che_no == chNo
                    )
                  ) {
                    chNos.push(chNo);
                  }
                } else {
                  if (
                    this.consAmountsDetails[3].some(
                      (value) =>
                        value.is_che_return == true && value.che_no == chNo
                    )
                  ) {
                    chNos.push(chNo);
                  }
                }
              });

              if (chNos.length != 0) {
                // declier temp balancr variable;
                // tempBalance = db balance - entered new payments
                let tempBalance =
                  balance -
                  Number(this.retCashAmount ?? 0 + this.retCardAmount ?? 0);
                chNos.forEach(async (element) => {
                  var chequeData = await this.consAmountsDetails[3].find(
                    (value) => {
                      return value.che_no == element;
                    }
                  );
                  if (chequeData != null)
                    if (tempBalance >= Number(chequeData.cheque_amount)) {
                      // New balance
                      tempBalance -= Number(chequeData.cheque_amount);

                      // Need to check balance >= cheque amount
                      batch.update(
                        constituentsRef
                          .doc(this.editItem.cons_id)
                          .collection("payments")
                          .doc(chequeData.pay_id),
                        {
                          is_che_return: this.payReturn ? true : false,
                          che_return_date: this.payReturn ? new Date() : null,
                          che_ret_emp_id: this.payReturn
                            ? this.userData.s_user_id
                            : null,
                        }
                      );

                      if (this.payReturn) {
                        await bankMoneyRef
                          .where("che_no", "==", chequeData.che_no)
                          .get()
                          .then((querySnapshot) => {
                            if (querySnapshot?.docs?.length != 0) {
                              batch.delete(querySnapshot.docs[0].ref);
                            }
                          });
                      }
                      resolve();
                    } else {
                      reject(`${chequeData.che_no} amount is not valid.`);
                    }
                });
              } else {
                reject("Selected Cheque(s) are not valid.");
              }
            }
          } else {
            reject(
              "Invalid return amount of Cash + Card payments and not selected any cheque for the return process."
            );
          }
        })
          .then(() => {
            batch
              .commit()
              .then(() => {
                this.$store.commit("alertMessage/message", [
                  "Payment(s) updated successfully.",
                  "success",
                ]);
                this.btnLoading = false;
                this.editItem = {};
                this.payManageDialog = !this.payManageDialog;
              })
              .catch((message) => {
                this.btnLoading = false;
                this.$store.commit("alertMessage/message", [message, "error"]);
              });
          })
          .catch((message) => {
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [message, "error"]);
          });
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Filters
    filterData() {
      try {
        var tempList = this.BodyDataBack;
        // Filter unit 01
        if (this.invoiceNo != null && this.invoiceNo != "") {
          tempList = tempList.filter(
            (value) => value.invoice_no == this.invoiceNo
          );
        }
        // Filter unit 02
        if (this.smanID != null && this.smanID != "") {
          tempList = tempList.filter(
            (value) => value.salesman_id == this.smanID
          );
        }
        // Filter unit 03
        if (this.empID != null && this.empID != "") {
          tempList = tempList.filter(
            (value) => value.create_emp_id == this.empID
          );
        }
        // Filter unit 02
        if (this.withDateRange) {
          if (this.pickDateRange.length != 2) {
            this.$store.commit("alertMessage/message", [
              "Please select the date range.",
              "warning",
            ]);
          } else {
            tempList = tempList.filter((value) => {
              var date01 = new Date(this.dateTimeFormater(value?.create_date));
              var date02 = new Date(this.pickDateRange[0]);
              var date03 = new Date(this.pickDateRange[1]);
              //
              return date01 >= date02 && date01 <= date03;
            });
          }
        }
        this.BodyData = tempList ?? [];
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.invoiceNo = null;
        this.smanID = null;
        this.empID = null;
        this.withDateRange = false;
        this.pickDateRange = [];
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
</style>