<template>
  <div>
    <app-bar-compo title="Expenses - Types" :filter="false">
      <template v-slot:actions>
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>
    <!-- Body -->
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :loading="isTableLoading"
        :search="searchTxt"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('edit', item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
      <!-- <v-fab-transition> -->
      <v-btn
        @click="dialogAction()"
        color="deep-orange accent-3"
        dark
        fixed
        bottom
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- </v-fab-transition> -->
    </v-container>
    <!--  -->
    <!-- Add/ Edit/ Delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dialogType == 'a'">
                <v-text-field
                  dense
                  v-model="editItem.exp_type_id"
                  label="Type ID"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.exp_type_name"
                  label="Type Name"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editItem.statement"
                  label="Statement"
                  :items="['Production_Statement', 'Financial_Statement']"
                  hide-selected
                  outlined
                  clearable
                  required
                >
                </v-combobox>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.note"
                  label="Note"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  no-resize
                  clearable
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(editItem.exp_type_id)
                : deleteData(editItem.exp_type_id)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
var expTypesRef;

export default {
  name: "exp_types_screen",
  mounted() {
    expTypesRef = this.$fireStore.collection("expenses_types");
    this.initData();
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      // Buttons
      btnLoading: false,
      //   Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      headers: [
        {
          text: "Type ID",
          align: "start",
          value: "exp_type_id",
          width: 200,
        },
        {
          text: "Type Name",
          align: "start",
          value: "exp_type_name",
          width: 200,
        },
        {
          text: "Statement",
          align: "start",
          value: "statement",
          width: 200,
        },

        {
          text: "Note",
          value: "note",
          width: 300,
          class: "pad-class",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: 50,
        },
      ],
      BodyData: [],
      // BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await expTypesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            snapshots.docs.forEach((element) => {
              this.BodyData.push(element.data());
              this.BodyDataBack.push(element.data());
            });
            this.isTableLoading = false;
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },

    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item) {
      this.editItem = {};
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        // set data to dclired variables
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.exp_type_id = item.exp_type_id;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },
    // Operating with DB
    addData() {
      try {
        if (this.editItem?.exp_type_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Expense Type ID.",
            "error",
          ]);
        } else if (this.editItem?.exp_type_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Expense Type name.",
            "error",
          ]);
        } else if (!this.editItem?.statement == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Statement.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          this.editItem.exp_type_id =
            "EXPT" + this.editItem.exp_type_id.replace("EXPT", "");
          expTypesRef
            .doc(this.editItem.exp_type_id)
            .set({ ...this.editItem })
            .then(() => {
              this.editItem = {};
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data added successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    editData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Expense Type ID is not valid.",
            "error",
          ]);
        } else if (this.editItem?.exp_type_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Expense Type name.",
            "error",
          ]);
        } else if (!this.editItem?.statement == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Statement.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          expTypesRef
            .doc(id)
            .update({
              ...this.editItem,
            })
            .then(() => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data updated successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Expense Type ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          expTypesRef
            .doc(id)
            .delete()
            .then(() => {
              this.btnLoading = false;
              this.dialog = false;
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
</style>