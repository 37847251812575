<template>
  <nav>
    <!-- App Bar -->
    <v-app-bar dense app fixed elevation="1" class="appBar">
      <v-app-bar-nav-icon @click="navigate"
        ><v-icon class="appBar-icon">mdi-arrow-left</v-icon></v-app-bar-nav-icon
      >
      <h4>{{ title }}</h4>
      <v-spacer></v-spacer>

      <v-dialog v-if="filter" scrollable v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on"
            ><v-icon class="appBar-icon">mdi-filter</v-icon></v-btn
          >
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Filters</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <slot name="filters"> </slot>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button-cancel" text @click="onClear"> Clear </v-btn>
            <v-btn class="button" text @click="onFilter"> Filter </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <slot name="actions"> </slot>
    </v-app-bar>
    <!-- Drawer -->
    <v-navigation-drawer app permanent :mini-variant.sync="mini">
      <v-list-item class="px-2">
        <v-list-item-avatar class="indigo darken-1">
          <span class="white--text text-h7">SV</span>
        </v-list-item-avatar>

        <v-list-item-title
          >{{ userName }}
          <br />
          <span class="text-subtitle fsmall">{{
            userData.s_using_branch
          }}</span></v-list-item-title
        >
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item-group mandatory active-class="primary">
          <div v-for="item in items" :key="item.title" link>
            <v-list-item v-slot="{ active }" route :to="item.route">
              <v-list-item-icon>
                <v-icon :class="active ? 'white--text' : 'appBar-icon'">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  :class="active ? 'white--text' : 'appBar-icon'"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- / -->
            <!-- <v-list-group v-else no-action>
              <v-list-item
                class="ma-0 pa-0"
                active-class="primary"
                v-slot="{ active }"
              >
                <template slot="activator">
                  <v-list-item-icon>
                    <v-icon :class="active ? 'white--text' : 'appBar-icon'">{{
                      item.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="active ? 'white--text' : 'appBar-icon'"
                      v-text="item.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="child in item.sub_items"
                  :key="child.title"
                  v-slot="{ active }"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      :class="active ? 'white--text' : 'appBar-icon'"
                      >{{ child.title }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item>
            </v-list-group> -->
          </div>
        </v-list-item-group>
      </v-list>
      <div class="about-btn">
        <v-btn
          icon
          @click="aboutDialog = !aboutDialog"
          class="red darken-4 white--text"
        >
          <v-icon>mdi-information-variant</v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>
    <!-- About Dialog -->
    <v-dialog v-model="aboutDialog" max-width="600px">
      <AboutCard />
    </v-dialog>
  </nav>
</template>

<script>
import AboutCard from "@/components/about_card.vue";

export default {
  name: "app_bar_compo",
  components: {
    AboutCard,
  },
  props: {
    title: {
      default: "",
      type: String,
    },
    filter: {
      default: true,
      type: Boolean,
    },
  },
  created() {
    // Request to find/identify the system user data
    this.$store.commit("systemUser/findUserData");
  },
  computed: {
    userName() {
      return (
        this.$store.getters["systemUser/userData"]?.s_user_name ?? "Selvans"
      );
    },
  },
  data() {
    return {
      aboutDialog: false,
      dialog: false,
      mini: true,
      items: [
        { title: "Home", icon: "mdi-home-city", route: "/" },
        {
          title: "Constituents",
          icon: "mdi-briefcase-account",
          route: "/constituents",
        },
        {
          title: "Items Stock",
          icon: "mdi-store",
          route: "/items_stock",
        },
        {
          title: "Employees",
          icon: "mdi-card-account-details",
          route: "/employees",
        },
        { title: "Expenses", icon: "mdi-cash", route: "/expenses" },
        { title: "Branches", icon: "mdi-source-branch", route: "/branches" },
        { title: "Bank/Money Account", icon: "mdi-bank", route: "/bank_money" },
        // { title: "Others", icon: "mdi-cube", route: "" },
        { title: "Reports", icon: "mdi-chart-areaspline", route: "/reports" },
      ],
    };
  },
  methods: {
    onClear() {
      this.dialog = false;
      this.$emit("onClear");
    },
    onFilter() {
      this.$emit("onFilter");
    },
    navigate() {
      try {
        this.$router.go(-1);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.about-btn {
  position: fixed;
  bottom: 10px;
  left: 10px;
}
</style>