import Vue from "vue";
import Vuex from "vuex";
import alertMessage from "./alertState.js";
import systemUser from "./systemUserState.js";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alertMessage,
    systemUser,
  },
});
