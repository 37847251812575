import firebase from "firebase";
// const firebaseConfig = {
//     apiKey: "AIzaSyAARbUCylB5HXKJNkfkVoZ7VAwK_DjHDMY",
//     authDomain: "apec-lanka-vehicles-2f741.firebaseapp.com",
//     projectId: "apec-lanka-vehicles-2f741",
//     storageBucket: "apec-lanka-vehicles-2f741.appspot.com",
//     messagingSenderId: "871641286040",
//     appId: "1:871641286040:web:4c01938c7229a6eb6197ed",
//     measurementId: "G-0MYBRV4W5L"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAETGcDiXd6wmUqRyWXps8_B0hDjQ3mwoc",
  authDomain: "apec-pos-online-59acc.firebaseapp.com",
  projectId: "apec-pos-online-59acc",
  storageBucket: "apec-pos-online-59acc.appspot.com",
  messagingSenderId: "323285452970",
  appId: "1:323285452970:web:bb7c64d7a621212860a8d3",
  measurementId: "G-S371JC9NXT",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

firebaseApp.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});
firebaseApp
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch((err) => {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

firebase.analytics();
const fireStore = firebaseApp.firestore();
const storage = firebaseApp.storage();
const auth = firebase.auth();
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const messaging = firebaseApp.messaging();

// messaging
//   .requestPermission()
//   .then(function () {
//     // console.log("Notification permission is done.");
//     // return messaging.getToken();
//   })
//   .catch(function () {
//     // console.log("Reason : " + reason);
//   });
export { fireStore, storage, messaging, firebase, auth, googleAuthProvider };
