export default {
  namespaced: true,
  state: {
    isAlertShow: false,
    alert_type: null,
    message: null,
  },
  getters: {
    isAlertShow: (state) => {
      return state.isAlertShow;
    },
    alert_type: (state) => {
      return state.alert_type;
    },
    msg: (state) => {
      return state.message;
    },
  },
  mutations: {
    message(state, payload) {
      state.isAlertShow = true;
      state.message = payload[0];
      state.alert_type = payload[1];
      setTimeout(() => {
        state.isAlertShow = false;
        state.message = null;
        state.alert_type = null;
      }, 5000);
    },
  },
  actions: {},
  modules: {},
};
