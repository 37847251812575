<template>
  <div class="txt-srch mb-1">
    <v-text-field
      @input="searchData"
      append-icon="mdi-magnify"
      label="Search"
      solo
      dense
      clearable
      append-inner-icon="mdi-map-marker"
    ></v-text-field>
    <!-- <v-autocomplete
      :items="items"
      @input="searchedData"
      append-icon="mdi-magnify"
      label="Search"
      solo
      hide-selected
      auto-select-first
      chips
      dense
      filled
      clearable
    ></v-autocomplete> -->
  </div>
</template>

<script>
export default {
  name: "search_txt_compo",
  props: ["searchData"],
};
</script> 

<style lang="scss">
.txt-srch.mb-1 {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none !important;
  }
}

</style>