<template>
  <v-row justify="space-between" class="pa-3">
    <h3 :class="color_class">{{ title }}</h3>
    <h3 class="ml-2" :class="color_class">{{ value.toFixed(2) }}</h3>
  </v-row>
</template>

<script>
export default {
  name: "summary_compo",
  props: ["title", "value", "color_class"],
};
</script>