export default {
  computed: {
    userData() {
      return this.$store.getters["systemUser/userData"];
    },
    userMP() {
      return this.$store.getters["systemUser/userMP"];
    },
  },
};
