<template>
  <div>
    <app-bar-compo
      title="Reports"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <template v-slot:filters>
        <span
          >* Filtering all of
          <span class="red--text">Suppliers</span> Transactions details
          <span class="red--text">by Branch ID and Date Range</span>.</span
        ><br />
        <span
          >* Filtering all of
          <span class="red--text">Customers</span> Transactions details
          <span class="red--text"
            >by Customer Type, Branch ID, and Date Range</span
          >.</span
        >
        <v-row class="mt-2">
          <v-col cols="12" sm="12" md="6" lg="6">
            <h3>Customer Type</h3>
            <v-select
              class="mt-2"
              dense
              v-model="consType"
              :items="['Def', 'Any']"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <h3>Branch ID</h3>
            <v-select
              class="mt-2"
              dense
              :loading="BranchIDsLoading"
              v-model="branchId"
              :items="BranchIDs"
              hide-selected
              outlined
              clearable
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-5 mt-2">
          <v-col>
            <v-row class="pa-0"
              ><v-checkbox
                class="ma-0 pa-0 mr-3"
                v-model="withDateRange"
                label="with"
              ></v-checkbox>
              <h3>Date Range</h3></v-row
            >
            <v-date-picker
              v-if="withDateRange"
              v-model="pickDateRange"
              range
              full-width
              header-color="blue"
              color="green"
              class="date-Picker mt-4"
            ></v-date-picker>
          </v-col>
        </v-row>
      </template>
    </app-bar-compo>
    <v-container v-if="!loading" fluid class="st-container">
      <div>
        <v-row class="ma-0 mt-2 ml-2">
          <v-btn text @click="navigate('/reports/invoices')"
            >Invoices Details</v-btn
          >
          <v-btn text @click="navigate('/reports/payments')"
            >Payments Details</v-btn
          >
          <v-btn text @click="navigate('/reports/items')">Items Details</v-btn>
          <!-- <v-btn text class="without-border-btn" @click="null"
            >Balance Sheet</v-btn
          >
          <v-btn class="without-border-btn" @click="null"
            >Production Statement</v-btn
          >
          <v-btn class="without-border-btn" @click="null"
            >Financial Statement</v-btn
          > -->
        </v-row>
      </div>
      <v-container fluid class="mt-4 text-headline fbig">
        <h4>Summary Of Transactions</h4>
        <span>{{ stDate + " - " + ltDate }}</span>
      </v-container>
      <!-- Sales & Purchase -->
      <v-row class="ma-0 mt-2">
        <v-col cols="12" sm="12" md="4">
          <v-card class="report-card">
            <v-row
              justify="space-between"
              class="deep-orange darken-3 white--text pa-2 ma-0 mb-1"
              ><v-card-title>Sales</v-card-title
              ><v-card-title
                >Rs.{{ sales.toLocaleString() }}</v-card-title
              ></v-row
            >

            <repCardBody
              v-for="item in salesBody"
              :key="item.title"
              :styleClass="item.styleClass"
              :title="item.title"
              :value="item.value"
            />
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card class="report-card">
            <v-row
              justify="space-between"
              class="light-blue darken-4 white--text pa-2 ma-0 mb-1"
              ><v-card-title>Purchase</v-card-title
              ><v-card-title
                >Rs.{{ purchase.toLocaleString() }}</v-card-title
              ></v-row
            >

            <repCardBody
              v-for="item in purchaseBody"
              :key="item.title"
              :styleClass="item.styleClass"
              :title="item.title"
              :value="item.value"
            />
          </v-card>
        </v-col>
      </v-row>
      <!-- <br /> -->
      <!-- <hr /> -->
      <!-- <br /> -->
      <!-- Items Production/ Personal/ Damaged -->
      <!-- <v-row class="ma-0"> Items </v-row> -->
      <br />
      <hr />
      <br />
      <!-- Expenses -->
      <v-row class="ma-0">
        <v-col>
          <v-card class="report-card">
            <v-row
              justify="space-between"
              class="purple darken-4 white--text pa-2 ma-0 mb-1"
              ><v-card-title>Production Expenses</v-card-title
              ><v-card-title
                >Rs.{{ expensesProduction.toLocaleString() }}</v-card-title
              ></v-row
            >
          </v-card>
        </v-col>
        <v-col>
          <v-card class="report-card">
            <v-row
              justify="space-between"
              class="pink darken-4 white--text pa-2 ma-0 mb-1"
              ><v-card-title>Financial Expenses</v-card-title
              ><v-card-title
                >Rs.{{ expensesFinancial.toLocaleString() }}</v-card-title
              ></v-row
            >
          </v-card>
        </v-col>
        <v-col>
          <v-card class="report-card">
            <v-row
              justify="space-between"
              class="cyan darken-3 white--text pa-2 ma-0 mb-1"
              ><v-card-title>Transport Expenses</v-card-title
              ><v-card-title
                >Rs.{{ expensesTransport.toLocaleString() }}</v-card-title
              ></v-row
            >
          </v-card>
        </v-col>
        <v-col>
          <v-card class="report-card">
            <v-row
              justify="space-between"
              class="grey darken-2 white--text pa-2 ma-0 mb-1"
              ><v-card-title>Unknown Expenses</v-card-title
              ><v-card-title
                >Rs.{{ expensesUnknown.toLocaleString() }}</v-card-title
              ></v-row
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div v-else style="min-height: 4px">
      <v-progress-linear :show="loading" indeterminate></v-progress-linear>
    </div>
  </div>
</template>

<script>
import repCardBody from "@/components/reports/report_card_body.vue";

var paymentsRef;
var invoicesRef;
// var takenRef;
// var damagedRef;
var expensesRef;
var branchesRef;

export default {
  name: "reports_home_screen",
  mounted() {
    paymentsRef = this.$fireStore.collectionGroup("payments");
    invoicesRef = this.$fireStore.collectionGroup("invoices");
    branchesRef = this.$fireStore.collection("branches");
    // damagedRef = this.$fireStore.collectionGroup("damaged");
    // takenRef = this.$fireStore.collectionGroup("taken");
    expensesRef = this.$fireStore.collection("expenses");
    this.initData();
    this.loadBranchIDs();
  },
  components: {
    repCardBody,
  },
  data() {
    return {
      // Loading
      loading: false,
      // Report Card
      salesBody: [],
      sales: 0,
      purchaseBody: [],
      purchase: 0,
      expensesProduction: 0,
      expensesFinancial: 0,
      expensesTransport: 0,
      expensesUnknown: 0,
      // Filter
      stDate: "",
      ltDate: "",
      //
      BranchIDsLoading: false,
      BranchIDs: [],
      branchId: null,
      withDateRange: false,
      pickDateRange: [],
      consType: "Def",
    };
  },
  methods: {
    async initData() {
      // Set Default Dates
      var date = new Date();
      var stDateMonthChange = date.setMonth(date.getMonth() - 1);
      this.stDate = new Date(
        stDateMonthChange - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.ltDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      // Set First Date
      this.pickDateRange.push(this.stDate);
      // Set Second Date
      this.pickDateRange.push(this.ltDate);
      // Calling initial functions
      // Sales
      this.salesPurchaseDetailsLoad(
        "sales",
        invoicesRef
          .where("cons_type", "==", "CUS")
          .where(
            "create_date",
            ">=",
            new Date(this.pickDateRange[0] + " 00:00:00")
          )
          .where(
            "create_date",
            "<=",
            new Date(this.pickDateRange[1] + " 23:59:59")
          ),
        paymentsRef
          .where("cons_type", "==", "CUS")
          .where(
            "create_date",
            ">=",
            new Date(this.pickDateRange[0] + " 00:00:00")
          )
          .where(
            "create_date",
            "<=",
            new Date(this.pickDateRange[1] + " 23:59:59")
          )
      );
      // Purchase
      this.salesPurchaseDetailsLoad(
        "",
        invoicesRef
          .where("cons_type", "==", "SUP")
          .where(
            "create_date",
            ">=",
            new Date(this.pickDateRange[0] + " 00:00:00")
          )
          .where(
            "create_date",
            "<=",
            new Date(this.pickDateRange[1] + " 23:59:59")
          ),
        paymentsRef
          .where("cons_type", "==", "SUP")
          .where(
            "create_date",
            ">=",
            new Date(this.pickDateRange[0] + " 00:00:00")
          )
          .where(
            "create_date",
            "<=",
            new Date(this.pickDateRange[1] + " 23:59:59")
          )
      );
      this.expensesLoad();
    },
    async salesPurchaseDetailsLoad(type, refInvoice, refPayments) {
      try {
        this.loading = true;

        var body = [];

        this.sales = 0;
        this.purchase = 0;

        let returnAmount = 0;
        let payCashAmount = 0;
        let payCardAmount = 0;
        let payChequeAmount = 0;
        let totalOfPayment = 0;
        let balanceCredit = 0;

        //
        let totalOfCash = 0;
        let totalOfCard = 0;
        // Ret Payments
        let totalOfCashRet = 0;
        let totalOfCardRet = 0;

        new Promise((resolve, reject) => {
          refInvoice
            .get()
            .then((querySnapshot) => {
              querySnapshot.docs?.forEach((el) => {
                if (type == "sales")
                  this.sales += Number(el.data().net_total ?? 0);
                else this.purchase += Number(el.data().net_total ?? 0);
                returnAmount += Number(el.data().return_amount ?? 0);
              });
            })
            .then(() => {
              refPayments
                .get()
                .then((querySnapshot) => {
                  querySnapshot.docs?.forEach((el) => {
                    var logic =
                      type == "sales"
                        ? el?.data()["payment_type"] == "Return_Pay" // Identify first process
                        : el?.data()["payment_type"] == "Get_Pay"; // Identify first process // Like return
                    //
                    if (logic) {
                      totalOfCashRet += Number(el.data()["cash_amount"] ?? 0);
                      totalOfCardRet += Number(el.data()["card_amount"] ?? 0);
                    } else {
                      totalOfCash += Number(el.data()["cash_amount"] ?? 0);
                      totalOfCard += Number(el.data()["card_amount"] ?? 0);
                    }

                    payChequeAmount += Number(
                      el.data().is_che_return ? 0 : el.data().cheque_amount ?? 0
                    );
                  });
                })
                .then(() => {
                  payCashAmount = totalOfCash - totalOfCashRet;
                  payCardAmount = totalOfCard - totalOfCardRet;
                  // Total
                  totalOfPayment =
                    payCashAmount + payCardAmount + payChequeAmount;
                  resolve();
                })
                .catch((e) => {
                  reject(e);
                });
            })
            .catch((e) => {
              reject(e);
            });
        })
          .then(() => {
            if (type == "sales") balanceCredit = this.sales - totalOfPayment;
            else balanceCredit = this.purchase - totalOfPayment;
            // Set data to array
            body.push(
              // { title: "Sales", value: "" },
              { title: "Items Return Amount (View)", value: returnAmount },
              {
                title: "Total of Payments",
                value: totalOfPayment,
                styleClass: "blue--text",
              },
              { title: "Cash Payments", value: payCashAmount },
              { title: "Card Payments", value: payCardAmount },
              { title: "Cheque Payments", value: payChequeAmount },
              {
                title: "Balance",
                value: balanceCredit,
                styleClass: "red--text pb-5",
              }
            );
            // Set to list
            if (type == "sales") {
              this.salesBody = body;
            } else {
              this.purchaseBody = body;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$store.commit("alertMessage/message", [error, "error"]);
          });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async expensesLoad() {
      // 'Production_Statement', 'Financial_Statement'
      try {
        this.loading = true;

        // Default value setted
        this.expensesProduction = 0;
        this.expensesFinancial = 0;
        this.expensesTransport = 0;
        this.expensesUnknown = 0;

        // Calculating and identify expences
        expensesRef
          .where(
            "create_date",
            ">=",
            new Date(this.pickDateRange[0] + " 00:00:00")
          )
          .where(
            "create_date",
            "<=",
            new Date(this.pickDateRange[1] + " 23:59:59")
          )
          .get()
          .then((querySnapshot) => {
            querySnapshot?.docs?.forEach((element) => {
              if (element.data()["exp_type_id"] == "TRANS")
                this.expensesTransport += Number(element.data()["amount"] ?? 0);
              else if (element.data()["statement"] == "Production_Statement")
                this.expensesProduction += Number(
                  element.data()["amount"] ?? 0
                );
              else if (element.data()["statement"] == "Financial_Statement")
                this.expensesFinancial += Number(element.data()["amount"] ?? 0);
              else
                this.expensesUnknown += Number(element.data()["amount"] ?? 0);
            });
          })
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("alertMessage/message", [error, "error"]);
          });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async loadBranchIDs() {
      try {
        this.BranchIDsLoading = true;
        // Branches IDs Loading
        await branchesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            snapshots.docs.forEach((element) => {
              this.BranchIDs.push(element.data()["br_id"]);
            });
            this.BranchIDsLoading = false;
          }
        );
      } catch (error) {
        this.BranchIDsLoading = false;
        console.log(error);
      }
    },
    navigate(path) {
      try {
        this.$router.push(path).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    // Filter
    async filterData() {
      try {
        var invoSale = this.$fireStore.collectionGroup("invoices");
        var invoPur = this.$fireStore.collectionGroup("invoices");
        var paySale = this.$fireStore.collectionGroup("payments");
        var payPur = this.$fireStore.collectionGroup("payments");

        // this.stDate = null;
        // this.ltDate = null;

        // Filter unit 01
        if (this.consType == "Any") {
          // Invoices
          invoSale = invoSale.where("cons_type", "==", "CUS");
          invoPur = invoPur.where("cons_type", "==", "SUP");
          // Payments
          paySale = paySale.where("cons_type", "==", "CUS");
          payPur = payPur.where("cons_type", "==", "SUP");
        } else {
          // Invoices
          invoSale = invoSale.where("cons_id", "==", "Def");
          invoPur = invoPur.where("cons_type", "==", "SUP");
          // Payments
          paySale = paySale.where("cons_id", "==", "Def");
          payPur = payPur.where("cons_type", "==", "SUP");
        }

        // Filter unit 02
        if (this.branchId != null && this.branchId != "") {
          // Invoices
          invoSale = invoSale.where("br_id", "==", this.branchId);
          invoPur = invoPur.where("br_id", "==", this.branchId);
          // Payments
          paySale = paySale.where("br_id", "==", this.branchId);
          payPur = payPur.where("br_id", "==", this.branchId);
        }

        // // // Filter unit 03
        if (this.withDateRange) {
          if (this.pickDateRange.length != 2) {
            this.$store.commit("alertMessage/message", [
              "Please select the date range.",
              "warning",
            ]);
          } else {
            this.stDate = this.pickDateRange[0];
            this.ltDate = this.pickDateRange[1];

            // Invoices
            invoSale = invoSale
              .where(
                "create_date",
                ">=",
                new Date(this.pickDateRange[0] + " 00:00:00")
              )
              .where(
                "create_date",
                "<=",
                new Date(this.pickDateRange[1] + " 23:59:59")
              );
            invoPur = invoPur
              .where("cons_type", "==", "SUP")
              .where(
                "create_date",
                ">=",
                new Date(this.pickDateRange[0] + " 00:00:00")
              )
              .where(
                "create_date",
                "<=",
                new Date(this.pickDateRange[1] + " 23:59:59")
              );
            // Payments
            paySale = paySale
              .where(
                "create_date",
                ">=",
                new Date(this.pickDateRange[0] + " 00:00:00")
              )
              .where(
                "create_date",
                "<=",
                new Date(this.pickDateRange[1] + " 23:59:59")
              );
            payPur = payPur
              .where("cons_type", "==", "SUP")
              .where(
                "create_date",
                ">=",
                new Date(this.pickDateRange[0] + " 00:00:00")
              )
              .where(
                "create_date",
                "<=",
                new Date(this.pickDateRange[1] + " 23:59:59")
              );
          }
        }
        // Sales
        this.salesPurchaseDetailsLoad("sales", invoSale, paySale);
        // Purchase
        this.salesPurchaseDetailsLoad("", invoPur, payPur);
        this.expensesLoad();
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.consType = "Any";
        this.withDateRange = false;
        this.pickDateRange = [];
        this.initData();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.report-card {
  & .v-card__text {
    & span {
      font-size: 17px !important;
    }
  }
}
</style>