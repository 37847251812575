<template>
  <div>
    <app-bar-compo
      title="Contituents"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <!-- Filters -->
      <template v-slot:filters>
        <h3>User Type</h3>
        <v-switch
          inset
          class="mr-3 mt-3"
          :label="!isSupplier ? 'Customer' : 'Supplier'"
          v-model="isSupplier"
          dense
        ></v-switch>
        <br />
        <v-row>
          <v-col>
            <v-row class="pa-2"
              ><v-checkbox
                class="ma-0 pa-0 mr-3"
                v-model="withDateRange"
                label="with"
              ></v-checkbox>
              <h3>Date Range</h3></v-row
            >
            <div v-if="withDateRange">
              <v-switch
                inset
                class="mr-3 mt-3"
                :label="!isFireDate ? 'Register Date' : 'Fire Date'"
                v-model="isFireDate"
                dense
              ></v-switch>
              <!-- <br /> -->
              <v-date-picker
                v-model="pickDateRange"
                range
                full-width
                header-color="blue"
                color="green"
                class="date-Picker mt-3"
              ></v-date-picker>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>
    <!-- Body -->
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :search="searchTxt"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :loading="isTableLoading"
        hide-default-footer
        class="elevation-1 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.reg_date="{ item }">
          <!-- <span>{{item.reg_date}}</span> -->
          <span>{{ dateTimeFormater(item.reg_date) }}</span>
        </template>
        <template v-slot:item.fire_date="{ item }">
          <!-- <span>{{item.reg_date}}</span> -->
          <span>{{ dateTimeFormater(item.fire_date) }}</span>
        </template>
        <template v-slot:item.summary="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="green darken-1"
                v-bind="attrs"
                v-on="on"
                @click="viewSummary(item.cons_id, item.cons_type)"
              >
                <v-icon>mdi-chart-timeline</v-icon>
              </v-btn>
            </template>
            <span>Summary</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip v-if="item.cons_id != 'Def'" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('edit', item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip v-if="item.cons_id != 'Def'" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="indigo darken-2"
                      class="ma-1"
                      @click="navigate('/constituents/invoices/', item)"
                    >
                      <v-icon>mdi-receipt</v-icon>
                    </v-btn>
                  </template>
                  <span>Invoices</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="pink darken-2"
                      class="ma-1"
                      @click="navigate('/constituents/payments/', item)"
                    >
                      <v-icon>mdi-cash</v-icon>
                    </v-btn>
                  </template>
                  <span>Payments</span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
      <!-- <v-fab-transition> -->
      <v-btn
        @click="dialogAction()"
        color="deep-orange accent-3"
        dark
        fixed
        bottom
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- </v-fab-transition> -->
    </v-container>
    <!--  -->
    <!-- Add/ Edit/ Delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8" lg="8" v-if="dialogType == 'a'">
                <v-text-field
                  dense
                  v-model="editItem.cons_id"
                  label="ID"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-text-field
                  dense
                  v-model="editItem.cons_name"
                  label="Name"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  dense
                  v-model="editItem.cons_type"
                  label="Type"
                  :items="['CUS', 'SUP']"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.address"
                  label="Address"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  clearable
                  no-resize
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.area"
                  label="Area"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.city"
                  label="City"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.contact_no"
                  label="Contact No"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="editItem.acc_status"
                  label="Account Status"
                  :items="['Active', 'Deactive']"
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  v-model="regDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="regDate"
                      label="Register Date"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="regDate"
                    @input="regDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  v-model="fireDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fireDate"
                      label="Fire Date"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fireDate"
                    @input="fireDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.note"
                  label="Note"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  no-resize
                  clearable
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            dark
            :disabled="btnLoading"
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(editItem.cons_id)
                : deleteData(editItem.cons_id)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Summary Dialog -->
    <v-dialog v-model="summaryDialog" scrollable max-width="450px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Summary of {{ summConsID }}</span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="summLoading">
            Calculating...
            <v-progress-linear
              indeterminate
              color="blue"
              class="mb-0"
            ></v-progress-linear>
          </v-container>
          <v-container v-else>
            <br />
            <v-row
              justify="space-between"
              class="pa-3 text-decoration-underline black--text"
            >
              <h3>Description</h3>
              <h3 class="ml-2">Rs.</h3>
            </v-row>
            <sumcompo
              title="The Whole Transaction"
              :value="wholeTransaction"
              color_class="indigo--text"
            />
            <sumcompo
              title="Items Return Amount (View)"
              :value="returnAmount"
              color_class="grey--text"
            />
            <br />
            <!-- <v-divider></v-divider> -->
            <hr />
            <br />
            <sumcompo
              title="Total Of Payments (-)"
              :value="totalOfPayment"
              color_class="indigo--text"
            />
            <sumcompo
              title="Cash Payments"
              :value="payCashAmount"
              color_class="grey--text"
            />
            <sumcompo
              title="Card Payments"
              :value="payCardAmount"
              color_class="grey--text"
            />
            <sumcompo
              title="Cheque Payments"
              :value="payChequeAmount"
              color_class="grey--text"
            />
            <br />
            <!-- <v-divider></v-divider> -->
            <hr />
            <br />
            <sumcompo
              title="Balance / Credit"
              :value="balanceCredit"
              color_class="red--text"
            />
            <br />
            <!-- <v-divider class="pb-1"></v-divider> -->
            <!-- <v-divider></v-divider> -->
            <hr class="pb-1" />
            <hr />
            <br />
            <span v-if="balanceCredit != 0" class="fmedium"
              >You have to
              <span class="green--text fmedium">{{
                summConsType == "CUS"
                  ? balanceCredit > 0
                    ? "GET Payment from"
                    : "GIVE Payment to"
                  : balanceCredit > 0
                  ? "GIVE Payment to"
                  : "GET Payment from"
              }}</span>
              the constituent.</span
            >
            <br v-if="balanceCredit != 0" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="button-cancel"
            text
            @click="summaryDialog = !summaryDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sumcompo from "@/components/constituents/summary_data";
var constituentsRef;

export default {
  name: "constituents_screen",
  mounted() {
    constituentsRef = this.$fireStore.collection("constituents");
    this.initData();
  },
  components: {
    sumcompo,
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      regDateMenu: false,
      regDate: null,
      fireDateMenu: false,
      fireDate: null,
      // Summary
      summaryDialog: false,
      summLoading: false,
      summConsID: null,
      summConsType: null,
      wholeTransaction: 0,
      returnAmount: 0,
      payCashAmount: 0,
      payCardAmount: 0,
      payChequeAmount: 0,
      totalOfPayment: 0,
      balanceCredit: 0,
      // Buttons
      btnLoading: false,
      //   Filters
      pickDateRange: [],
      isSupplier: false,
      isFireDate: false,
      withDateRange: false,
      //   Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: null,
      itemsPerPage: 10,
      headers: [
        {
          text: "ID",
          align: "start",
          //   sortable: false,
          value: "cons_id",
        },
        {
          text: "Name",
          align: "start",
          width: 150,
          //   sortable: false,
          value: "cons_name",
        },
        { text: "Type", value: "cons_type" },
        { text: "Address", value: "address", sortable: false },
        { text: "City", value: "city", sortable: false },
        { text: "Area", value: "area", sortable: false },
        { text: "Contact No", value: "contact_no", sortable: false },
        {
          text: "Status",
          width: 100,
          value: "acc_status",
          align: "center",
        },
        {
          text: "Note",
          value: "note",
          width: 300,
          class: "pad-class",
          sortable: false,
        },
        { text: "Register Date", width: 130, value: "reg_date" },
        { text: "Fire Date", width: 130, value: "fire_date" },
        { text: "Summary", align: "center", value: "summary" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await constituentsRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            snapshots.docs.forEach((element) => {
              this.BodyData.push(element.data());
              this.BodyDataBack.push(element.data());
            });
            this.isTableLoading = false;
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item) {
      this.editItem = {};
      this.regDate = null;
      this.fireDate = null;
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        // set data to dclired variables
        this.regDate = this.dateTimeFormater(this.editItem?.reg_date);
        this.fireDate = this.dateTimeFormater(this.editItem?.fire_date);
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.cons_id = item.cons_id;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },
    navigate(path, item) {
      try {
        this.$router
          .push({
            path: path,
            query: { id: item.cons_id, cons_type: item.cons_type },
            // params: { cons_type: item.cons_type },// Working with nameRoute
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    printData() {
      const doc = this.$jspdf;
      try {
        // doc.table(0, 0, this.BodyData, [
        //   "cons_name",
        //   "cons_type",
        //   "address",
        //   "area",
        //   "city",
        //   "contact_no",
        //   "acc_status",
        //   "note",
        //   "reg_date",
        //   "fire_date",
        // ]);
        var columns = [
          { title: "ID", dataKey: "cons_id" },
          { title: "Name", dataKey: "cons_name" },
          { title: "Type", dataKey: "cons_type" },
          { title: "Address", dataKey: "address" },
          { title: "City", dataKey: "city" },
          { title: "Area", dataKey: "area" },
          { title: "Contact No", dataKey: "contact_no" },
          { title: "Status", dataKey: "acc_status" },
          { title: "Note", dataKey: "note" },
          { title: "Register Date", dataKey: "reg_date" },
          { title: "Fire Date", dataKey: "fire_date" },
        ];
        doc.text(5, 10, "Hi How are you");
        doc.addImage;
        // doc.line();
        doc.autoTable(columns, this.BodyData, {
          // styles: { fillColor: [255, 0, 0], },
          startY: 20,
          headStyles: { fillColor: [41, 176, 235] },
          // columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 5, left: 5, right: 5, bottom: 5 },
        });

        doc.save("my-table.pdf");
      } catch (error) {
        console.log(error);
      }
    },
    dateTimeFormater(value) {
      return this.$dateTime(value ?? null);
    },
    // Operating with DB
    addData() {
      try {
        if (this.editItem?.cons_id == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Constituent ID.",
            "error",
          ]);
        } else if (this.editItem?.cons_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Constituent Name.",
            "error",
          ]);
        } else if (this.editItem?.cons_type == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Constituent Type.",
            "error",
          ]);
        } else if (this.editItem?.acc_status == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Constituent Account Status.",
            "error",
          ]);
        } else if (this.regDate == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Register Date.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          if (this.editItem.cons_type == "CUS") {
            this.editItem.cons_id =
              "CUS" + this.editItem.cons_id.replace("CUS", "");
          } else {
            this.editItem.cons_id =
              "SUP" + this.editItem.cons_id.replace("SUP", "");
          }
          constituentsRef
            .doc(this.editItem.cons_id)
            .set({
              ...this.editItem,
              reg_branch_id: this.userData.s_using_branch,
              reg_date: new Date(this.regDate),
              fire_date: this.fireDate != null ? new Date(this.fireDate) : null,
            })
            .then(() => {
              this.editItem = {};
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data added successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    editData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Constituent ID is not valid.",
            "error",
          ]);
        } else if (this.editItem?.cons_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Constituent Name.",
            "error",
          ]);
        } else if (this.editItem?.cons_type == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Constituent Type.",
            "error",
          ]);
        } else if (this.editItem?.acc_status == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Constituent Account Status.",
            "error",
          ]);
        } else if (this.regDate == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Register Date.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          constituentsRef
            .doc(id)
            .update({
              ...this.editItem,
              reg_date: new Date(this.regDate),
              fire_date: this.fireDate != null ? new Date(this.fireDate) : null,
            })
            .then(() => {
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data updated successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Constituent ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          constituentsRef
            .doc(id)
            .delete()
            .then(() => {
              this.btnLoading = false;
              this.dialog = false;
              this.$store.commit("alertMessage/message", [
                "Data deleted successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Summary
    async viewSummary(consID, consType) {
      try {
        this.summConsID = consID;
        this.summConsType = consType;
        this.summLoading = true;
        this.summaryDialog = true;

        this.wholeTransaction = 0;
        this.returnAmount = 0;
        this.payCashAmount = 0;
        this.payCardAmount = 0;
        this.payChequeAmount = 0;
        this.totalOfPayment = 0;
        this.balanceCredit = 0;
        //
        let totalOfCash = 0;
        let totalOfCard = 0;
        // Ret Payments
        let totalOfCashRet = 0;
        let totalOfCardRet = 0;

        new Promise((resolve, reject) => {
          constituentsRef
            .doc(consID)
            .get()
            .then(async (snapshots) => {
              snapshots.ref
                ?.collection("invoices")
                .get()
                .then((querySnapshot) => {
                  querySnapshot.docs?.forEach((el) => {
                    this.wholeTransaction += Number(el.data().net_total ?? 0);
                    this.returnAmount += Number(el.data().return_amount ?? 0);
                  });
                })
                .then(() => {
                  snapshots.ref
                    ?.collection("payments")
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.docs?.forEach((el) => {
                        var logic =
                          el?.data().cons_type == "CUS"
                            ? el?.data()["payment_type"] == "Return_Pay" // Identify first process
                            : el?.data()["payment_type"] == "Get_Pay"; // Identify first process // Like return
                        //
                        if (logic) {
                          totalOfCashRet += Number(
                            el.data()["cash_amount"] ?? 0
                          );
                          totalOfCardRet += Number(
                            el.data()["card_amount"] ?? 0
                          );
                        } else {
                          totalOfCash += Number(el.data()["cash_amount"] ?? 0);
                          totalOfCard += Number(el.data()["card_amount"] ?? 0);
                        }

                        this.payChequeAmount += Number(
                          el.data().is_che_return
                            ? 0
                            : el.data().cheque_amount ?? 0
                        );
                      });
                    })
                    .then(() => {
                      this.payCashAmount = totalOfCash - totalOfCashRet;
                      this.payCardAmount = totalOfCard - totalOfCardRet;
                      // Total
                      this.totalOfPayment =
                        this.payCashAmount +
                        this.payCardAmount +
                        this.payChequeAmount;
                      resolve();
                    });
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        })
          .then(() => {
            this.balanceCredit = this.wholeTransaction - this.totalOfPayment;
            this.summLoading = false;
          })
          .catch((error) => {
            this.summLoading = false;
            this.$store.commit("alertMessage/message", [error, "error"]);
          });
      } catch (error) {
        this.summLoading = false;
        console.log(error);
      }
    },
    // Filter
    filterData() {
      try {
        var tempList = this.BodyDataBack;
        // Filter unit 01
        if (this.isSupplier) {
          tempList = tempList.filter((value) => value.cons_type == "SUP");
        } else {
          tempList = tempList.filter((value) => value.cons_type == "CUS");
        }
        // Filter unit 02
        if (this.withDateRange) {
          if (this.pickDateRange.length != 2) {
            this.$store.commit("alertMessage/message", [
              "Please select the date range.",
              "warning",
            ]);
          } else {
            if (this.isFireDate) {
              tempList = tempList.filter((value) => {
                var date01 = new Date(this.dateTimeFormater(value?.fire_date));
                var date02 = new Date(this.pickDateRange[0]);
                var date03 = new Date(this.pickDateRange[1]);
                //
                return date01 >= date02 && date01 <= date03;
              });
            } else {
              tempList = tempList.filter((value) => {
                var date01 = new Date(this.dateTimeFormater(value?.reg_date));
                var date02 = new Date(this.pickDateRange[0]);
                var date03 = new Date(this.pickDateRange[1]);
                //
                return date01 >= date02 && date01 <= date03;
              });
            }
          }
        }
        this.BodyData = tempList ?? [];
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.isSupplier = false;
        this.isFireDate = false;
        this.withDateRange = false;
        this.pickDateRange = [];
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
</style>