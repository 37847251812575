<template>
  <div>
    <app-bar-compo
      title="Item Stock - Inventories"
      @onClear="clearFilter"
      @onFilter="filterData"
    >
      <!-- Filters -->
      <template v-slot:filters>
        <h3>Item Type</h3>
        <v-switch
          inset
          class="mr-3 mt-3"
          :label="isAccItem ? 'Accessory Items' : 'Material Items'"
          v-model="isAccItem"
          dense
        ></v-switch>
      </template>
      <template v-slot:actions>
        <!-- <v-btn @click="printData">Print</v-btn> -->
        <v-col cols="6" md="6" lg="4" sm="8" xl="4" class="pa-0 ma-0">
          <search-txt-compo :searchData="getSearchData"></search-txt-compo>
        </v-col>
      </template>
    </app-bar-compo>
    <!-- Body -->
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="BodyData"
        :search="searchTxt"
        :page.sync="page"
        :loading="isTableLoading"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1 data-tbl"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu left origin="center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon class="appBar-icon">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-container class="background">
              <v-row class="pa-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="
                        barcodeDialog = !barcodeDialog;
                        barcodeItemCode = item.item_code;
                      "
                    >
                      <v-icon>mdi-barcode</v-icon>
                    </v-btn>
                  </template>
                  <span>Barcode Generator</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="blue darken-2"
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      @click="dialogAction('edit', item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red darken-4"
                      class="ma-1"
                      @click="dialogAction('delete', item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
      <!-- <v-fab-transition> -->
      <v-btn
        @click="dialogAction()"
        color="deep-orange accent-3"
        dark
        fixed
        bottom
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- </v-fab-transition> -->
    </v-container>
    <!--  -->
    <!-- Add/ Edit/ Delete Dialog -->
    <!--  -->
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text v-if="dialogType != 'd'">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.item_code"
                  label="Item Code"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="editItem.item_name"
                  label="Name"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="editItem.item_type"
                  label="Item Type"
                  :items="['AI', 'MI']"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-select
                  dense
                  v-model="editItem.measuar_unit"
                  label="Measuar Unit"
                  :items="['Pi', 'Kg', 'L', 'M']"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editItem.pro_id"
                  label="Product ID"
                  :items="products"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-combobox>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="editItem.wh_id"
                  label="Warehouse ID"
                  :items="warehouses"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-combobox>
              </v-col> -->
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="def_cost_price"
                  label="Cost Price (Def)"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="def_sale_price"
                  label="Sale Price (Def)"
                  outlined
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  v-model="editItem.note"
                  label="Note"
                  class="text-area-max-height"
                  height="110"
                  outlined
                  no-resize
                  clearable
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="dialog = false"
          >
            {{ dialogType != "d" ? "Close" : "No" }}
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
              dialogType == 'a'
                ? addData()
                : dialogType == 'e'
                ? editData(editItem.item_id)
                : deleteData(editItem.item_id)
            "
          >
            {{ dialogType != "d" ? "Save" : "Yes" }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <!-- Barcode Dialog -->
    <!--  -->
    <v-dialog v-model="barcodeDialog" scrollable persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Barcode Generator</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row> -->
            <!-- <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="barcodeItemCode"
                  label="Item Code"
                  :items="itemCodes"
                  hide-selected
                  outlined
                  clearable
                  required
                ></v-combobox>
              </v-col> -->
            <v-col>
              <v-text-field
                dense
                v-model="barCodeQty"
                label="Barcode Qty"
                outlined
                clearable
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <barcode :value="barcodeItemCode" id="barcode-element">
                Show this if the rendering fails.
              </barcode>
            </v-col>
            <!-- </v-row> -->
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-2"
            :disabled="btnLoading"
            dark
            outlined
            @click="barcodeDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            outlined
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="printBarcode()"
          >
            Print
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import VueBarcode from "vue-barcode";

var itemsRef;
var itemsStockRef;
var productsRef;
var warehousesRef;
var branchesRef;

export default {
  name: "items_screen",
  mounted() {
    itemsRef = this.$fireStore.collection("AM_Items");
    itemsStockRef = this.$fireStore.collection("itemsStock");
    productsRef = this.$fireStore.collection("products");
    warehousesRef = this.$fireStore.collection("warehouses");
    branchesRef = this.$fireStore.collection("branches");
    this.initData();
    this.loadProductsData();
    this.loadWarehousesData();
    this.loadBranchessData();
  },
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      // Crud
      dialog: false,
      dialogTitle: null,
      dialogType: null,
      editItem: {},
      products: [],
      warehouses: [],
      branches: [],
      def_sale_price: 0,
      def_cost_price: 0,
      // Barcodes
      barcodeDialog: false,
      barcodeItemCode: null,
      barCodeQty: null,
      // itemCodes: [],
      // Buttons
      btnLoading: false,
      //   Filter
      isAccItem: false,
      //   Table
      isTableLoading: false,
      page: 1,
      pageCount: 0,
      searchTxt: "",
      itemsPerPage: 10,
      headers: [
        {
          text: "Item Code",
          align: "start",
          value: "item_code",
          sortable: false,
        },
        {
          text: "Name",
          align: "start",
          value: "item_name",
        },
        {
          text: "Type",
          value: "item_type",
        },
        {
          text: "M Unit",
          value: "measuar_unit",
        },
        {
          text: "Product ID",
          align: "start",
          value: "pro_id",
          width: 200,
        },
        // {
        //   text: "Warehouse ID",
        //   align: "start",
        //   value: "wh_id",
        //   width: 200,
        // },
        {
          text: "Cost Price (def)",
          align: "end",
          value: "def_cost_price",
          width: 200,
        },
        {
          text: "Sale Price (def)",
          align: "end",
          value: "def_sale_price",
          width: 200,
        },

        {
          text: "Note",
          value: "note",
          width: 300,
          class: "pad-class",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: 50,
        },
      ],
      BodyData: [],
      BodyDataBack: [], // this is a body data backup list and this is using for filter process
    };
  },
  methods: {
    async initData() {
      try {
        this.isTableLoading = true;
        await itemsRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.BodyData = [];
            this.BodyDataBack = [];
            // this.itemCodes = [];
            snapshots.docs.forEach((element) => {
              this.BodyData.push(element.data());
              this.BodyDataBack.push(element.data());
              // this.itemCodes.push(element.data()["item_code"]);
            });
            this.isTableLoading = false;
          }
        );
      } catch (error) {
        this.isTableLoading = false;
        console.log(error);
      }
    },
    async loadProductsData() {
      try {
        await productsRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.products = [];
            snapshots.docs.forEach((element) => {
              this.products.push(element.data()["pro_id"]);
            });
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async loadWarehousesData() {
      try {
        await warehousesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.warehouses = [];
            snapshots.docs.forEach((element) => {
              this.warehouses.push(element.data()["wh_id"]);
            });
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async loadBranchessData() {
      try {
        await branchesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.branches = [];
            snapshots.docs.forEach((element) => {
              this.branches.push(element.data()["br_id"]);
            });
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    getSearchData(value) {
      this.searchTxt = value;
    },
    dialogAction(type, item) {
      this.editItem = {};
      this.def_cost_price = 0;
      this.def_sale_price = 0;
      if (type == "edit") {
        this.editItem = Object.assign({}, item);
        this.def_cost_price = item.def_cost_price;
        this.def_sale_price = item.def_sale_price;
        this.dialogType = "e";
        this.dialogTitle = "Edit";
      } else if (type == "delete") {
        this.editItem.item_id = item.item_id;
        this.dialogType = "d";
        this.dialogTitle = "Do you want to delete this data?";
      } else {
        this.dialogType = "a";
        this.dialogTitle = "Add";
      }
      this.dialog = !this.dialog;
    },

    // Operating with DB
    addData() {
      try {
        if (this.editItem?.item_code == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Item Code.",
            "error",
          ]);
        } else if (this.editItem?.item_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Item Name.",
            "error",
          ]);
        } else if (this.editItem?.item_type == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Item Type.",
            "error",
          ]);
        } else if (this.editItem?.measuar_unit == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Measuar Unit.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          if (this.editItem.item_type == "AI") {
            this.editItem.item_code =
              "AI" + this.editItem.item_code.replace("AI", "");
          } else {
            this.editItem.item_code =
              "MI" + this.editItem.item_code.replace("MI", "");
          }
          // Declare Batch
          var batch = this.$batch.batch();
          // Item ID generate
          var itemID = uuid();
          itemsRef
            .doc(itemID)
            .set({
              item_id: itemID,
              def_cost_price: Number(this.def_cost_price),
              def_sale_price: Number(this.def_sale_price),
              ...this.editItem,
            })
            .then(() => {
              // Set item by branches
              this.branches.forEach((element) => {
                const stockID = uuid();
                var dataMap = {
                  stock_id: stockID,
                  item_id: itemID,
                  available_qty: 0,
                  damaged_qty: 0,
                  br_id: element,
                  def_cost_price: Number(this.def_cost_price),
                  def_sale_price: Number(this.def_sale_price),
                  create_date: new Date(),
                  last_update_date: null,
                  ...this.editItem,
                };
                batch.set(itemsStockRef.doc(stockID), dataMap);
              });
              // Set item by warehouses
              this.warehouses.forEach((element) => {
                const stockID = uuid();
                var dataMap = {
                  stock_id: stockID,
                  item_id: itemID,
                  available_qty: 0,
                  damaged_qty: 0,
                  wh_id: element,
                  def_cost_price: Number(this.def_cost_price),
                  def_sale_price: Number(this.def_sale_price),
                  create_date: new Date(),
                  last_update_date: null,
                  ...this.editItem,
                };
                batch.set(itemsStockRef.doc(stockID), dataMap);
              });
            })
            .then(() => {
              batch.commit();
              this.editItem = {};
              this.def_sale_price = 0;
              this.def_cost_price = 0;
              this.btnLoading = false;
              this.$store.commit("alertMessage/message", [
                "Data added successfully.",
                "success",
              ]);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    editData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Item ID is not valid.",
            "error",
          ]);
        } else if (this.editItem?.item_code == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Item Code.",
            "error",
          ]);
        } else if (this.editItem?.item_name == null) {
          this.$store.commit("alertMessage/message", [
            "Please enter Item Name.",
            "error",
          ]);
        } else if (this.editItem?.item_type == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Item Type.",
            "error",
          ]);
        } else if (this.editItem?.measuar_unit == null) {
          this.$store.commit("alertMessage/message", [
            "Please select Measur Unit.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          // remove ai and mi if available in item code
          this.editItem.item_code = this.editItem.item_code.replace("AI", "");
          this.editItem.item_code = this.editItem.item_code.replace("MI", "");
          if (this.editItem.item_type == "AI") {
            this.editItem.item_code =
              "AI" + this.editItem.item_code.replace("AI", "");
          } else {
            this.editItem.item_code =
              "MI" + this.editItem.item_code.replace("MI", "");
          }
          itemsRef
            .doc(id)
            .update({
              ...this.editItem,
              def_cost_price: Number(this.def_cost_price),
              def_sale_price: Number(this.def_sale_price),
            })
            .then(() => {
              itemsStockRef
                .where("item_id", "==", id)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.docs?.forEach((snapshot) => {
                    snapshot.ref.update({
                      ...this.editItem,
                      def_cost_price: Number(this.def_cost_price),
                      def_sale_price: Number(this.def_sale_price),
                      last_update_date: new Date(),
                    });
                  });
                })
                .then(() => {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [
                    "Data updated successfully.",
                    "success",
                  ]);
                })
                .catch((e) => {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [
                    e.message,
                    "error",
                  ]);
                });
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    async deleteData(id) {
      try {
        if (id == null) {
          this.$store.commit("alertMessage/message", [
            "Item ID is not valid.",
            "error",
          ]);
        } else {
          this.btnLoading = true;
          await itemsStockRef
            .where("item_id", "==", id)
            .get()
            .then(async (querySnapshot) => {
              // Get availability
              var availability = 0;
              await querySnapshot.docs?.forEach(async (doc) => {
                availability += Number(doc.data()["available_qty"] ?? 0);
              });
              // check and remove item from stock
              if (availability === 0) {
                await querySnapshot.docs?.forEach(async (snapshot) => {
                  // Remove all of the taken items
                  await snapshot?.ref
                    .collection("taken")
                    .get()
                    .then(async (docsTeken) => {
                      await docsTeken?.docs?.forEach((element) => {
                        element?.ref.delete();
                      });
                    })
                    .then(async () => {
                      await snapshot?.ref
                        .collection("damaged")
                        .get()
                        .then(async (docsDamaged) => {
                          await docsDamaged?.docs?.forEach((element) => {
                            element?.ref.delete();
                          });
                        })
                        .catch((error) => {
                          this.btnLoading = false;
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      this.btnLoading = false;
                      console.log(error);
                    });
                });
                // Remove all of stock items
                await querySnapshot.docs?.forEach(async (snapshot) => {
                  snapshot?.ref.delete();
                });
              }
              //return availablity
              return availability;
            })
            .then(async (value) => {
              // check and remove item from items
              if (value == 0) {
                // remove item from items
                itemsRef
                  .doc(id)
                  .delete()
                  .then(() => {
                    this.btnLoading = false;
                    this.dialog = false;
                    this.$store.commit("alertMessage/message", [
                      "Data deleted successfully.",
                      "success",
                    ]);
                  });
              } else {
                this.btnLoading = false;
                this.$store.commit("alertMessage/message", [
                  "Item is available in stock.",
                  "error",
                ]);
              }
            })
            .catch((error) => {
              this.btnLoading = false;
              console.log(error);
            });
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Barcodes
    async printBarcode() {
      try {
        if (Number(this.barCodeQty) >= 21) {
          this.btnLoading = true;
          let el = window.document.getElementsByClassName(
            "vue-barcode-element"
          )[0];
          // Make list of element
          let divEl = document.createElement("div");
          divEl.id = "barcodes-id";
          divEl.style.width = "100%";
          divEl.style.justifyContent = "center";
          divEl.style.alignItems = "center";
          divEl.style.display = "flex";
          divEl.style.flexWrap = "wrap";
          divEl.style.gap = "5px";
          //
          var elList = [];
          for (let index = 0; index < Number(this.barCodeQty); index++) {
            let tmp = document.createElement("div");
            tmp.style.margin = "2px";
            tmp.style.padding = "0px";
            tmp.innerHTML = el.outerHTML;
            elList.push(tmp);
          }
          divEl.append(...elList);
          // Make iframe
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          document.body.appendChild(iframe);
          const pri = iframe.contentWindow;
          pri.document.open();
          // pri.document.URL = "";
          pri.document.appendChild(divEl);
          pri.document.close();
          pri.focus();
          pri.print();
          this.btnLoading = false;
        } else {
          this.$store.commit("alertMessage/message", [
            "The printable minimum quantity is 21.",
            "error",
          ]);
        }
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    // Filter
    filterData() {
      try {
        // Filter unit 01
        if (this.isAccItem) {
          this.BodyData = this.BodyDataBack.filter(
            (value) => value.item_type == "AI"
          );
        } else {
          this.BodyData = this.BodyDataBack.filter(
            (value) => value.item_type == "MI"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearFilter() {
      try {
        this.isAccItem = false;
        this.BodyData = this.BodyDataBack;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss"></style>
