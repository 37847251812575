<template>
  <div class="signIn-compo">
    <v-form v-model="valid">
      <v-container>
        <v-card min-width="350">
          <v-card-text class="center-card">
            <v-avatar size="76" class="ma-5 indigo darken-1"
              ><span class="white--text text-h5">SV</span></v-avatar
            >
            <v-col>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password"
                label="Password"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                dense
                v-model="selectedBranch"
                :items="branches"
                hide-selected
                label="Branch"
                outlined
                clearable
                required
              ></v-select>
            </v-col>
          </v-card-text>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn
              @click="signIn"
              :loading="btnLoading"
              :disabled="btnLoading"
              class="button mr-5"
              dark
              >Sign In<template v-slot:loader>
                <span class="custom-loader">
                  <v-icon>mdi-cached</v-icon>
                </span>
              </template></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
    </v-form>
    <div class="img">
      <img src="@/assets/development logo.webp" />
    </div>
  </div>
</template>

<script>
var branchesRef;
var employeesRef;

export default {
  name: "signIn_screen",
  mounted() {
    branchesRef = this.$fireStore.collection("branches");
    employeesRef = this.$fireStore.collection("employees");
    this.initData();
  },
  data: () => ({
    valid: false,
    branches: [],
    selectedBranch: null,
    password: null,
    email: null,
    emailRules: [
      (v) => !!v || "E-mail is required",
      // (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    // Buttons
    btnLoading: false,
    //
    isBranchCodesLading: false,
  }),
  methods: {
    async initData() {
      try {
        this.isBranchCodesLading = true;
        await branchesRef.onSnapshot(
          { includeMetadataChanges: true },
          (snapshots) => {
            this.branches = [];
            snapshots.docs.forEach((element) => {
              this.branches.push(element.data()["br_id"]);
            });
            this.isBranchCodesLading = false;
          }
        );
      } catch (error) {
        this.isBranchCodesLading = false;
        console.log(error);
      }
    },
    signIn() {
      if (this.selectedBranch == null || this.selectedBranch == "") {
        this.$store.commit("alertMessage/message", [
          "Please select your working Branch ID.",
          "error",
        ]);
      } else {
        this.btnLoading = true;
        // this.email = this.email;
        this.$auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then((userCredential) => {
            // Signed in
            return userCredential.user?.uid ?? null;
          })
          .then(async (uid) => {
            if (uid != null) {
              await employeesRef
                .where("authID", "==", uid)
                // .where("emp_status", "==", "Active")
                .get()
                .then(async (snapshots) => {
                  // if (
                  //   snapshots.data()["emp_position"] == "Manager" ||
                  //   snapshots.data()["emp_position"] == "Cashier"
                  // ) {
                  if (snapshots.docs[0]?.data()["emp_id"] != null) {
                    await localStorage.setItem(
                      "systemuser",
                      JSON.stringify({
                        s_user_id: snapshots.docs[0].data()["emp_id"],
                        s_user_name: snapshots.docs[0].data()["emp_name"],
                        isAuth: true,
                        s_using_branch: this.selectedBranch,
                      })
                    );
                    await localStorage.setItem(
                      "userMP", // User Email & Password
                      JSON.stringify({
                        s_user_email: this.email,
                        s_user_password: this.password,
                      })
                    );
                    this.$store.commit("alertMessage/message", [
                      "Sign in successfully.",
                      "success",
                    ]);
                    this.$router.replace("/").catch(() => {});
                  } else {
                    await this.$auth
                      .signOut()
                      .then(() => {
                        localStorage.removeItem("systemuser");
                        this.$store.commit("alertMessage/message", [
                          "The system user data not exist. Please try again.",
                          "error",
                        ]);
                      })
                      .catch((error) => {
                        this.$store.commit("AlertMessage/message", [
                          error,
                          "error",
                        ]);
                      });
                  }
                  // } else {
                  //   this.$store.commit("alertMessage/message", [
                  //     "The system user data not valid. Please try again.",
                  //     "error",
                  //   ]);
                  // }
                })
                .then(() => {
                  this.btnLoading = false;
                })
                .catch((error) => {
                  this.btnLoading = false;
                  this.$store.commit("alertMessage/message", [error, "error"]);
                });
            } else {
              this.$store.commit("alertMessage/message", [
                "The System user not registered. Please try again.",
                "error",
              ]);
            }
          })
          .catch((error) => {
            this.btnLoading = false;
            this.$store.commit("alertMessage/message", [error, "error"]);
          });
      }
    },
  },
};
</script>
<style lang="scss">
.signIn-compo {
  // min-width: 350px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .center-card {
    text-align: center;
  }
  & .img {
    width: 100%;
    display: flex;
    justify-content: center;
    & img {
      padding: 0px;
      // margin-top: 20px;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }
}
</style>